import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Flex,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    Link,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast,
} from "@chakra-ui/react";
import {SearchIcon} from "@chakra-ui/icons";
import {IoMdAdd} from "react-icons/io";
import {MdDelete} from "react-icons/md";
import {DeviceTypeBox} from "./DeviceTypeBox";
import API from "../../context/API";

const DeviceTypes = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [currentDeviceType, setCurrentDeviceType] = useState(null);
    const [deviceTypes, setDeviceTypes] = useState([]);
    const [filteredDeviceTypes, setFilteredDeviceTypes] = useState([]);
    const toast = useToast();

    async function fetchDeviceTypes() {
        try {
            const deviceTypesResponse = await API.get("device-types", {
                headers: {"Content-Type": "application/json"},
                withCredentials: false,
            });
            const deviceTypesData = deviceTypesResponse.data.data;
            setDeviceTypes(deviceTypesData);
            setFilteredDeviceTypes(deviceTypesData);
        } catch (error) {
            toast({
                title: "Error fetching device types.",
                description: error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    }

    useEffect(() => {
        fetchDeviceTypes();
        return () => {
            setDeviceTypes([]);
        };
    }, []);

    const handleAddClick = () => {
        setCurrentDeviceType(null);
        setIsEditing(true);
    };

    const handleItemClick = (itemId) => {
        const deviceTypeToEdit = deviceTypes.find((item) => item.id === itemId);
        setCurrentDeviceType(deviceTypeToEdit);
        setIsEditing(true);
    };

    const handleDeleteClick = async (itemId) => {
        try {
            const deleteDeviceTypeResponse = await API.delete(`device-types/${itemId}`, {
                headers: {"Content-Type": "application/json"},
                withCredentials: false,
            });
            if (deleteDeviceTypeResponse.data.status === 200) {
                toast({
                    title: "Success.",
                    description: "Device type deleted successfully.",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
                const updatedDeviceTypes = deviceTypes.filter((item) => item.id !== itemId);
                setDeviceTypes(updatedDeviceTypes);
                setFilteredDeviceTypes(updatedDeviceTypes);
            } else {
                toast({
                    title: "Error.",
                    description: deleteDeviceTypeResponse.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Error.",
                description: error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleSearch = (event) => {
        const filter = event.target.value.toLowerCase();
        if (!filter) {
            setFilteredDeviceTypes(deviceTypes);
            return;
        }
        const filtered = deviceTypes.filter((item) => item.name.toLowerCase().includes(filter));
        setFilteredDeviceTypes(filtered);
    };

    return (
        <>
            {!isEditing ? (
                <>
                    <Box>
                        <Flex justify={"space-between"} alignItems={"center"} width={"100%"} flexWrap={"wrap"} mt={4}>
                            <Box>
                                <Heading size={"xl"} letterSpacing={-2}>
                                    Device Types
                                </Heading>
                            </Box>
                            <Box>
                                <Button
                                    leftIcon={<IoMdAdd/>}
                                    bg="black"
                                    color={"white"}
                                    _hover={{
                                        color: "black",
                                        bg: "white",
                                        borderWidth: "1px",
                                        borderColor: "gray.300",
                                    }}
                                    variant="outline"
                                    onClick={handleAddClick}
                                >
                                    Add Device Type
                                </Button>
                            </Box>
                        </Flex>
                        <Box m={4}>
                            <InputGroup size={"md"}>
                                <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300"/>}/>
                                <Input
                                    type="search"
                                    placeholder="Search..."
                                    _placeholder={{color: "gray.500"}}
                                    onKeyUp={handleSearch}
                                />
                            </InputGroup>
                        </Box>
                    </Box>
                    <TableContainer>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {filteredDeviceTypes.map((item) => (
                                    <Tr key={item.id}>
                                        <Td>
                                            <Link color="green" onClick={() => handleItemClick(item.id)}>
                                                {item.name}
                                            </Link>
                                        </Td>
                                        <Td>
                                            <Button variant="ghost" colorScheme="red"
                                                    onClick={() => handleDeleteClick(item.id)}>
                                                <MdDelete/>
                                            </Button>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <DeviceTypeBox
                    initialDeviceType={currentDeviceType}
                    onSave={() => setIsEditing(false)}
                    onCancel={() => setIsEditing(false)}
                    fetchDeviceTypes={fetchDeviceTypes}
                />
            )}
        </>
    );
};

export default DeviceTypes;
