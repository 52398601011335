import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  Icon,
  IconButton,
  Spacer,
  Spinner,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {RiRadioButtonFill} from "react-icons/ri";
import {MdDiscount} from "react-icons/md";
import {HiSpeakerphone} from "react-icons/hi";
import {BsMenuApp} from "react-icons/bs";
import {FaBook, FaCamera, FaRegEdit, FaTag} from "react-icons/fa";
import {Pie} from "react-chartjs-2";
import "../../theme/Layout/ChartConfig";
import Card from "../../theme/Layout/Card";
//import { SalesChart } from "../../theme/Layout/Chart";
//import { DateRangePicker } from "../../theme/Layout/DatePicker";
import {AddIcon, ArrowForwardIcon, CloseIcon} from "@chakra-ui/icons";
import API from "../../context/API";
import {useNavigate} from "react-router-dom"; // Add this import

const getGreetingTime = (d = new Date()) => {
  const split_afternoon = 12; // 24hr time to split the afternoon
  const split_evening = 17; // 24hr time to split the evening
  const currentHour = d.getHours();

  if (currentHour >= split_afternoon && currentHour <= split_evening) {
    return "afternoon";
  } else if (currentHour >= split_evening) {
    return "evening";
  }
  return "morning";
};

const Greeting = ({ name }) => {
  const timeOfDay = getGreetingTime();
  const greeting = `Good ${timeOfDay}, ${name}`;

  return <Text>{greeting}</Text>;
};

function formatTimeAgo(date) {
  const now = new Date();
  const seconds = Math.round((now - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const hours = Math.round(minutes / 60);
  const days = Math.round(hours / 24);

  if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else {
    return `${days} days ago`;
  }
}

const SummaryCards = ({ children, onClick, isSelected }) => {
  const hoverBgColor = useColorModeValue("gray.200", "gray.700");
  const selectedBgColor = useColorModeValue("gray.200", "gray.600");
  const bgColor = useColorModeValue("gray.100", "gray.800");

  return (
    <Card
      flex="1"
      flexBasis="0"
      ml={"0"}
      mr={4}
      mt={4}
      minWidth={"max"}
      shadow={isSelected ? "md" : "sm"}
      boxSizing="border-box"
      cursor="pointer"
      onClick={onClick}
      bg={isSelected ? selectedBgColor : bgColor}
      _hover={{
        bg: hoverBgColor,
        transition: "background-color 0.3s ease",
      }}
    >
      {children}
    </Card>
  );
};

const CardContainer = ({
  sales,
  orders,
  devices,
  currentSessions,
  onSelect,
  selectedCard,
  //pendingDevices,
}) => {
  return (
    <Flex
      direction="row"
      wrap="wrap"
      justify="space-between"
      align="stretch"
      mr={-4}
    >
      <SummaryCards
        onClick={() => onSelect("orders")}
        isSelected={selectedCard === "orders"}
      >
        <Text>Orders</Text>
        <Text fontSize={"xl"} fontWeight={"bold"}>
          {orders}
        </Text>{" "}
      </SummaryCards>
      <SummaryCards
        onClick={() => onSelect("devices")}
        isSelected={selectedCard === "devices"}
      >
        <Flex alignItems="center">
          <Text>Devices</Text>
          {/* {pendingDevices.length > 0 && (
            <Box ml={2} bg="green.400" w="8px" h="8px" borderRadius="full" />
          )} */}
        </Flex>
        <Text fontSize={"xl"} fontWeight={"bold"}>
          {devices}
        </Text>{" "}
      </SummaryCards>
      <SummaryCards
        onClick={() => onSelect("sessions")}
        isSelected={selectedCard === "sessions"}
      >
        <Text>Current Sessions</Text>
        <Text fontSize={"xl"} fontWeight={"bold"}>
          {currentSessions}
        </Text>{" "}
      </SummaryCards>
    </Flex>
  );
};

export const suggestions = [
  {
    id: 1,
    category: "Growth",
    title: "Create a “Buy 1, Get 1 Free” campaign",
    description:
      "Offers appear as a green tag on your cover image to help your store stand out. Merchants who create this offer often see up to 39% more orders.",
    actionText: "Create campaign",
    icon: FaTag,
  },
  {
    id: 2,
    category: "Operations",
    title: "Add photos to your items",
    description:
      "You have 10 items without photos. Adding pictures can increase sales.",
    actionText: "Add photos",
    icon: FaCamera,
    condition: (user) => user.itemsWithoutPhotos > 0, // Condition to display this suggestion
  },
  // ...more suggestions
];

export const SuggestionCards = ({ suggestion, user, minWidth }) => {
  if (suggestion.condition && !suggestion.condition(user)) {
    return null; // Don't render the card if the condition is not met
  }

  return (
    <>
      <Card
        m={2}
        pt={8}
        pb={8}
        minWidth={minWidth}
        width="100%" // Ensures that the card expands to fill the space
        height="100%" // Stretches to match the height of the tallest item
      >
        <Heading size="sm" color={"gray.500"}>
          {suggestion.category}
        </Heading>
        <Flex direction={"row"}>
          <VStack p={2}>
            <Box>
              <Heading size={"md"} letterSpacing={-1}>
                {suggestion.title}
              </Heading>
              <Text>{suggestion.description}</Text>
              <Button
                variant={"outline"}
                mt={4}
                borderRadius={32}
                borderColor={"gray.400"}
              >
                {suggestion.actionText}
              </Button>
            </Box>
          </VStack>
          <Spacer />
          <Icon
            as={suggestion.icon}
            alignItems={"right"}
            name="Growth"
            boxSize={{ base: 6, md: 12 }}
          />
        </Flex>
      </Card>
    </>
  );
};

// Main component to render all suggestions
export const Suggestions = ({ user, minWidth, width = "100%" }) => {
  return (
    <Flex
      ml={1}
      direction={["column", "row"]} // Column on small screens, row on larger
      wrap="wrap" // Allows items to wrap to the next line
      justify="center"
      align="stretch" // Aligns items to stretch to fill the container height
      w="100%" // Ensures the flex container takes full width
    >
      {" "}
      {suggestions.map((suggestion) => (
        <Box width={["100%", width]} height="100%">
          <SuggestionCards
            key={suggestion.id}
            suggestion={suggestion}
            user={user}
            minWidth={minWidth}
          />
        </Box>
      ))}
    </Flex>
  );
};

export const user = {
  itemsWithoutPhotos: 10, // This would be dynamic data from your app's state
  // ...other user data
};

const sidebarButtons = [
  {
    id: 1,
    text: "Create ad",
    lefticon: <HiSpeakerphone />,
  },
  {
    id: 2,
    text: "Create offer",
    lefticon: <MdDiscount />,
  },
  {
    id: 3,
    text: "Edit item",
    lefticon: <FaRegEdit />,
  },
  {
    id: 4,
    text: "Edit menu hours",
    lefticon: <BsMenuApp />,
  },
  {
    id: 5,
    text: "Learning guide",
    lefticon: <FaBook />,
  },
];

function DashboardMerchant() {
  const [lastUpdated, setLastUpdated] = useState("Never");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sidebarDisplay = useBreakpointValue({ base: "none", md: "block" });
  const [merchantData, setMerchantData] = useState(null);
  const [sales, setSales] = useState(0);
  const [orders, setOrders] = useState(0);
  const [devices, setDevices] = useState(0);
  const [orderStatuses, setOrderStatuses] = useState({
    completed: 0,
    pending: 0,
    ready: 0,
  });
  const [selectedCard, setSelectedCard] = useState("orders");

  const navigate = useNavigate(); // Add this
  const toast = useToast();

  useEffect(() => {
    const fetchMerchantData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await API.get("merchant/profile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMerchantData(response.data.data);
        const now = Date.now();
        localStorage.setItem("lastUpdatedTimestamp", now.toString());
        setLastUpdated(formatTimeAgo(new Date(now)));
      } catch (error) {
        console.error("Error fetching merchant data:", error);
      }
    };

    const fetchOrderStatuses = async () => {
      try {
        const token = localStorage.getItem("access_token");
        let orderStatuses = { completed: 0, pending: 0, ready: 0 };

        const statuses = ["pending", "completed", "ready"];
        for (const status of statuses) {
          const response = await API.get(`/orders?type=${status}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (response.data.success) {
            orderStatuses[status] = response.data.data.length;
          }
        }

        setOrderStatuses(orderStatuses);
      } catch (error) {
        console.error("Error fetching order statuses:", error);
      }
    };

    fetchMerchantData();
    fetchOrderStatuses();
  }, []);

  const SidebarContent = () => (
    <>
      <Box>
        <Heading size={{ base: "md", xl: "lg" }} mb={{ base: 6, md: 0 }}>
          Quick Actions
        </Heading>
      </Box>
      <VStack spacing={2} w={"100%"}>
        {sidebarButtons.map((button) => (
          <Button
            width={"100%"}
            justifyContent="space-between"
            rightIcon={<ArrowForwardIcon />}
            key={button.id}
          >
            <Box as="span" display="flex" alignItems="center">
              {button.lefticon}
              <Text marginLeft="2">{button.text}</Text>
            </Box>
          </Button>
        ))}
      </VStack>
    </>
  );

  const bgColor = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("gray.700", "gray.200");

  const backgroundColor = useColorModeValue(
    ["#000000", "#A9A9A9", "#E0E0E0"],
    ["#FFFFFF", "#4B4B4B", "#808080"]
  );
  const hoverBackgroundColor = useColorModeValue(
    ["#333333", "#D3D3D3", "#C0C0C0"],
    ["#CCCCCC", "#666666", "#999999"]
  );

  const legendColor = useColorModeValue("#000000", "#FFFFFF");
  const hasOrders = Object.values(orderStatuses).some((count) => count > 0);

  const data = {
    labels: ["Completed", "Pending", "Ready"],
    datasets: [
      {
        data: [
          orderStatuses.completed,
          orderStatuses.pending,
          orderStatuses.ready,
        ],
        backgroundColor: backgroundColor,
        hoverBackgroundColor: hoverBackgroundColor,
        //borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: legendColor,
          usePointStyle: true,
          boxWidth: 10,
          padding: 20,
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  if (!merchantData) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box w={"full"} pt={4}>
      <Flex h={"calc(100vh - 110px)"}>
        <Box
          flex={"1"}
          width={"65%"}
          pr={{ base: 0, md: 4 }}
          boxSizing="border-box"
        >
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <Box>
              <Greeting
                name={merchantData ? merchantData.name : "Loading..."}
              />
              <Text fontSize={"xs"}>Last updated {lastUpdated}</Text>
            </Box>
            <Button
              size="sm"
              variant="solid"
              borderRadius="full"
              leftIcon={<RiRadioButtonFill />}
              onClick={onOpen}
              zIndex="overlay"
              shadow={"sm"}
              bg={bgColor}
              display={{ base: "flex", md: "none" }}
            >
              More actions
            </Button>
          </Flex>
          <Heading size={{ base: "lg", md: "xl" }} letterSpacing={-2}>
            Summary
          </Heading>
          <CardContainer
            sales={sales}
            orders={orders}
            devices={merchantData ? merchantData.devices.length : 0}
            currentSessions={
              merchantData ? merchantData.currentSessions.length : 0
            }
            onSelect={(card) =>
              setSelectedCard(selectedCard === card ? null : card)
            }
            selectedCard={selectedCard}
          />

          {/* Detailed Sections */}
          <Box mt={4}>
            <Collapse in={selectedCard === "orders"} animateOpacity>
              <Card
                m={4}
                p={6}
                flex="1"
                ml={1}
                mr={1}
                minWidth={"max"}
                minHeight={"400px"}
              >
                <VStack align={"flex-start"}>
                  <Flex
                    w={"full"}
                    justify={"space-between"}
                    alignItems={"center"}
                  >
                    <Text fontSize={"lg"}> Order status overview </Text>
                    <IconButton
                      aria-label="Close"
                      icon={<CloseIcon />}
                      size="sm"
                      onClick={() => setSelectedCard(null)}
                      alignSelf={"flex-end"}
                    />
                  </Flex>
                  <Text fontSize={"xs"}> Orders mapped by status </Text>
                  <Divider />
                  <Flex width={"100%"} height={"100%"}>
                    <Box width={"100%"}>
                      {hasOrders ? (
                        <Pie data={data} options={options} />
                      ) : (
                        <Flex
                          justifyContent={"center"}
                          alignItems={"center"}
                          minHeight={"200px"}
                        >
                          <Text fontSize={"lg"}>No orders data found.</Text>
                        </Flex>
                      )}
                    </Box>
                  </Flex>
                </VStack>
              </Card>
            </Collapse>

            <Collapse in={selectedCard === "devices"} animateOpacity>
              <Card
                m={4}
                p={6}
                flex="1"
                ml={1}
                mr={1}
                minWidth={"max"}
                minHeight={"400px"}
              >
                <VStack align={"flex-start"}>
                  <Flex
                    w={"full"}
                    justify={"space-between"}
                    alignItems={"center"}
                  >
                    <Text fontSize={"lg"}> Devices overview </Text>
                    <IconButton
                      aria-label="Close"
                      icon={<CloseIcon />}
                      size="sm"
                      onClick={() => setSelectedCard(null)}
                      alignSelf={"flex-end"}
                    />
                  </Flex>
                  <Text fontSize={"xs"}> Overview of all devices </Text>
                  <Divider />

                  <Flex
                    width={"100%"}
                    justify="space-between"
                    alignItems={"center"}
                  >
                    <VStack alignItems={"flex-start"}>
                      <Text fontSize={"lg"}>
                        Total Devices:{" "}
                        {merchantData ? merchantData.devices.length : 0}
                      </Text>
                    </VStack>
                    <Button
                      leftIcon={<AddIcon />}
                      bg="black"
                      color={"white"}
                      _hover={{
                        color: "black",
                        bg: "white",
                        borderWidth: "1px",
                        borderColor: "gray.300",
                      }}
                      variant="outline"
                      onClick={() => navigate("/deviceassignment")} // Navigate to DeviceAssignment
                    >
                      Assign Device
                    </Button>
                  </Flex>
                </VStack>
              </Card>
            </Collapse>

            <Collapse in={selectedCard === "sessions"} animateOpacity>
              <Card
                m={4}
                p={6}
                flex="1"
                ml={1}
                mr={1}
                minWidth={"max"}
                minHeight={"400px"}
              >
                <VStack align={"flex-start"}>
                  <Flex
                    w={"full"}
                    justify={"space-between"}
                    alignItems={"center"}
                  >
                    <Text fontSize={"lg"}> Current Sessions overview </Text>
                    <IconButton
                      aria-label="Close"
                      icon={<CloseIcon />}
                      size="sm"
                      onClick={() => setSelectedCard(null)}
                      alignSelf={"flex-end"}
                    />
                  </Flex>{" "}
                  <Text fontSize={"xs"}>
                    {" "}
                    Overview of all current sessions{" "}
                  </Text>
                  <Divider />
                  <Flex width={"100%"}>
                    <Box width={"100%"}>
                      <Text fontSize={"lg"}>
                        Active Sessions:{" "}
                        {merchantData ? merchantData.currentSessions.length : 0}
                      </Text>
                    </Box>
                  </Flex>
                </VStack>
              </Card>
            </Collapse>
          </Box>
        </Box>

        {sidebarDisplay === "block" && (
          <VStack
            width={"35%"}
            w="35vh"
            position={"relative"}
            p={4}
            spacing={4}
            //bg="white"
            justifyContent={"center"}
            alignItems={"center"}
            //zIndex={2}
            overflowY={"hidden"}
          >
            <VStack
              width={"35%"}
              w="35vh"
              position={"fixed"}
              right={5}
              p={4}
              spacing={4}
              //bg="white"
              maxWidth={"inherit"}
              justifyContent={"center"}
              alignItems={"center"}
              //zIndex={2}
              //overflowY={"hidden"}
            >
              <SidebarContent />
            </VStack>
          </VStack>
        )}
      </Flex>

      {/* Mobile Drawer */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xs">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody marginTop={"4"}>
            <SidebarContent />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}
export default DashboardMerchant;
