import React from 'react';
import AdminReports from './adminReports';
import MerchantReports from './merchantReports';
import ManagerReports from './managerReports';
import { useLocation } from 'react-router-dom';
import StaffReports from './staffReports';

const Reports = ({ role }) => {
  return (
    <div>
      {role === 'admin' && <AdminReports />}
      {role === 'merchant' && <MerchantReports />}
      {role === 'manager' && <ManagerReports />}
      {role === 'staff' && <StaffReports />}
    </div>
  );
};

export default Reports;
