import React, { useEffect, useRef, useState } from "react";
import { Box, Input, InputGroup, InputLeftElement, InputRightElement, Icon } from "@chakra-ui/react";
import { FaCalendarAlt, FaChevronDown, FaChevronUp } from "react-icons/fa";
import "daterangepicker/daterangepicker.css";
import $ from "jquery";
import "daterangepicker";
import moment from "moment";

function DateRangePicker({onDateRangeChange}) {
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: moment().subtract(30, "days"),
    endDate: moment(),
  });
  const [isPickerOpen, setIsPickerOpen] = useState(false); // Track if picker is open
  const [isApplyEnabled, setIsApplyEnabled] = useState(false); // Track if apply button is enabled
  const dateRangePickerRef = useRef(null);

  useEffect(() => {
    const picker = $(dateRangePickerRef.current).daterangepicker(
      {
        startDate: selectedDateRange.startDate,
        endDate: selectedDateRange.endDate,
        locale: {
          format: "MMM DD, YYYY",
        },
        autoUpdateInput: false,
      },
      (start, end) => {
        setSelectedDateRange({ startDate: start, endDate: end });
        onDateRangeChange({startDate: start, endDate: end})
        setIsApplyEnabled(true); // Enable apply button on date selection
      }
    ).data("daterangepicker");

    // Customize apply and cancel buttons
    picker.container.find(".applyBtn").css({
      backgroundColor: isApplyEnabled ? "black" : "gray",
      color: "white",
    });
    picker.container.find(".cancelBtn").css({
      backgroundColor: "gray",
      color: "white",
    });

    // Manually set the input value after selection and reset apply button state
    $(dateRangePickerRef.current).on("apply.daterangepicker", (ev, picker) => {
      $(dateRangePickerRef.current).val(
        `${picker.startDate.format("MMM DD, YYYY")} - ${picker.endDate.format("MMM DD, YYYY")}`
      );
      setIsApplyEnabled(false); // Reset apply button after applying
      setIsPickerOpen(false);
    });

    // Cleanup on unmount
    return () => {
      picker.remove();
    };
  }, [selectedDateRange, isApplyEnabled]);

  const handleTogglePicker = (e) => {
    e.stopPropagation();
    const picker = $(dateRangePickerRef.current).data("daterangepicker");

    if (isPickerOpen) {
      picker.hide();
      setIsPickerOpen(false);
    } else {
      picker.show();
      setIsPickerOpen(true);
      setIsApplyEnabled(false); // Disable apply button when picker opens
    }
  };

  return (
    <Box className="date-range-picker-container">
      <InputGroup size="md" onClick={handleTogglePicker}>
        <InputLeftElement pointerEvents="none">
          <Icon as={FaCalendarAlt} color="gray.500" />
        </InputLeftElement>
        <Input
          ref={dateRangePickerRef}
          type="text"
          placeholder={`${selectedDateRange.startDate.format("MMM DD, YYYY")} - ${selectedDateRange.endDate.format("MMM DD, YYYY")}`}
          readOnly
          cursor="pointer"
          borderRadius="md"
          boxShadow="sm"
          _hover={{ borderColor: "gray.400" }}
          _focus={{ borderColor: "gray.500", boxShadow: "0 0 0 1px gray.500" }}
          bg="white"
          color="gray.500"
          fontWeight="medium"
          px={4}
          pr={8}
          minWidth="290px"
          textAlign="center"
        />
        <InputRightElement>
          <Icon
            as={isPickerOpen ? FaChevronUp : FaChevronDown}
            color="gray.500"
            cursor="pointer"
          />
        </InputRightElement>
      </InputGroup>
    </Box>
  );
}

export default DateRangePicker;
