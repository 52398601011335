import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import {AddIcon, DeleteIcon, SearchIcon} from "@chakra-ui/icons";

const DeviceAssignment = () => {
  const [devices, setDevices] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deviceData, setDeviceData] = useState({
    name: "",
    identifier: "",
    type: "",
  });
  const toast = useToast();

  useEffect(() => {
    // Retrieve devices from the logged-in user data
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.devices) {
      setDevices(user.devices);
      setFilteredDevices(user.devices);
    }
  }, []);

  //   const fetchDevices = async () => {
  //     try {
  //       const response = await API.get("/devices");
  //       if (response.data.success) {
  //         setDevices(response.data.data);
  //         setFilteredDevices(response.data.data);
  //       } else {
  //         setError("Failed to fetch devices.");
  //         toast({
  //             title: "Failed to fetch Devices.",
  //             description: error.message,
  //             status: "error",
  //             duration: 5000,
  //             isClosable: true,
  //           });
  //       }
  //     } catch (error) {
  //       setError("An error occurred while fetching devices.");
  //       toast({
  //         title: "Failed to fetch Devices.",
  //         description: error.message,
  //         status: "error",
  //         duration: 5000,
  //         isClosable: true,
  //       });
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  const handleAddClick = () => {
    setDeviceData({
      name: "",
      identifier: "",
      type: "",
    });
    setIsModalOpen(true);
  };

  const handleDeleteClick = async (itemId) => {
    // Implement device deletion logic here if necessary
    // Update the local storage after deleting the device
    const updatedDevices = devices.filter((item) => item.id !== itemId);
    setDevices(updatedDevices);
    setFilteredDevices(updatedDevices);
    const user = JSON.parse(localStorage.getItem("user"));
    user.devices = updatedDevices;
    localStorage.setItem("user", JSON.stringify(user));

    toast({
      title: "Device deleted.",
      description: "The device has been deleted successfully.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const handleSearch = (event) => {
    const filter = event.target.value.toLowerCase();
    setSearchQuery(filter);
    if (!filter) {
      setFilteredDevices(devices);
      return;
    }
    const filtered = devices.filter(
      (item) =>
        item.name.toLowerCase().includes(filter) ||
        item.identifier.toLowerCase().includes(filter) ||
        (item.type && item.type.name.toLowerCase().includes(filter))
    );
    setFilteredDevices(filtered);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalSubmit = async () => {
    // Implement the logic to add the device to the user's list
    const newDevice = { ...deviceData, id: Date.now() }; // Mocked new device ID
    const updatedDevices = [...devices, newDevice];
    setDevices(updatedDevices);
    setFilteredDevices(updatedDevices);

    // Update the user data in local storage
    const user = JSON.parse(localStorage.getItem("user"));
    user.devices = updatedDevices;
    localStorage.setItem("user", JSON.stringify(user));

    toast({
      title: "Device created.",
      description: "The device has been created successfully.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    handleModalClose();
  };

  return (
    <>
      <Box>
        <Flex
          justify={"space-between"}
          alignItems={"center"}
          width={"100%"}
          flexWrap={"wrap"}
          mt={4}
        >
          <Heading size={"xl"} letterSpacing={-2}>
            Your devices
          </Heading>
          <Button
            leftIcon={<AddIcon />}
            bg="black"
            color={"white"}
            _hover={{
              color: "black",
              bg: "white",
              borderWidth: "1px",
              borderColor: "gray.300",
            }}
            variant="outline"
            onClick={handleAddClick}
            display={{ base: "none", md: "flex" }} // Show on mobile
          >
            Add Device
          </Button>
          <IconButton
            onClick={handleAddClick}
            display={{ base: "flex", md: "none" }} // Show on mobile
            size="sm"
            bg="black"
            color="white"
            _hover={{
              color: "black",
              bg: "white",
              borderWidth: "1px",
              borderColor: "gray.300",
            }}
            borderRadius="full" // Rounded button for mobile
            icon={<AddIcon />}
          />
        </Flex>
        <Box m={4}>
          <InputGroup size={"md"}>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              type="search"
              placeholder="Search..."
              _placeholder={{ color: "gray.500" }}
              onChange={handleSearch}
              value={searchQuery}
            />
          </InputGroup>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Identifier</Th>
              <Th>Type</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredDevices && filteredDevices.length > 0 ? (
              filteredDevices.map((item) => (
                <Tr key={item.id}>
                  <Td>{item.name}</Td>
                  <Td>{item.identifier}</Td>
                  <Td>{item.type?.name || "N/A"}</Td>
                  <Td>
                    <Button
                      variant="ghost"
                      colorScheme="red"
                      onClick={() => handleDeleteClick(item.id)}
                    >
                      <DeleteIcon />
                    </Button>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={4}>
                  <Flex justify="center" align="center" height="100%">
                    <Text fontSize={"md"}>No Devices found</Text>
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>

      {/* Modal for Creating Device */}
      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        isCentered
        size={{base: "sm", md: "md"}}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Device</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="device-name" mb="4">
              <FormLabel>Device Name</FormLabel>
              <Input
                value={deviceData.name}
                onChange={(e) =>
                  setDeviceData({ ...deviceData, name: e.target.value })
                }
                variant={"filled"}
                borderRadius={"full"}
              />
            </FormControl>
            <FormControl id="device-identifier" mb="4">
              <FormLabel>Device Identifier</FormLabel>
              <Input
                value={deviceData.identifier}
                onChange={(e) =>
                  setDeviceData({ ...deviceData, identifier: e.target.value })
                }
                variant={"filled"}
                borderRadius={"full"}
              />
            </FormControl>
            <FormControl id="device-type" mb="4">
              <FormLabel>Device Type</FormLabel>
              <Input
                value={deviceData.type}
                onChange={(e) =>
                  setDeviceData({ ...deviceData, type: e.target.value })
                }
                variant={"filled"}
                borderRadius={"full"}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="black"
              color={"white"}
              _hover={{
                color: "black",
                bg: "white",
                borderWidth: "1px",
                borderColor: "gray.300",
              }}
              variant="outline"
              mr={3}
              onClick={handleModalSubmit}
            >
              Save
            </Button>
            <Button onClick={handleModalClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeviceAssignment;
