import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import AppRoutes from "./routes";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import Layout from "./theme/Layout/Layout";
import theme from "./theme/theme";
import { ApiProvider } from "./context/ApiContext";
import LoginPage from "./components/LoginPage";
import { Logout } from "./components/logout";
import { LoadingProvider } from "./context/LoadingContext";
import "./interceptors/axios";
import OtpPhoneInput from "./components/OTPInput";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginData, setLoginData] = useState(null);
  const [role, setRole] = useState("");
  const [dynamicRoutes, setDynamicRoutes] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const userRole = localStorage.getItem("role");
    if (token && userRole) {
      setIsLoggedIn(true);
      setRole(userRole);
    }
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ApiProvider>
        <LoadingProvider>
          <Router>
            <Routes>
              <Route path="/otpverify" element={<OtpPhoneInput setIsLoggedIn={setIsLoggedIn} otp={loginData?.otp} phone={loginData?.phone} role={role}/>}/>
              <Route
                path="/login"
                element={
                  isLoggedIn ? (
                    <Navigate replace to="/dashboard" />
                  ) : (
                    <LoginPage
                      isLoggedIn={isLoggedIn}
                      setIsLoggedIn={setIsLoggedIn}
                      setRole={setRole}
                      setLoginData={setLoginData}
                    />
                  )
                } 
              />
              
              <Route
                path="/*"
                element={
                  isLoggedIn ? (
                    <Layout role={role} setDynamicRoutes={setDynamicRoutes}>
                      <AppRoutes dynamicRoutes={dynamicRoutes} role={role} />
                    </Layout>
                  ) : (
                    <Navigate replace to="/login" />
                  )
                }
              />
              <Route path="/logout" element={<Logout />} />
            </Routes>
          </Router>
        </LoadingProvider>
      </ApiProvider>
    </ChakraProvider>
  );
}

export default App;
