// Disputes.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Icon,
  Flex,
  Spinner,
  VStack,
  HStack,
  useColorModeValue,
  Heading,
  IconButton,
  SimpleGrid,
  Avatar,
  Badge,
  useBreakpointValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
  Spacer,
  ModalFooter,
  Button
} from "@chakra-ui/react";
import { FiArrowLeft, FiAlertCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
} from "chart.js";

// Import Swiper components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Import Swiper modules
import { Navigation, Pagination } from "swiper/modules";

// Register chart components
ChartJS.register(ArcElement, ChartTooltip, Legend);

/**
 * InfoTile Component
 * Displays dispute metrics with a doughnut chart and percentage.
 *
 * @param {object} props - Component props.
 * @param {string} props.title - Title of the metric.
 * @param {string} props.value - Value of the metric.
 * @param {string} props.description - Description of the metric.
 * @param {object} props.chartData - Data for the doughnut chart.
 * @param {string} props.iconColor - Color of the icon.
 * @param {boolean} props.showPercentage - Whether to show percentage below the chart.
 * @returns JSX Element
 */
const InfoTile = ({
  title,
  value,
  description,
  chartData,
  iconColor,
  showPercentage = true,
}) => {
  // Define color variables
  const tileBg = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("gray.800", "white");
  const percentageColor = useColorModeValue("gray.600", "gray.300");

  // Chart colors
  const chartColor1 = useColorModeValue("#666666", "#c2c2c2");
  const chartColor2 = useColorModeValue("#2e2e2e", "#7d7d7d");
  const chartColorHover1 = useColorModeValue("#c2c2c2", "#e0e0e0");
  const chartColorHover2 = useColorModeValue("#e0e0e0", "#e0e0e0");

  // Chart configuration
  const data = {
    labels: chartData.labels,
    datasets: [
      {
        data: chartData.data,
        backgroundColor: chartData.backgroundColor,
        hoverBackgroundColor: chartData.hoverBackgroundColor,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "65%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        titleColor: "#fff",
        bodyColor: "#fff",
        bodyFont: {
          size: 14,
        },
        padding: 10,
        cornerRadius: 4,
        callbacks: {
          label: function (context) {
            const label = context.label || "";
            const value = context.formattedValue || "";
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = ((context.raw / total) * 100).toFixed(2);
            return `${label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  // Calculate percentages
  const total = chartData.data.reduce((a, b) => a + b, 0);
  const percentages = chartData.data.map((value) =>
    ((value / total) * 100).toFixed(2)
  );

  return (
    <Box
      bg={tileBg}
      borderRadius="md"
      p={4}
      w="100%"
      h="320px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {/* Top Section */}
      <Box>
        <HStack align="start" mb={2}>
          <Icon as={FiAlertCircle} w={8} h={8} color={iconColor} />
          <Box>
            <Text fontSize="lg" fontWeight="bold" color={textColor}>
              {title}
            </Text>
            <Text fontSize="sm" color={percentageColor}>
              {description}
            </Text>
          </Box>
        </HStack>
      </Box>
      {/* Doughnut Chart */}
      <Box position="relative" width="100%" height="150px" mt={2}>
        <Doughnut data={data} options={options} />
        {/* Value inside the chart */}
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          textAlign="center"
          pointerEvents="none"
        >
          <Text fontSize="2xl" fontWeight="bold" color={textColor}>
            {value}
          </Text>
        </Box>
        {/* Percentage Texts */}
        {showPercentage && (
          <Box
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            display="flex"
            justifyContent="space-around"
            px={4}
          >
            {chartData.labels.map((label, index) => (
              <VStack key={index} spacing={0}>
                <Text fontSize="sm" color={percentageColor}>
                  {label}
                </Text>
                <Text fontSize="md" fontWeight="bold" color={textColor}>
                  {percentages[index]}%
                </Text>
              </VStack>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

/**
 * DisputeCard Component
 * Displays individual dispute information in a card.
 *
 * @param {object} props - Component props.
 * @param {object} props.dispute - Dispute data.
 * @param {function} props.onOpen - Function to open the modal.
 * @param {function} props.setSelectedDispute - Function to set the selected dispute.
 * @returns JSX Element
 */
const DisputeCard = ({ dispute, onOpen, setSelectedDispute }) => {
  // Define color variables
  const cardBg = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("gray.800", "white");
  const subTextColor = useColorModeValue("gray.600", "gray.300");
  const badgeBgColor = dispute.status === "Resolved" ? "#38A169" : "#E53E3E";
  const badgeTextColor = "white";

  return (
    <Box
      bg={cardBg}
      borderRadius="md"
      p={4}
      w="aauto"
      h="350px"
      boxShadow="md"
      cursor="pointer"
      onClick={() => {
        setSelectedDispute(dispute);
        onOpen();
      }}
    >
      <VStack spacing={3} align="start" h="100%">
        <HStack spacing={3}>
          <Avatar name={dispute.customerName} size="lg" />
          <Box>
            <Text fontSize="xl" fontWeight="bold" color={textColor}>
              {dispute.customerName}
            </Text>
            <Text fontSize="sm" color={subTextColor}>
              Dispute ID: {dispute.id}
            </Text>
          </Box>
        </HStack>
        <Box>
          <Text fontSize="lg" fontWeight="semibold" color={textColor} mt={2}>
            {dispute.title}
          </Text>
          <Text fontSize="sm" color={subTextColor} noOfLines={3}>
            {dispute.description}
          </Text>
        </Box>
        <Spacer />
        <Box alignSelf="flex-end" mt="auto">
          <Badge
            bg={badgeBgColor}
            color={badgeTextColor}
            px={4}
            py={1}
            borderRadius="full"
          >
            {dispute.status}
          </Badge>
        </Box>
      </VStack>
    </Box>
  );
};

/**
 * DisputeDetailsModal Component
 * Displays detailed information about a dispute in a modal.
 *
 * @param {object} props - Component props.
 * @param {boolean} props.isOpen - Whether the modal is open.
 * @param {function} props.onClose - Function to close the modal.
 * @param {object} props.dispute - Dispute data.
 * @returns JSX Element
 */
const DisputeDetailsModal = ({ isOpen, onClose, dispute }) => {
  // Define color variables
  const textColor = useColorModeValue("gray.800", "white");
  const subTextColor = useColorModeValue("gray.600", "gray.300");
  const badgeBgColor = dispute.status === "Resolved" ? "#38A169" : "#E53E3E";
  const badgeTextColor = "white";

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: "full", md: "lg", lg: "4xl" }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Dispute Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="start">
            <HStack spacing={3}>
              <Avatar name={dispute.customerName} size="lg" />
              <Box>
                <Text fontSize="xl" fontWeight="bold" color={textColor}>
                  {dispute.customerName}
                </Text>
                <Text fontSize="sm" color={subTextColor}>
                  Dispute ID: {dispute.id}
                </Text>
              </Box>
            </HStack>
            <Box>
              <Text fontSize="lg" fontWeight="semibold" color={textColor}>
                {dispute.title}
              </Text>
              <Text fontSize="md" color={subTextColor}>
                {dispute.description}
              </Text>
            </Box>
            <Box>
              <Text fontSize="sm" color={subTextColor}>
                Date Submitted: {dispute.dateSubmitted}
              </Text>
              <Text fontSize="sm" color={subTextColor}>
                Last Updated: {dispute.lastUpdated}
              </Text>
            </Box>
            <Box>
              <Text fontSize="md" fontWeight="bold" color={textColor}>
                Status:
              </Text>
              <Badge
                bg={badgeBgColor}
                color={badgeTextColor}
                px={4}
                py={1}
                borderRadius="full"
              >
                {dispute.status}
              </Badge>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
              <Button
                bg="black"
                color={"white"}
                _hover={{
                  color: "black",
                  bg: "white",
                  borderWidth: "1px",
                  borderColor: "gray.300",
                }}
                variant="outline"
                mr={3}
                onClick={onClose}
              >
                Close
              </Button>
            </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

/**
 * Disputes Component
 * Displays the disputes overview and details.
 *
 * @returns JSX Element
 */
const Disputes = () => {
  const [disputeData, setDisputeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [selectedDispute, setSelectedDispute] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Define color variables
  const textColor = useColorModeValue("gray.800", "white");
  const iconColor = useColorModeValue("black", "lightgrey");
  const chartColor1 = useColorModeValue("#666666", "#c2c2c2");
  const chartColor2 = useColorModeValue("#2e2e2e", "#7d7d7d");
  const chartColorHover1 = useColorModeValue("#c2c2c2", "#e0e0e0");
  const chartColorHover2 = useColorModeValue("#e0e0e0", "#e0e0e0");
  
  const slidesPerView = useBreakpointValue({
    base: 1.2,
    sm: 1.5,
    md: 2.5,
    lg: 3.5,
    xl: 4.5,
  });

  // Fetch dispute data (Replace with actual API call)
  useEffect(() => {
    const fetchDisputeData = async () => {
      try {
        // Simulate API call delay
        await new Promise((resolve) => setTimeout(resolve, 1500));

        // Sample data (Replace with actual data)
        const sampleData = {
          totalDisputes: 50,
          resolvedDisputes: 30,
          pendingDisputes: 20,
          disputes: [
            {
              id: "D-1001",
              customerName: "John Doe",
              title: "Incorrect billing amount",
              description:
                "I was overcharged by $10 on my last order. Please review and refund.",
              status: "Pending",
              dateSubmitted: "2023-10-01",
              lastUpdated: "2023-10-02",
            },
            {
              id: "D-1002",
              customerName: "Jane Smith",
              title: "Food quality issue",
              description:
                "The pizza I ordered was burnt and inedible. I request a replacement.",
              status: "Resolved",
              dateSubmitted: "2023-09-25",
              lastUpdated: "2023-09-27",
            },
            {
              id: "D-1003",
              customerName: "Mike Johnson",
              title: "Late delivery",
              description:
                "My order arrived 2 hours late. I would like a refund for the delivery fee.",
              status: "Resolved",
              dateSubmitted: "2023-09-20",
              lastUpdated: "2023-09-22",
            },
            {
              id: "D-1004",
              customerName: "Emily Davis",
              title: "Order not received",
              description:
                "I never received my order even though it shows as delivered.",
              status: "Pending",
              dateSubmitted: "2023-09-18",
              lastUpdated: "2023-09-19",
            },
            {
              id: "D-1005",
              customerName: "David Wilson",
              title: "Wrong item delivered",
              description:
                "I ordered a vegetarian burger but received a beef burger.",
              status: "Resolved",
              dateSubmitted: "2023-09-15",
              lastUpdated: "2023-09-16",
            },
            // Add more disputes as needed
          ],
        };

        setDisputeData(sampleData);
      } catch (error) {
        console.error("Error fetching dispute data:", error);
        setDisputeData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchDisputeData();
  }, []);

  return (
    <Box p={5} color={textColor}>
      {loading ? (
        <Flex justify="center" align="center" minH="80vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        disputeData && (
          <VStack spacing={6} align="stretch">
            {/* Header with Back Button */}
            <Flex align="center">
              <IconButton
                icon={<FiArrowLeft />}
                variant="ghost"
                color={iconColor}
                onClick={() => navigate(-1)}
                aria-label="Go Back"
                mr={4}
                size="lg"
              />
              <HStack spacing={2}>
                <Icon as={FiAlertCircle} w={6} h={6} color={iconColor} />
                <Heading size="lg">Disputes</Heading>
              </HStack>
            </Flex>

            {/* Overview Tiles */}
            <SimpleGrid columns={{ base: 1, sm: 1, md: 3 }} spacing={6}>
              {/* Total Disputes Tile */}
              <InfoTile
                title="Total Disputes"
                value={disputeData.totalDisputes}
                description="Total number of disputes received."
                iconColor="#E53E3E" // Red color
                chartData={{
                  labels: ["Total Disputes"],
                  data: [1],
                  backgroundColor: [chartColor1],
                  hoverBackgroundColor: [chartColorHover1],
                }}
                showPercentage={false}
              />
              {/* Resolved Disputes Tile */}
              <InfoTile
                title="Resolved Disputes"
                value={disputeData.resolvedDisputes}
                description="Number of disputes that have been resolved."
                iconColor="#38A169" // Green color
                chartData={{
                  labels: ["Resolved", "Pending"],
                  data: [
                    disputeData.resolvedDisputes,
                    disputeData.totalDisputes - disputeData.resolvedDisputes,
                  ],
                  backgroundColor: [chartColor1, chartColor2],
                  hoverBackgroundColor: [chartColorHover1, chartColorHover2],
                }}
              />
              {/* Pending Disputes Tile */}
              <InfoTile
                title="Pending Disputes"
                value={disputeData.pendingDisputes}
                description="Number of disputes awaiting resolution."
                iconColor="#DD6B20" // Orange color
                chartData={{
                  labels: ["Pending", "Resolved"],
                  data: [
                    disputeData.pendingDisputes,
                    disputeData.totalDisputes - disputeData.pendingDisputes,
                  ],
                  backgroundColor: [chartColor1, chartColor2],
                  hoverBackgroundColor: [chartColorHover1, chartColorHover2],
                }}
              />
            </SimpleGrid>

            {/* Disputes Carousel */}
            <Box>
              <Heading size="md" mb={4}>
                Disputes Overview
              </Heading>
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={10}
                slidesPerView={slidesPerView}
                navigation
                pagination={{ clickable: true }}
              >
                {disputeData.disputes.map((dispute) => (
                  <SwiperSlide key={dispute.id}>
                    <DisputeCard
                      dispute={dispute}
                      onOpen={onOpen}
                      setSelectedDispute={setSelectedDispute}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
            {/* Dispute Details Modal */}
            {selectedDispute && (
              <DisputeDetailsModal
                isOpen={isOpen}
                onClose={onClose}
                dispute={selectedDispute}
              />
            )}
          </VStack>
        )
      )}
    </Box>
  );
};

export default Disputes;
