import React from "react";
import { Box, Heading, Text, VStack, Button, HStack } from "@chakra-ui/react";

const HelpSupport = () => {
  return (
    <Box p="4">
      <Heading size="lg" mb="4">
        Help & Support
      </Heading>
      <VStack spacing="8" w="full">
        <HStack spacing="4" w="full">
          <VStack w="full" align={"flex-start"}>
            <Text fontSize="lg">Frequently Asked Questions</Text>
            <Text>Find answers to the most common questions.</Text>
          </VStack>

          <Button
            w="20%"
            flexGrow={1}
            //leftIcon={<IoMdAdd />}
            bg="black"
            color={"white"}
            _hover={{
              color: "black",
              bg: "white",
              borderWidth: "1px",
              borderColor: "gray.300",
            }}
            variant="outline"
          >
            View FAQs
          </Button>
        </HStack>
        <HStack spacing="4" w="full">
          <VStack w="full" align={"flex-start"}>
            <Text fontSize="lg">User Guides</Text>
            <Text>Read our comprehensive user guides and tutorials.</Text>
          </VStack>

          <Button
            w="20%"
            flexGrow={1}
            //leftIcon={<IoMdAdd />}
            bg="black"
            color={"white"}
            _hover={{
              color: "black",
              bg: "white",
              borderWidth: "1px",
              borderColor: "gray.300",
            }}
            variant="outline"
          >
            View Guides
          </Button>
        </HStack>
        <HStack spacing="4" w="full">
          <VStack w="full" align={"flex-start"}>
            <Text fontSize="lg">Contact Support</Text>
            <Text>Need help? Get in touch with our support team.</Text>
          </VStack>
          <Button
            w="20%"
            flexGrow={1}
            //leftIcon={<IoMdAdd />}
            bg="black"
            color={"white"}
            _hover={{
              color: "black",
              bg: "white",
              borderWidth: "1px",
              borderColor: "gray.300",
            }}
            variant="outline"
          >
            Contact Support
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default HelpSupport;