import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { MerchantBox } from "./MerchantBox";
import API from "../../context/API";
import { useLoading } from "../../context/LoadingContext";
import { LoadingAnimation } from "../LoadingAnimation";

const MerchantManager = ({ role }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentMerchantManager, setCurrentMerchantManager] = useState(null);
  const [merchantManagers, setMerchantManagers] = useState([]);
  const [filteredMerchantManagers, setFilteredMerchantManagers] = useState([]);
  const toast = useToast();
  const { isLoading, setLoading } = useLoading();
  const { colorMode } = useColorMode();

  async function fetchMerchantManagers() {
    try {
      const merchantManagersResponse = await API.get("manager/all", {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });
      const merchantManagersData = merchantManagersResponse.data.data;
      setMerchantManagers(merchantManagersData);
      setFilteredMerchantManagers(merchantManagersData);
      setLoading(false);
    } catch (error) {
      toast({
        title: "Error fetching merchant managers.",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    setLoading(true);
    fetchMerchantManagers();
    return () => {
      setMerchantManagers([]);
    };
  }, []);

  const handleAddClick = () => {
    setCurrentMerchantManager(null);
    setIsEditing(true);
  };

  const handleItemClick = (itemId) => {
    const merchantManagerToEdit = merchantManagers.find(
      (item) => item.id === itemId
    );
    setCurrentMerchantManager(merchantManagerToEdit);
    setIsEditing(true);
  };

  const handleDeleteClick = async (itemId) => {
    try {
      const deleteMerchantManagerResponse = await API.delete(
        `manager/${itemId}`,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        }
      );
      if (deleteMerchantManagerResponse.data.status === 200) {
        toast({
          title: "Success.",
          description: "Merchant manager deleted successfully.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        const updatedMerchantManagers = merchantManagers.filter(
          (item) => item.id !== itemId
        );
        setMerchantManagers(updatedMerchantManagers);
        setFilteredMerchantManagers(updatedMerchantManagers);
      } else {
        toast({
          title: "Error.",
          description: deleteMerchantManagerResponse.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error.",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleSearch = (event) => {
    const filter = event.target.value.toLowerCase();
    if (!filter) {
      setFilteredMerchantManagers(merchantManagers);
      return;
    }
    const filtered = merchantManagers.filter(
      (item) =>
        item.name.toLowerCase().includes(filter) ||
        item.username.toLowerCase().includes(filter) ||
        item.email.toLowerCase().includes(filter)
    );
    setFilteredMerchantManagers(filtered);
  };

  if (role !== "merchant") {
    return <Box>You don't have the permissions to access this page.</Box>;
  } else if (isLoading) {
    return (
      <>
        <LoadingAnimation size="contentArea" />
      </>
    );
  }

  return (
    <>
      {!isEditing ? (
        <>
          <Box>
            <Flex
              justify={"space-between"}
              alignItems={"center"}
              width={"100%"}
              flexWrap={"wrap"}
              mt={4}
            >
              <Box>
                <Heading
                  size={{ base: "lg", md: "xl" }}
                  letterSpacing={-2}
                >
                  Merchant Managers
                </Heading>
              </Box>
              <Box>
                <Button
                  leftIcon={<IoMdAdd />}
                  bg="black"
                  color={"white"}
                  _hover={{
                    color: "black",
                    bg: "white",
                    borderWidth: "1px",
                    borderColor: "gray.300",
                  }}
                  variant="outline"
                  onClick={handleAddClick}
                  size={{ base: "sm", md: "md" }}
                  display={{ base: "none", md: "flex" }} // Hide on mobile
                >
                  Add Merchant Manager
                </Button>
                <IconButton
                  onClick={handleAddClick}
                  display={{ base: "flex", md: "none" }} // Show on mobile
                  size="sm"
                  bg="black"
                  color="white"
                  _hover={{
                    color: "black",
                    bg: "white",
                    borderWidth: "1px",
                    borderColor: "gray.300",
                  }}
                  borderRadius="full" // Rounded button for mobile
                  icon={<IoMdAdd />}
                />
              </Box>
            </Flex>
            <Box m={4}>
              <InputGroup size={"md"}>
                <InputLeftElement
                  pointerEvents="none"
                  children={<SearchIcon color="gray.300" />}
                />
                <Input
                  type="search"
                  placeholder="Search..."
                  _placeholder={{ color: "gray.500" }}
                  onKeyUp={handleSearch}
                />
              </InputGroup>
            </Box>
          </Box>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th fontSize={{ base: "sm", md: "md" }}>Name</Th>
                  <Th fontSize={{ base: "sm", md: "md" }}>Username</Th>
                  <Th fontSize={{ base: "sm", md: "md" }}>Email</Th>
                  <Th fontSize={{ base: "sm", md: "md" }}>Phone</Th>
                  <Th fontSize={{ base: "sm", md: "md" }}>Address</Th>
                  <Th fontSize={{ base: "sm", md: "md" }}>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredMerchantManagers.map((item) => (
                  <Tr key={item.id}>
                    <Td>
                      <Link
                        color={colorMode === "light" ? "green" : "lightgreen"}
                        onClick={() => handleItemClick(item.id)}
                        fontSize={{ base: "sm", md: "md" }} // Responsive font size
                      >
                        {item.name}
                      </Link>
                    </Td>
                    <Td fontSize={{ base: "sm", md: "md" }}>{item.username}</Td>
                    <Td fontSize={{ base: "sm", md: "md" }}>{item.email}</Td>
                    <Td fontSize={{ base: "sm", md: "md" }}>{item.phone}</Td>
                    <Td fontSize={{ base: "sm", md: "md" }}>{item.address}</Td>
                    <Td>
                      <Button
                        variant="ghost"
                        colorScheme="red"
                        onClick={() => handleDeleteClick(item.id)}
                      >
                        <MdDelete />
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <MerchantBox
          initialMerchant={currentMerchantManager}
          onSave={() => {
            setIsEditing(false);
            fetchMerchantManagers();
          }}
          onCancel={() => setIsEditing(false)}
          fetchMerchants={fetchMerchantManagers}
          isManagerMode={true} // Pass the prop to indicate manager mode
        />
      )}
    </>
  );
};

export default MerchantManager;
