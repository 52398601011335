import React, { useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Grid,
  GridItem,
  useColorModeValue,
  Collapse,
} from "@chakra-ui/react";

function MerchantReports() {
  const [selectedItem, setSelectedItem] = useState(null);

  const hoverBg = useColorModeValue('gray.100', 'gray.700');
  const selectedBg = useColorModeValue('gray.200', 'gray.600');

  const handleClick = (item) => {
    setSelectedItem(item === selectedItem ? null : item);
  };

  const renderShopPerformance = () => (
    <Collapse in={selectedItem === 'shopPerformance'} animateOpacity>
      <Box mt="4" p="4" borderWidth="1px" borderRadius="md" boxShadow="sm">
        <Heading size="md">Shop Performance Details</Heading>
        <Text marginBottom="4">Detailed report on shop performance...</Text>
      </Box>
    </Collapse>
  );

  const renderStaffPerformance = () => (
    <Collapse in={selectedItem === 'staffPerformance'} animateOpacity>
      <Box mt="4" p="4" borderWidth="1px" borderRadius="md" boxShadow="sm">
        <Heading size="md">Staff Performance Details</Heading>
        <Text marginBottom="4">Detailed report on staff performance...</Text>
      </Box>
    </Collapse>
  );

  const renderCustomerInsights = () => (
    <Collapse in={selectedItem === 'customerInsights'} animateOpacity>
      <Box mt="4" p="4" borderWidth="1px" borderRadius="md" boxShadow="sm">
        <Heading size="md">Customer Insights Details</Heading>
        <Text marginBottom="4">Detailed report on customer insights...</Text>
      </Box>
    </Collapse>
  );

  const renderFinancialReports = () => (
    <Collapse in={selectedItem === 'financialReports'} animateOpacity>
      <Box mt="4" p="4" borderWidth="1px" borderRadius="md" boxShadow="sm">
        <Heading size="md">Financial Reports Details</Heading>
        <Text marginBottom="4">Detailed report on financials...</Text>
      </Box>
    </Collapse>
  );

  return (
    <Flex direction="column">
      <Box as="main" flex="1" padding="4">
        <Heading size="lg" marginBottom="4">Reports</Heading>

        <Grid templateColumns="repeat(2, 1fr)" gap="4" marginBottom="4">
          <GridItem
            padding="4"
            borderRadius="md"
            boxShadow="sm"
            cursor="pointer"
            onClick={() => handleClick('shopPerformance')}
            _hover={{ backgroundColor: hoverBg, transition: 'background-color 0.3s ease' }}
            backgroundColor={selectedItem === 'shopPerformance' ? selectedBg : 'transparent'}
            transition="background-color 0.3s ease"
          >
            <Text fontSize="xl" fontWeight="bold">Shop Performance</Text>
            <Text fontSize="2xl">Detailed overview</Text>
          </GridItem>
          <GridItem
            padding="4"
            borderRadius="md"
            boxShadow="sm"
            cursor="pointer"
            onClick={() => handleClick('staffPerformance')}
            _hover={{ backgroundColor: hoverBg, transition: 'background-color 0.3s ease' }}
            backgroundColor={selectedItem === 'staffPerformance' ? selectedBg : 'transparent'}
            transition="background-color 0.3s ease"
          >
            <Text fontSize="xl" fontWeight="bold">Staff Performance</Text>
            <Text fontSize="2xl">Detailed overview</Text>
          </GridItem>
          <GridItem
            padding="4"
            borderRadius="md"
            boxShadow="sm"
            cursor="pointer"
            onClick={() => handleClick('customerInsights')}
            _hover={{ backgroundColor: hoverBg, transition: 'background-color 0.3s ease' }}
            backgroundColor={selectedItem === 'customerInsights' ? selectedBg : 'transparent'}
            transition="background-color 0.3s ease"
          >
            <Text fontSize="xl" fontWeight="bold">Customer Insights</Text>
            <Text fontSize="2xl">Detailed overview</Text>
          </GridItem>
          <GridItem
            padding="4"
            borderRadius="md"
            boxShadow="sm"
            cursor="pointer"
            onClick={() => handleClick('financialReports')}
            _hover={{ backgroundColor: hoverBg, transition: 'background-color 0.3s ease' }}
            backgroundColor={selectedItem === 'financialReports' ? selectedBg : 'transparent'}
            transition="background-color 0.3s ease"
          >
            <Text fontSize="xl" fontWeight="bold">Financial Reports</Text>
            <Text fontSize="2xl">Detailed overview</Text>
          </GridItem>
        </Grid>

        {renderShopPerformance()}
        {renderStaffPerformance()}
        {renderCustomerInsights()}
        {renderFinancialReports()}
      </Box>
    </Flex>
  );
}

export default MerchantReports;
