import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Flex,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    Link,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorMode,
    useToast,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { DeviceBox } from "./DeviceBox";
import API from "../../context/API";

const Devices = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [currentDevice, setCurrentDevice] = useState(null);
    const [devices, setDevices] = useState([]);
    const [filteredDevices, setFilteredDevices] = useState([]);
    const toast = useToast();
    const {colorMode} = useColorMode();

    async function fetchDevices() {
        try {
            const devicesResponse = await API.get("devices", {
                headers: { "Content-Type": "application/json" },
                withCredentials: false,
            });
            const devicesData = devicesResponse.data.data;
            setDevices(devicesData);
            setFilteredDevices(devicesData);
        } catch (error) {
            toast({
                title: "Error fetching devices.",
                description: error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    }

    useEffect(() => {
        fetchDevices();
        return () => {
            setDevices([]);
        };
    }, []);

    const handleAddClick = () => {
        setCurrentDevice(null);
        setIsEditing(true);
    };

    const handleItemClick = (itemId) => {
        const deviceToEdit = devices.find((item) => item.id === itemId);
        setCurrentDevice(deviceToEdit);
        setIsEditing(true);
    };

    const handleDeleteClick = async (itemId) => {
        try {
            const deleteDeviceResponse = await API.delete(`devices/${itemId}`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: false,
            });
            if (deleteDeviceResponse.data.status === 200) {
                toast({
                    title: "Success.",
                    description: "Device deleted successfully.",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
                const updatedDevices = devices.filter((item) => item.id !== itemId);
                setDevices(updatedDevices);
                setFilteredDevices(updatedDevices);
            } else {
                toast({
                    title: "Error.",
                    description: deleteDeviceResponse.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Error.",
                description: error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleSearch = (event) => {
        const filter = event.target.value.toLowerCase();
        if (!filter) {
            setFilteredDevices(devices);
            return;
        }
        const filtered = devices.filter(
            (item) =>
                item.name.toLowerCase().includes(filter) ||
                item.identifier.toLowerCase().includes(filter) ||
                (item.type && item.type.name.toLowerCase().includes(filter))
        );
        setFilteredDevices(filtered);
    };

    return (
      <>
        {!isEditing ? (
          <>
            <Box>
              <Flex
                justify={"space-between"}
                alignItems={"center"}
                width={"100%"}
                flexWrap={"wrap"}
                mt={4}
              >
                <Heading size={"xl"} letterSpacing={-2}>
                  Devices
                </Heading>
                <Box>
                  <Button
                    leftIcon={<IoMdAdd />}
                    bg="black"
                    color={"white"}
                    _hover={{
                      color: "black",
                      bg: "white",
                      borderWidth: "1px",
                      borderColor: "gray.300",
                    }}
                    variant="outline"
                    onClick={handleAddClick}
                  >
                    Add Device
                  </Button>
                </Box>
              </Flex>
              <Box m={4}>
                <InputGroup size={"md"}>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<SearchIcon color="gray.300" />}
                  />
                  <Input
                    type="search"
                    placeholder="Search..."
                    _placeholder={{ color: "gray.500" }}
                    onKeyUp={handleSearch}
                  />
                </InputGroup>
              </Box>
            </Box>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Identifier</Th>
                    <Th>Type</Th>
                    <Th>Merchant</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredDevices.map((item) => (
                    <Tr key={item.id}>
                      <Td>
                        <Link
                          color={colorMode === "light" ? "green" : "lightgreen"}
                          onClick={() => handleItemClick(item.id)}
                        >
                          {item.name}
                        </Link>
                      </Td>
                      <Td>{item.identifier}</Td>
                      <Td>{item.type?.name}</Td>
                      <Td>{item.merchant?.name}</Td>
                      <Td>
                        <Button
                          variant="ghost"
                          colorScheme="red"
                          onClick={() => handleDeleteClick(item.id)}
                        >
                          <MdDelete />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <DeviceBox
            initialDevice={currentDevice}
            onSave={() => setIsEditing(false)}
            onCancel={() => setIsEditing(false)}
            fetchDevices={fetchDevices}
          />
        )}
      </>
    );
};

export default Devices;
