import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";

function PaymentsAdmin() {
  const [pendingPayments, setPendingPayments] = useState([]);
  const [clearedPayments, setClearedPayments] = useState([]);
  const [upcomingPayments, setUpcomingPayments] = useState([]);

  useEffect(() => {
    // Placeholder for fetching data from APIs in the future
    const fetchPaymentsData = async () => {
      try {
        // Initially setting all payment states to empty arrays to simulate no data
        setPendingPayments([]); 
        setClearedPayments([]);
        setUpcomingPayments([]);
      } catch (error) {
        console.error("Error fetching payment data:", error);
        setPendingPayments(null);
        setClearedPayments(null);
        setUpcomingPayments(null);
      }
    };

    fetchPaymentsData();
  }, []);

  const renderNoDataMessage = () => (
    <Tr>
      <Td colSpan="3" textAlign="center">
        <Text fontSize="lg" color="gray.500">No data to display</Text>
      </Td>
    </Tr>
  );

  return (
    <Flex direction="column" padding="4">
      <Heading size="lg" marginBottom="4">Payment Management</Heading>

      {/* Pending Payments */}
      <Box padding="4" borderRadius="md" boxShadow="sm" marginBottom="4">
        <Heading size="md" marginBottom="4">Pending Payments</Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Merchant</Th>
              <Th>Amount</Th>
              <Th>Due Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {pendingPayments && pendingPayments.length > 0 ? (
              pendingPayments.map((payment, index) => (
                <Tr key={index}>
                  <Td>{payment.merchant}</Td>
                  <Td>{payment.amount}</Td>
                  <Td>{payment.dueDate}</Td>
                </Tr>
              ))
            ) : (
              renderNoDataMessage()
            )}
          </Tbody>
        </Table>
      </Box>

      {/* Merchants Who Cleared Payments */}
      <Box padding="4" borderRadius="md" boxShadow="sm" marginBottom="4">
        <Heading size="md" marginBottom="4">Merchants Who Cleared Payments</Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Merchant</Th>
              <Th>Amount</Th>
              <Th>Payment Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {clearedPayments && clearedPayments.length > 0 ? (
              clearedPayments.map((payment, index) => (
                <Tr key={index}>
                  <Td>{payment.merchant}</Td>
                  <Td>{payment.amount}</Td>
                  <Td>{payment.paymentDate}</Td>
                </Tr>
              ))
            ) : (
              renderNoDataMessage()
            )}
          </Tbody>
        </Table>
      </Box>

      {/* Upcoming Payments */}
      <Box padding="4" borderRadius="md" boxShadow="sm" marginBottom="4">
        <Heading size="md" marginBottom="4">Upcoming Payments</Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Merchant</Th>
              <Th>Amount</Th>
              <Th>Due Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {upcomingPayments && upcomingPayments.length > 0 ? (
              upcomingPayments.map((payment, index) => (
                <Tr key={index}>
                  <Td>{payment.merchant}</Td>
                  <Td>{payment.amount}</Td>
                  <Td>{payment.dueDate}</Td>
                </Tr>
              ))
            ) : (
              renderNoDataMessage()
            )}
          </Tbody>
        </Table>
      </Box>
    </Flex>
  );
}

export default PaymentsAdmin;
