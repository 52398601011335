import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { MerchantBox } from "./MerchantBox";
import API from "../../context/API";
import { useLoading } from "../../context/LoadingContext";
import { LoadingAnimation } from "../LoadingAnimation";

const Merchants = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentMerchant, setCurrentMerchant] = useState(null);
  const [merchants, setMerchants] = useState([]);
  const [filteredMerchants, setFilteredMerchants] = useState([]);
  const toast = useToast();
  const { isLoading, setLoading } = useLoading();
  const { colorMode } = useColorMode();

  async function fetchMerchants() {
    try {
      const merchantsResponse = await API.get("merchant", {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });
      const merchantsData = merchantsResponse.data.data;
      setMerchants(merchantsData);
      setFilteredMerchants(merchantsData);
      setLoading(false);
    } catch (error) {
      toast({
        title: "Error fetching merchants.",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    setLoading(true);
    fetchMerchants();
    return () => {
      setMerchants([]);
    };
  }, []);

  const handleAddClick = () => {
    setCurrentMerchant(null);
    setIsEditing(true);
  };

  const handleItemClick = (itemId) => {
    const merchantToEdit = merchants.find((item) => item.id === itemId);
    setCurrentMerchant(merchantToEdit);
    setIsEditing(true);
  };

  const handleDeleteClick = async (itemId) => {
    try {
      const deleteMerchantResponse = await API.delete(`merchant/${itemId}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: false,
      });
      if (deleteMerchantResponse.data.status === 200) {
        toast({
          title: "Success.",
          description: "Merchant deleted successfully.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        const updatedMerchants = merchants.filter((item) => item.id !== itemId);
        setMerchants(updatedMerchants);
        setFilteredMerchants(updatedMerchants);
      } else {
        toast({
          title: "Error.",
          description: deleteMerchantResponse.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error.",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleSearch = (event) => {
    const filter = event.target.value.toLowerCase();
    if (!filter) {
      setFilteredMerchants(merchants);
      return;
    }
    const filtered = merchants.filter(
      (item) =>
        item.name.toLowerCase().includes(filter) ||
        item.username.toLowerCase().includes(filter) ||
        item.email.toLowerCase().includes(filter)
    );
    setFilteredMerchants(filtered);
  };
  if (isLoading) {
    return (
      <>
        <LoadingAnimation size="contentArea" />
      </>
    );
  }
  return (
    <>
      {!isEditing ? (
        <>
          <Box>
            <Flex
              justify={"space-between"}
              alignItems={"center"}
              width={"100%"}
              flexWrap={"wrap"}
              mt={4}
            >
              <Box>
                <Heading size={"xl"} letterSpacing={-2}>
                  Merchants
                </Heading>
              </Box>
              <Box>
                <Button
                  leftIcon={<IoMdAdd />}
                  bg="black"
                  color={"white"}
                  _hover={{
                    color: "black",
                    bg: "white",
                    borderWidth: "1px",
                    borderColor: "gray.300",
                  }}
                  variant="outline"
                  onClick={handleAddClick}
                >
                  Add Merchant
                </Button>
              </Box>
            </Flex>
            <Box m={4}>
              <InputGroup size={"md"}>
                <InputLeftElement
                  pointerEvents="none"
                  children={<SearchIcon color="gray.300" />}
                />
                <Input
                  type="search"
                  placeholder="Search..."
                  _placeholder={{ color: "gray.500" }}
                  onKeyUp={handleSearch}
                />
              </InputGroup>
            </Box>
          </Box>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Username</Th>
                  <Th>Email</Th>
                  <Th>Phone</Th>
                  <Th>Address</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredMerchants.map((item) => (
                  <Tr key={item.id}>
                    <Td>
                      <Link
                        color={colorMode === "light" ? "green" : "lightgreen"}
                        onClick={() => handleItemClick(item.id)}
                      >
                        {item.name}
                      </Link>
                    </Td>
                    <Td>{item.username}</Td>
                    <Td>{item.email}</Td>
                    <Td>{item.phone}</Td>
                    <Td>{item.address}</Td>
                    <Td>
                      <Button
                        variant="ghost"
                        colorScheme="red"
                        onClick={() => handleDeleteClick(item.id)}
                      >
                        <MdDelete />
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <MerchantBox
          initialMerchant={currentMerchant}
          onSave={() => setIsEditing(false)}
          onCancel={() => setIsEditing(false)}
          fetchMerchants={fetchMerchants}
        />
      )}
    </>
  );
};

export default Merchants;
