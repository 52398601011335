import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Grid,
  GridItem,
  useColorModeValue,
  Collapse,
  VStack,
  IconButton,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

function DashboardStaff() {
  // Static data
  const staticShiftDetails = {
    start_time: "09:00 AM",
    end_time: "05:00 PM",
    section: "Dining Area",
    specials: "Grilled Salmon"
  };

  const staticDailyTasks = [
    { description: "Set up tables" },
    { description: "Prep vegetables" },
    { description: "Clean the dining area" }
  ];

  const staticPerformanceMetrics = {
    serviceSpeed: "95%",
    customerFeedback: "4.8/5",
    salesSummary: "1500"
  };

  const staticAnnouncements = [
    { message: "Staff meeting at 3 PM." },
    { message: "New menu items launching tomorrow." }
  ];

  // State management
  const [shiftDetails, setShiftDetails] = useState(staticShiftDetails);
  const [dailyTasks, setDailyTasks] = useState(staticDailyTasks);
  const [performanceMetrics, setPerformanceMetrics] = useState(staticPerformanceMetrics);
  const [announcements, setAnnouncements] = useState(staticAnnouncements);
  const [selectedItem, setSelectedItem] = useState('shiftDetails');

  // Uncomment the following useEffect when you switch to API data
  // useEffect(() => {
  //   const fetchDashboardData = async () => {
  //     try {
  //       const shiftResponse = await API.get("staff/shift");
  //       setShiftDetails(shiftResponse.data.data);

  //       const tasksResponse = await API.get("staff/tasks");
  //       setDailyTasks(tasksResponse.data.data);

  //       const performanceResponse = await API.get("staff/performance");
  //       setPerformanceMetrics(performanceResponse.data.data);

  //       const announcementsResponse = await API.get("announcements");
  //       setAnnouncements(announcementsResponse.data.data);
  //     } catch (error) {
  //       console.error('Error fetching dashboard data:', error);
  //     }
  //   };

  //   fetchDashboardData();
  // }, []);

  const hoverBg = useColorModeValue('gray.100', 'gray.700');
  const selectedBg = useColorModeValue('gray.200', 'gray.600');

  const handleClick = (item) => {
    setSelectedItem(item === selectedItem ? null : item);
  };

  const renderShiftDetails = () => (
    <Collapse in={true} animateOpacity>
      <Box
        mt="4"
        p="4"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="sm"
      >
        <Heading size="md">Shift Details</Heading>
        <Text marginBottom="4">
          Shift Time: {shiftDetails?.start_time} - {shiftDetails?.end_time}
        </Text>
        <Text>Assigned Section: {shiftDetails?.section}</Text>
        <Text>Today's Specials: {shiftDetails?.specials}</Text>
      </Box>
    </Collapse>
  );

  const renderDailyTasks = () => (
    <Collapse in={selectedItem === 'dailyTasks'} animateOpacity>
      <Box
        mt="4"
        p="4"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="sm"
      >
        <Flex justify="space-between">
          <Heading size="md">Daily Tasks</Heading>
          <IconButton
            icon={<CloseIcon />}
            size="sm"
            onClick={() => setSelectedItem(null)}
            aria-label="Close"
          />
        </Flex>
        <VStack align="start" spacing="2">
          {dailyTasks.map((task, index) => (
            <Text key={index}>{task.description}</Text>
          ))}
        </VStack>
      </Box>
    </Collapse>
  );

  const renderPerformanceMetrics = () => (
    <Collapse in={selectedItem === 'performanceMetrics'} animateOpacity>
      <Box
        mt="4"
        p="4"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="sm"
      >
        <Flex justify="space-between">
          <Heading size="md">Performance Metrics</Heading>
          <IconButton
            icon={<CloseIcon />}
            size="sm"
            onClick={() => setSelectedItem(null)}
            aria-label="Close"
          />
        </Flex>
        <Text marginBottom="4">Service Speed: {performanceMetrics.serviceSpeed}</Text>
        <Text>Customer Feedback: {performanceMetrics.customerFeedback}</Text>
        <Text>Sales Summary: ${performanceMetrics.salesSummary}</Text>
      </Box>
    </Collapse>
  );

  const renderAnnouncements = () => (
    <Collapse in={selectedItem === 'announcements'} animateOpacity>
      <Box
        mt="4"
        p="4"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="sm"
      >
        <Flex justify="space-between">
          <Heading size="md">Announcements</Heading>
          <IconButton
            icon={<CloseIcon />}
            size="sm"
            onClick={() => setSelectedItem(null)}
            aria-label="Close"
          />
        </Flex>
        <VStack align="start" spacing="2">
          {announcements.map((announcement, index) => (
            <Text key={index}>{announcement.message}</Text>
          ))}
        </VStack>
      </Box>
    </Collapse>
  );

  return (
    <Flex direction="column">
      <Flex direction="row" flex="1">
        {/* Main Content Area */}
        <Box as="main" flex="1" padding="4">
          <Heading size="lg" marginBottom="4">Dashboard</Heading>

          {/* Key Metrics */}
          <Grid templateColumns="repeat(2, 1fr)" gap="4" marginBottom="4">
            <GridItem
              padding="4"
              borderRadius="md"
              boxShadow="sm"
              backgroundColor={selectedBg} // First item is always selected
            >
              <Text fontSize="xl" fontWeight="bold">Shift Details</Text>
            </GridItem>
            <GridItem
              padding="4"
              borderRadius="md"
              boxShadow="sm"
              cursor="pointer"
              onClick={() => handleClick('dailyTasks')}
              _hover={{ backgroundColor: hoverBg, transition: 'background-color 0.3s ease' }}
              backgroundColor={selectedItem === 'dailyTasks' ? selectedBg : 'transparent'}
              transition="background-color 0.3s ease"
            >
              <Text fontSize="xl" fontWeight="bold">Daily Tasks</Text>
            </GridItem>
            <GridItem
              padding="4"
              borderRadius="md"
              boxShadow="sm"
              cursor="pointer"
              onClick={() => handleClick('performanceMetrics')}
              _hover={{ backgroundColor: hoverBg, transition: 'background-color 0.3s ease' }}
              backgroundColor={selectedItem === 'performanceMetrics' ? selectedBg : 'transparent'}
              transition="background-color 0.3s ease"
            >
              <Text fontSize="xl" fontWeight="bold">Performance Metrics</Text>
            </GridItem>
            <GridItem
              padding="4"
              borderRadius="md"
              boxShadow="sm"
              cursor="pointer"
              onClick={() => handleClick('announcements')}
              _hover={{ backgroundColor: hoverBg, transition: 'background-color 0.3s ease' }}
              backgroundColor={selectedItem === 'announcements' ? selectedBg : 'transparent'}
              transition="background-color 0.3s ease"
            >
              <Text fontSize="xl" fontWeight="bold">Announcements</Text>
            </GridItem>
          </Grid>

          {/* Collapse Sections */}
          {renderShiftDetails()}
          {renderDailyTasks()}
          {renderPerformanceMetrics()}
          {renderAnnouncements()}
        </Box>
      </Flex>
    </Flex>
  );
}

export default DashboardStaff;
