import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Image,
  Heading,
  Spinner,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  useDisclosure,
  IconButton,
  useToast,
  Text,
  Input,
  Checkbox,
  Collapse,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useColorModeValue,
  HStack,
  InputRightAddon,
  InputGroup,
  List,
  ListItem,
  Wrap,
  WrapItem,
  Badge,
  CloseButton,
  Switch,
} from "@chakra-ui/react";
import {
  AddIcon,
  EditIcon,
  DeleteIcon,
  CheckIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import API, {getCompressedFile, getMediaURL, mediaUploadURL} from "../../context/API";
import SearchBar from "../../theme/Layout/Searchbar";
import { IoIosRefresh } from "react-icons/io";
import { GrUpdate } from "react-icons/gr";

function areItemDataEqual(obj1, obj2) {
  const keys = ["item", "name", "description", "price","taxable"];
  for (let key of keys) {
    if (obj1[key] !== obj2[key]) return false;
  }
  return true;
}

function areArraysEqualById(arr1, arr2) {
  const ids1 = arr1.map((item) => item.id).sort();
  const ids2 = arr2.map((item) => item.id).sort();
  if (ids1.length !== ids2.length) return false;
  for (let i = 0; i < ids1.length; i++) {
    if (ids1[i] !== ids2[i]) return false;
  }
  return true;
}

function Items({tabIndex, role }) {
  //tracking states
  const [initialItemData, setInitialItemData] = useState(null);
  const [initialCustomizations, setInitialCustomizations] = useState([]);
  const [initialCategories, setInitialCategories] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [imageUploadError, setImageUploadError] = useState(false);

  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [refreshing, setRefreshing] = useState(false); // Separate state for refresh loading
  const [lastUpdated, setLastUpdated] = useState(null); // To store last updated time
  const [imageFile, setImageFile] = useState(null);
  const imageInputRef = useRef(null);

  // Customizations States
  const [allCustomizations, setAllCustomizations] = useState([]); // Stores all customizations
  const [customizations, setCustomizations] = useState([]);
  const [filteredCustomizations, setFilteredCustomizations] = useState([]);
  const [queryCustomizations, setQueryCustomizations] = useState("");
  const [showSuggestionsCustomizations, setShowSuggestionsCustomizations] =
    useState(false);
  const suggestionsBoxRef = useRef(null);

  // Categories States
  const [categoryOptions, setCategoryOptions] = useState([]); // All categories from API
  const [filteredCategories, setFilteredCategories] = useState([]); // Filtered based on search
  const [queryCategories, setQueryCategories] = useState(""); // Search query for categories
  const [showSuggestionsCategories, setShowSuggestionsCategories] =
    useState(false);
  const [categories, setCategories] = useState([]); // Selected categories
  const categoriesBoxRef = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const bgColor = useColorModeValue("white", "gray.800");
  const hoverBg = useColorModeValue("gray.200", "gray.600");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const toast = useToast();

  const [itemData, setItemData] = useState({
    item: "",
    name: "",
    description: "",
    taxable:true,
    sequence_number: "",
    price: "",
    picture: "",
  });

  const fetchItems = async () => {
    setRefreshing(true);

    try {
      const response = await API.get("/items");
      const itemsData = response.data.data.map((item) => ({
        ...item,
        picture: getMediaURL(item.picture),
      }));
      if (response.data.success) {
        setItems(itemsData);
        setFilteredItems(itemsData);
        setLastUpdated(new Date().toLocaleString());
      } else {
        setError("Failed to fetch items.");
      }
    } catch (error) {
      setError("An error occurred while fetching items.");
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  useEffect(() => {
    if(tabIndex === 3){
      fetchItems();
      fetchAllCustomizations();
      fetchCategories();
    }
  }, [tabIndex]);

  useEffect(() => {
    const hasCategoriesChanged = !areArraysEqualById(categories, initialCategories);
    if (selectedItem) {
      const hasItemDataChanged = !areItemDataEqual(itemData, initialItemData);
      const hasCustomizationsChanged = !areArraysEqualById(customizations, initialCustomizations);
      const hasImageChanged = !!imageFile && !imageUploadError; // Only consider if image upload hasn't failed
      setIsChanged(
        (hasItemDataChanged ||
          hasCustomizationsChanged ||
          hasCategoriesChanged ||
          hasImageChanged) &&
          !imageUploadError
      );
    } else {
      setIsChanged(
        itemData.item.trim() !== "" && !imageUploadError && hasCategoriesChanged
      );
    }
  }, [
    itemData,
    customizations,
    categories,
    imageFile,
    imageUploadError,
    selectedItem,
  ]);

  const handleSearch = (query) => {
    const results = items.filter((item) =>
      item.item.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredItems(results);
  };

  const fetchAllCustomizations = async () => {
    try {
      const response = await API.get("/customizations");
      if (response.data.success) {
        setAllCustomizations(response.data.data);
      } else {
        toast({
          title: "Failed to fetch customizations.",
          description: "Unable to load customizations.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching customizations:", error);
      toast({
        title: "Error fetching customizations.",
        description: "Unable to load customizations.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await API.get("/categories");
      if (response.data.success) {
        setCategoryOptions(response.data.data);
        setFilteredCategories(response.data.data);
      } else {
        toast({
          title: "Failed to fetch categories.",
          description: "Unable to load categories.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      toast({
        title: "Error fetching categories.",
        description: "Unable to load categories.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCreate = () => {
    setSelectedItem(null);
    const newItemData = {
      item: "",
      name: "",
      description: "",
      sequence_number: "",
      taxable:true,
      price: "",
      picture: "",
    };
    setItemData(newItemData);
    setInitialItemData(newItemData);
    setImageFile(null);
    setCustomizations([]);
    setInitialCustomizations([]);
    setCategories([]);
    setInitialCategories([]);
    setImageUploadError(false);
    onOpen();
  };

  const handleEdit = async (item) => {
    setSelectedItem(item.id);
    const newItemData = {
      item: item.item,
      name: item.name,
      description: item.description,
      taxable:item.taxable,
      sequence_number: item.sequence_number,
      price: item.price,
      picture: item.picture,
    };
    setItemData(newItemData);
    setInitialItemData(newItemData);
    setImageFile(null);
    setImageUploadError(false);

    try {
      // Fetching already existing customizations for the item selected
      const response = await API.get(`/items/${item.id}/customizations`);
      if (response.data.success) {
        const customizationsData = response.data.data.customizations.map((item) => {
          const customization = item.customization;
        return {
          ...customization,
          sequence_number: item.sequence_number,
        };
      });

      // Sort customizations by sequence_number
      const sortedCustomizations = customizationsData.sort(
        (a, b) => parseInt(a.sequence_number) - parseInt(b.sequence_number)
      );
        setCustomizations(sortedCustomizations);
        setInitialCustomizations(sortedCustomizations);
      } else {
        toast({
          title: "Failed to fetch item customizations.",
          description: "Unable to load customizations.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching item customizations:", error);
      toast({
        title: "Error fetching item customizations.",
        description: "Unable to load customizations.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    try {
      // Fetching already existing categories for the item seleected
      const response = await API.get(`/items/${item.id}/categories`);
      if (response.data.success) {
        const categoriesData = response.data.data.category_names;
        setCategories(categoriesData);
        setInitialCategories(categoriesData);
      } else {
        toast({
          title: "Failed to fetch item categories.",
          description: "Unable to load categories.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching item categories:", error);
      toast({
        title: "Error fetching item categories.",
        description: "Unable to load categories.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    onOpen();
  };

  const handleImageUpload = async (file) => {
    const compressedFile = await getCompressedFile(file)
    const formData = new FormData();
    formData.append("file", compressedFile);
    formData.append("type", "kiwikiosk_items");

    try {
      const response = await API.post("/images/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const fullUrl = response.data.url;
      const relativePath = cleanPicturePath(fullUrl);
      setImageUploadError(false);

      return relativePath;
    } catch (error) {
      console.error("Error uploading image:", error);
      const errorMessage =
        error.response?.data?.message ||
        error.response?.data ||
        "Unable to upload the image.";

      // Check and replace specific error messages
      if (errorMessage.includes("value too long for type")) {
        errorMessage = "The image file name is too long.";
      }
      toast({
        title: "Image Upload Failed.",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setImageUploadError(true);

      return null;
    }
  };

  const handleImageReplace = async (imageKey, file) => {
    const compressedFile = await getCompressedFile(file)
    const formData = new FormData();
    formData.append("file", compressedFile);
    formData.append("type", "kiwikiosk_items");

    try {
      const encodedImageKey = encodeURIComponent(imageKey);
      const response = await API.put(`/images/${encodedImageKey}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const fullUrl = response.data.url;
      const relativePath = cleanPicturePath(fullUrl);
      setImageUploadError(false);
      return relativePath;
    } catch (error) {
      console.error("Error replacing image:", error);

      let errorMessage = "Unable to replace the image.";

      if (error.response) {
        if (error.response.status === 413) {
          errorMessage =
            "The image is too large. Please select an image smaller than 10 MB.";
        } else {
          errorMessage =
            error.response.data?.message || error.response.data || errorMessage;
        }
      }

      toast({
        title: "Image Replace Failed.",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setImageUploadError(true);

      return null;
    }
  };

  // the method to cut and save only relative path for image
  const cleanPicturePath = (path) => {
    const baseURL = "https://nyc3.digitaloceanspaces.com/kiwikiosk/media/";
    if (!path || path.trim() === "") {
      return "default.jpg";
    }
    if (path.startsWith(baseURL)) {
      return path.substring(baseURL.length);
    } else {
      // If the path doesn't contain the baseURL, attempt to remove any protocol and domain
      const urlParts = path.split(/https?:\/\/[^/]+\//);
      return urlParts[urlParts.length - 1];
    }
  };

  const handleSubmit = async () => {
    const isCreating = !selectedItem;

    if (isCreating) {
      const missingFields = [];

      if (!itemData.item || itemData.item.trim() === "") {
        missingFields.push("Item Name");
      }

      if (!itemData.price || isNaN(itemData.price)) {
        missingFields.push("Price");
      }

      if (missingFields.length > 0) {
        const formattedMissingFields = missingFields
          .map((field) => `• ${field}`)
          .join("\n");

        toast({
          title: "Missing Required Fields",
          description: `Please fill out the following required fields:\n${formattedMissingFields}`,
          status: "error",
          duration: 7000,
          isClosable: true,
          whiteSpace: "pre-line",
        });
        return;
      }
    }

    let picturePath = itemData.picture;

    if (imageFile) {
      if (selectedItem) {

        const imageKey = cleanPicturePath(picturePath);
        picturePath = await handleImageReplace(imageKey, imageFile);
      } else {
        picturePath = await handleImageUpload(imageFile);
      }
    } else {
      if (isCreating && (!picturePath || picturePath.trim() === "")) {
        picturePath = "default.jpg";
      } else {
        picturePath = cleanPicturePath(picturePath);
      }
    }

    if (picturePath) {

      const formattedItemData = {
        ...itemData,
        price: parseFloat(itemData.price),
        picture: picturePath,
        sequence_number: itemData.sequence_number,
        customizations: customizations.map((c) => ({
          customization: c.id.toString(),
          sequence_number: c.sequence_number,
        })),
        category_names: categories.map((category) => {
          if (
            typeof category === "object" &&
            category !== null &&
            "id" in category
          ) {
            return category.id.toString();
          }
          return category.toString();
        }), // Include category IDs as strings
      };

      try {
        if (selectedItem) {
          await API.put(`/items/${selectedItem}`, formattedItemData);
          toast({
            title: "Item updated.",
            description: "The item was successfully updated.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else {
          delete formattedItemData.sequence_number;
          formattedItemData.name = formattedItemData.item;
          await API.post("/items", formattedItemData);
          toast({
            title: "Item created.",
            description: "The item was successfully created.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }

        fetchItems();
        onClose();
      } catch (error) {
        console.error("Error saving category:", error);
        let errorMessage =
          error.response?.data?.message ||
          error.response?.data ||
          "Unable to save the item.";

        if (errorMessage.includes("value too long for type")) {
          errorMessage = "The image file name is too long.";
        }
        toast({
          title: "An error occurred.",
          description: errorMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 10 * 1024 * 1024; // 1 MB

    if (file.size > maxSizeInBytes) {
      toast({
        title: "Image Too Large.",
        description: "Please select an image smaller than 10 MB.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setImageFile(file);
    setImageUploadError(false);
  };

  const handleDelete = async () => {
    try {
      await API.delete(`/items/${itemToDelete}`);
      setItems(items.filter((item) => item.id !== itemToDelete));
      setFilteredItems(
        filteredItems.filter((item) => item.id !== itemToDelete)
      );
      onDeleteClose();
      toast({
        title: "Item deleted.",
        description: "The item was successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting item:", error);
      toast({
        title: "An error occurred.",
        description: "Unable to delete the item.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSearchCustomizations = (query) => {
    setQueryCustomizations(query);
    if (query.trim() === "") {
      setFilteredCustomizations([]);
      setShowSuggestionsCustomizations(false);
      return;
    }
    const filtered = allCustomizations.filter((customization) =>
      customization.customization.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCustomizations(filtered);
    setShowSuggestionsCustomizations(true);
  };

  const handleOptionSelectCustomization = (customization) => {
    if (!customizations.some((c) => c.id === customization.id)) {
      const maxSequenceNumber = customizations.reduce((max, c) => {
        const seqNum = parseInt(c.sequence_number);
        return !isNaN(seqNum) && seqNum > max ? seqNum : max;
      }, -1);
      const newCustomization = {
        ...customization,
        sequence_number: (maxSequenceNumber + 1).toString(),
      };
      const updatedCustomizations = [...customizations, newCustomization];

      // Sort customizations by sequence_number
      updatedCustomizations.sort(
        (a, b) => parseInt(a.sequence_number) - parseInt(b.sequence_number)
      );

      setCustomizations(updatedCustomizations);
    }
    setQueryCustomizations("");
    setFilteredCustomizations([]);
    setShowSuggestionsCustomizations(false);
  };

  const handleRemoveCustomization = (id) => {
    const updatedCustomizations = customizations
      .filter((c) => c.id !== id)
      .map((c, index) => ({
        ...c,
        sequence_number: index.toString(),
      }));

    setCustomizations(updatedCustomizations);
  };


  const handleClearSearchCustomizations = () => {
    setQueryCustomizations("");
    setFilteredCustomizations([]);
    setShowSuggestionsCustomizations(false);
  };

  const handleSearchCategories = (value) => {
    setQueryCategories(value);
    if (value) {
      setFilteredCategories(
        categoryOptions.filter((item) =>
          item.category.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setFilteredCategories(categoryOptions);
    }
  };

  const handleOptionSelectCategory = (selectedItem) => {
    if (!categories.some((c) => c.id === selectedItem.id)) {
      const newCategory = {
        ...selectedItem,
        order: categories.length,
      };
      setCategories([...categories, newCategory]);
    }
    setShowSuggestionsCategories(false);
    setQueryCategories("");
  };

  const handleRemoveCategory = (id) => {
    const updatedCategories = categories.filter((c) => c.id !== id);
    setCategories(updatedCategories);
  };

  const handleClearSearchCategories = () => {
    setQueryCategories("");
    setFilteredCategories(categoryOptions);
    setShowSuggestionsCategories(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsBoxRef.current &&
        !suggestionsBoxRef.current.contains(event.target)
      ) {
        setShowSuggestionsCustomizations(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const confirmDelete = (id) => {
    setItemToDelete(id);
    onDeleteOpen();
  };

  const RefreshButton = () => (
    <IconButton
      icon={<IoIosRefresh />}
      size={"sm"}
      onClick={fetchItems}
      isLoading={refreshing}
      loadingText="Refreshing..."
      mx={4}
      px={2}
    />
  );

  if (loading && !refreshing) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Box my={"4"}>
      <Flex
        justify="space-between"
        align="center"
        marginBottom="4"
        direction={{ base: "column", md: "row" }}
      >
        <Box>
          <Heading size={"xl"} letterSpacing={-2}>
            Items
          </Heading>
          <Text fontSize={"sm"}>
            List of all available items, you can add new ones, edit and/or
            delete older ones.
          </Text>
        </Box>
        <Flex align="center" justify={"flex-start"}>
          <Text fontSize="sm" color="gray.500">
            Last updated: {lastUpdated || "Never"}
          </Text>
          <RefreshButton />
          {role !== "staff" && (
            <>
              <Button
                display={{ base: "none", md: "flex" }}
                leftIcon={<AddIcon />}
                bg="black"
                color={"white"}
                _hover={{
                  color: "black",
                  bg: "white",
                  borderWidth: "1px",
                  borderColor: "gray.300",
                }}
                variant="outline"
                onClick={handleCreate}
              >
                Create Item
              </Button>
              <IconButton
                display={{ base: "flex", md: "none" }}
                icon={<AddIcon />}
                size={"sm"}
                aria-label="Create Item"
                bg="black"
                color={"white"}
                _hover={{
                  color: "black",
                  bg: "white",
                  borderWidth: "1px",
                  borderColor: "gray.300",
                }}
                onClick={handleCreate}
                ml={3}
              />
            </>
          )}
        </Flex>
      </Flex>

      <SearchBar
        items={items.map((item) => item.item)}
        onSearch={handleSearch}
        placeholder="Search items..."
        variant="filled"
        borderRadius="full"
        width="100%"
      />
      <Box p={4} bg={bgColor} borderRadius="md" overflowX="auto">
        <Table variant="simple" borderRadius="md" mt="4" minWidth="600px">
          <Thead>
            <Tr>
              <Th fontSize={{ base: "xs", md: "sm" }}>Item</Th>
              <Th fontSize={{ base: "xs", md: "sm" }}>Description</Th>
              <Th fontSize={{ base: "xs", md: "sm" }}>Price</Th>
              <Th fontSize={{ base: "xs", md: "sm" }}>Image</Th>
              <Th textAlign={"center"}>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {refreshing ? (
              <Tr>
                <Td colSpan="5">
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    height="150px"
                  >
                    <Spinner size="lg" />
                  </Flex>
                </Td>
              </Tr>
            ) : (
              filteredItems.map((item) => (
                <Tr key={item.id}>
                  <Td fontSize={{ base: "xs", md: "sm" }}>{item.item}</Td>
                  <Td fontSize={{ base: "xs", md: "sm" }}>
                    {item.description || "No description available"}
                  </Td>
                  <Td
                    fontSize={{ base: "xs", md: "sm" }}
                    textAlign={{ base: "left", md: "right" }}
                  >
                    {item.price ? `$${item.price.toFixed(2)}` : "$0.00"}
                  </Td>
                  <Td>
                    {item.picture && (
                      <Image
                        src={item.picture}
                        alt={item.item}
                        boxSize="50px"
                        objectFit="cover"
                        borderRadius="md"
                        fallbackSrc="https://via.placeholder.com/50"
                      />
                    )}
                  </Td>
                  <Td>
                    {role !== "staff" && (
                      <Flex justifyContent={"center"}>
                        <IconButton
                          icon={<EditIcon />}
                          colorScheme="blue"
                          variant="ghost"
                          aria-label="Edit Item"
                          onClick={() => handleEdit(item)}
                        />
                        <IconButton
                          icon={<DeleteIcon />}
                          colorScheme="red"
                          variant="ghost"
                          aria-label="Delete Item"
                          onClick={(e) => {
                            e.stopPropagation();
                            confirmDelete(item.id);
                          }}
                        />
                      </Flex>
                    )}
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={{ base: "full", md: "lg", lg: "4xl" }}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedItem ? "Update Item" : "Create Item"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="item" mb="4">
              <FormLabel>Item Name*</FormLabel>
              <Input
                value={itemData.item}
                onChange={(e) =>
                  setItemData({ ...itemData, item: e.target.value })
                }
                variant={"filled"}
                borderRadius={"full"}
              />
            </FormControl>

            <FormControl id="description" mb="4">
              <FormLabel>Description</FormLabel>
              <Input
                value={itemData.description}
                onChange={(e) =>
                  setItemData({ ...itemData, description: e.target.value })
                }
                variant={"filled"}
                borderRadius={"full"}
              />
            </FormControl>
            <FormControl id="price" mb="4">
              <FormLabel>Price</FormLabel>
              <Input
                type="number"
                value={itemData.price}
                onChange={(e) =>
                  setItemData({ ...itemData, price: e.target.value })
                }
                variant={"filled"}
                borderRadius={"full"}
              />
            </FormControl>

            <FormControl id="picture" mb="4">
              <FormLabel>Photo</FormLabel>
              <Box position="relative">
                <Image
                  src={
                    imageFile
                      ? URL.createObjectURL(imageFile)
                      : itemData.picture
                      ? getMediaURL(itemData.picture)
                      : "https://via.placeholder.com/320x240"
                  }
                  alt="Item Image"
                  width="100%"
                  height="300"
                  objectFit="cover"
                  borderRadius="md"
                  fallbackSrc="https://via.placeholder.com/320x240"
                />
                <Button
                  onClick={() => imageInputRef.current.click()}
                  position="absolute"
                  leftIcon={selectedItem ? <GrUpdate /> : <AddIcon />}
                  bg="black"
                  color={"white"}
                  _hover={{
                    color: "black",
                    bg: "white",
                    borderWidth: "1px",
                    borderColor: "gray.300",
                  }}
                  variant="outline"
                  top="2"
                  right="2"
                  size={"sm"}
                  borderRadius={"full"}
                >
                  {selectedItem ? "Change image" : "Add image"}
                </Button>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  ref={imageInputRef}
                  style={{ display: "none" }}
                />
              </Box>
              <Text fontSize="sm" color="gray.500" mt={2}>
                File requirement: JPG, PNG, GIF, or WEBP up to 10 MB. Minimum
                pixels required: 320 for width and height.
              </Text>
            </FormControl>
            <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="autoRenewSubscription" mb="0">
                   Taxable
                  </FormLabel>
                  <Switch
                    id="taxable"
                    name="taxable"
                    isChecked={itemData.taxable}
                    onChange={(e)=>setItemData({ ...itemData, taxable: e.target.checked })}
                  />
            </FormControl>

            <Accordion allowToggle defaultIndex={[0]}>
              <AccordionItem my={4}>
                <AccordionButton py={4}>
                  <Box width={"100%"}>
                    <Heading fontSize={"xl"} flex="1" textAlign="left">
                      Customise item
                    </Heading>
                    <Text textAlign="left">
                      Modifier groups allow customers to use toppings, sides and
                      more to customise items
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Box>
                    <FormControl mb="4">
                      <Heading fontSize={"md"} mb={2}>
                        Customizations
                      </Heading>
                      <Box position="relative" ref={suggestionsBoxRef}>
                        <InputGroup>
                          <Input
                            placeholder="Search customizations..."
                            value={queryCustomizations}
                            onChange={(e) =>
                              handleSearchCustomizations(e.target.value)
                            }
                            onFocus={() =>
                              setShowSuggestionsCustomizations(true)
                            }
                            variant="filled"
                            borderRadius="full"
                            mb={4}
                          />
                          {queryCustomizations && (
                            <InputRightAddon>
                              <CloseButton
                                onClick={handleClearSearchCustomizations}
                              />
                            </InputRightAddon>
                          )}
                        </InputGroup>

                        {showSuggestionsCustomizations &&
                          filteredCustomizations.length > 0 && (
                            <Box
                              position="absolute"
                              bg={bgColor}
                              border="1px solid"
                              borderColor={borderColor}
                              borderRadius="md"
                              mt={2}
                              width="100%"
                              zIndex="1"
                              maxHeight="150px"
                              overflowY="auto"
                            >
                              <List spacing={1}>
                                {filteredCustomizations.map((item) => (
                                  <ListItem
                                    key={item.id}
                                    p={2}
                                    cursor="pointer"
                                    _hover={{
                                      bg: hoverBg,
                                    }}
                                    onClick={() =>
                                      handleOptionSelectCustomization(item)
                                    }
                                  >
                                    <Flex
                                      justify="space-between"
                                      align="center"
                                    >
                                      <Text>{item.customization}</Text>
                                      {customizations.some(
                                        (c) => c.id === item.id
                                      ) && <CheckIcon color="green.500" />}
                                    </Flex>
                                  </ListItem>
                                ))}
                              </List>
                            </Box>
                          )}
                      </Box>
                    </FormControl>

                    <Box
                      p={4}
                      borderWidth={"1px"}
                      borderColor={"#d1d1d1"}
                      borderRadius={"lg"}
                    >
                      <Text>Selected Modifier Groups:</Text>
                      {customizations.length === 0 ? (
                        <Text color="gray.500">
                          No modifier groups selected.
                        </Text>
                      ) : (
                        <Wrap spacing={2} mt={2}>
                          {customizations
                            .sort(
                              (a, b) =>
                                parseInt(a.sequence_number) -
                                parseInt(b.sequence_number)
                            )
                            .map((customization) => (
                              <WrapItem key={customization.id}>
                                <Badge
                                  display="flex"
                                  alignItems="center"
                                  p={2}
                                  borderRadius="full"
                                  variant="solid"
                                  colorScheme="teal"
                                >
                                  {customization.customization || "unnamed customization"}
                                  <IconButton
                                    icon={<CloseIcon />}
                                    size="xs"
                                    ml={2}
                                    onClick={() =>
                                      handleRemoveCustomization(
                                        customization.id
                                      )
                                    }
                                    variant="unstyled"
                                    aria-label="Remove Customization"
                                    _hover={{ color: "red.500" }}
                                  />
                                </Badge>
                              </WrapItem>
                            ))}
                        </Wrap>
                      )}
                    </Box>

                    <FormControl mb="4">
                      <Heading fontSize={"md"} mb={2}>
                        Categories*
                      </Heading>
                      <Box position="relative" ref={categoriesBoxRef}>
                        <InputGroup>
                          <Input
                            placeholder="Search categories..."
                            value={queryCategories}
                            onChange={(e) =>
                              handleSearchCategories(e.target.value)
                            }
                            onFocus={() => setShowSuggestionsCategories(true)}
                            variant="filled"
                            borderRadius="full"
                            mb={4}
                          />
                          {queryCategories && (
                            <InputRightAddon>
                              <CloseButton
                                onClick={handleClearSearchCategories}
                              />
                            </InputRightAddon>
                          )}
                        </InputGroup>

                        {showSuggestionsCategories &&
                          filteredCategories.length > 0 && (
                            <Box
                              position="absolute"
                              bg={bgColor}
                              border="1px solid"
                              borderColor={borderColor}
                              borderRadius="md"
                              mt={2}
                              width="100%"
                              zIndex="1"
                              maxHeight="150px"
                              overflowY="auto"
                            >
                              <List spacing={1}>
                                {filteredCategories.map((category) => (
                                  <ListItem
                                    key={category.id}
                                    p={2}
                                    cursor="pointer"
                                    _hover={{
                                      hoverBg,
                                    }}
                                    onClick={() =>
                                      handleOptionSelectCategory(category)
                                    }
                                  >
                                    <Flex
                                      justify="space-between"
                                      align="center"
                                    >
                                      <Text>{category.category}</Text>
                                      {categories.some(
                                        (c) => c.id === category.id
                                      ) && <CheckIcon color="green.500" />}
                                    </Flex>
                                  </ListItem>
                                ))}
                              </List>
                            </Box>
                          )}
                      </Box>
                    </FormControl>

                    <Box
                      p={4}
                      borderWidth={"1px"}
                      borderColor={"#d1d1d1"}
                      borderRadius={"lg"}
                      mb={4}
                    >
                      <Text>Selected Categories:</Text>
                      {categories.length === 0 ? (
                        <Text color="gray.500">No categories selected.</Text>
                      ) : (
                        <Wrap spacing={2} mt={2}>
                          {categories.map((category) => (
                            <WrapItem key={category.id}>
                              <Badge
                                display="flex"
                                alignItems="center"
                                p={2}
                                borderRadius="full"
                                variant="solid"
                                colorScheme="blue"
                              >
                                {category.category}
                                <IconButton
                                  icon={<CloseIcon />}
                                  size="xs"
                                  ml={2}
                                  onClick={() =>
                                    handleRemoveCategory(category.id)
                                  }
                                  variant="unstyled"
                                  aria-label="Remove Category"
                                  _hover={{ color: "red.500" }}
                                />
                              </Badge>
                            </WrapItem>
                          ))}
                        </Wrap>
                      )}
                    </Box>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </ModalBody>
          <ModalFooter>
            <Button
              isDisabled={!isChanged}
              bg="black"
              color={"white"}
              _hover={{
                color: "black",
                bg: "white",
                borderWidth: "1px",
                borderColor: "gray.300",
              }}
              variant="outline"
              mr={3}
              onClick={handleSubmit}
            >
              {selectedItem ? "Update Item" : "Create Item"}
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this item?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDelete}>
              Delete
            </Button>
            <Button variant="ghost" onClick={onDeleteClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default Items;
