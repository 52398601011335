import React, { useRef, useState } from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import API from "../../context/API";

export const DeviceTypeBox = ({ initialDeviceType, onSave, onCancel, fetchDeviceTypes }) => {
    const [deviceType, setDeviceType] = useState({
        name: "",
        ...initialDeviceType,
    });

    const [errors, setErrors] = useState({});
    const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();
    const toast = useToast();

    const isEditMode = !!initialDeviceType;

    const validate = () => {
        const newErrors = {};
        if (!deviceType.name) newErrors.name = "Name is required";
        return newErrors;
    };

    const handleSaveClick = async () => {
        const newErrors = validate();
        if (Object.keys(newErrors).length === 0) {
            try {
                if (isEditMode) {
                    const updatedDeviceTypeResponse = await API.put(`device-types/${deviceType.id}`, deviceType, {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: false,
                    });
                    if (updatedDeviceTypeResponse.data.status === 200) {
                        toast({
                            title: "Success.",
                            description: "Device type updated successfully.",
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                        });
                    } else {
                        toast({
                            title: "Error.",
                            description: updatedDeviceTypeResponse.data.message,
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                        });
                    }
                } else {
                    const newDeviceTypeResponse = await API.post("device-types", deviceType, {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: false,
                    });
                    if (newDeviceTypeResponse.data.status === 200) {
                        toast({
                            title: "Success.",
                            description: "Device type added successfully.",
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                        });
                    } else {
                        toast({
                            title: "Error.",
                            description: newDeviceTypeResponse.data.message,
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                        });
                    }
                }
                fetchDeviceTypes();
                onSave();
            } catch (error) {
                toast({
                    title: "Error.",
                    description: error.response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } else {
            setErrors(newErrors);
        }
    };

    const handleCancelClick = () => {
        if (isUnsavedChanges) {
            onOpen();
        } else {
            onCancel();
        }
    };

    const confirmCancel = () => {
        setIsUnsavedChanges(false);
        onCancel();
        onClose();
    };

    const handleChange = (field, value) => {
        setDeviceType((prevDeviceType) => ({ ...prevDeviceType, [field]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
        setIsUnsavedChanges(true);
    };

    return (
        <>
            <VStack spacing={4} align="stretch">
                <Flex justify="space-between">
                    <Button leftIcon={<ArrowBackIcon />} variant="outline" onClick={handleCancelClick}>
                        Back
                    </Button>
                    <Flex>
                        <Button colorScheme="red" variant="outline" mr={3} onClick={handleCancelClick}>
                            Cancel
                        </Button>
                        <Button
                            bg="black"
                            color={"white"}
                            _hover={{
                                color: "black",
                                bg: "white",
                                borderWidth: "1px",
                                borderColor: "gray.300",
                            }}
                            variant="outline"
                            onClick={handleSaveClick}
                        >
                            Save
                        </Button>
                    </Flex>
                </Flex>
                <FormControl id="device-type-name" isInvalid={errors.name}>
                    <FormLabel>Name</FormLabel>
                    <Input value={deviceType.name} variant={"filled"} onChange={(e) => handleChange("name", e.target.value)} />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
            </VStack>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Unsaved Changes
                        </AlertDialogHeader>
                        <AlertDialogBody>You have unsaved changes. Are you sure you want to cancel?</AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                No
                            </Button>
                            <Button colorScheme="red" onClick={confirmCancel} ml={3}>
                                Yes
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};
