import { mode } from "@chakra-ui/theme-tools";

export const tableStyles = {
  baseStyle: (props) => ({
    th: {
      borderColor: mode("gray.300", "gray.600")(props), // Light gray for light mode, darker gray for dark mode
    },
    td: {
      borderColor: mode("gray.300", "gray.600")(props), // Light gray for light mode, darker gray for dark mode
    },
    tbody: {
      tr: {
        borderBottomColor: mode("gray.300", "gray.600")(props), // Light gray for light mode, darker gray for dark mode
      },
    },
  }),
};
