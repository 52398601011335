import React from 'react';
import AdminAnalytics from './adminAnalytics';
import MerchantAnalytics from './merchantAnalytics';
import ManagerAnalytics from './managerAnalytics';
import StaffAnalytics from './staffAnalytics';

const Analytics = ({ role }) => {
  return (
    <div>
      {role === 'admin' && <AdminAnalytics />}
      {role === 'merchant' && <MerchantAnalytics role={role} />}
      {role === 'manager' && <ManagerAnalytics />}
      {role === 'staff' && <StaffAnalytics />}
    </div>
  );
};

export default Analytics;
