import React, { useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Grid,
  GridItem,
  useColorModeValue,
  Collapse,
} from "@chakra-ui/react";

function ManagerReports() {
  const [selectedItem, setSelectedItem] = useState(null);

  const hoverBg = useColorModeValue('gray.100', 'gray.700');
  const selectedBg = useColorModeValue('gray.200', 'gray.600');

  const handleClick = (item) => {
    setSelectedItem(item === selectedItem ? null : item);
  };

  const renderStaffManagement = () => (
    <Collapse in={selectedItem === 'staffManagement'} animateOpacity>
      <Box mt="4" p="4" borderWidth="1px" borderRadius="md" boxShadow="sm">
        <Heading size="md">Staff Management Details</Heading>
        <Text marginBottom="4">Detailed report on staff management...</Text>
      </Box>
    </Collapse>
  );

  const renderDailyOperations = () => (
    <Collapse in={selectedItem === 'dailyOperations'} animateOpacity>
      <Box mt="4" p="4" borderWidth="1px" borderRadius="md" boxShadow="sm">
        <Heading size="md">Daily Operations Details</Heading>
        <Text marginBottom="4">Detailed report on daily operations...</Text>
      </Box>
    </Collapse>
  );

  const renderCustomerFeedback = () => (
    <Collapse in={selectedItem === 'customerFeedback'} animateOpacity>
      <Box mt="4" p="4" borderWidth="1px" borderRadius="md" boxShadow="sm">
        <Heading size="md">Customer Feedback Details</Heading>
        <Text marginBottom="4">Detailed report on customer feedback...</Text>
      </Box>
    </Collapse>
  );

  return (
    <Flex direction="column">
      <Box as="main" flex="1" padding="4">
        <Heading size="lg" marginBottom="4">Reports</Heading>

        <Grid templateColumns="repeat(2, 1fr)" gap="4" marginBottom="4">
          <GridItem
            padding="4"
            borderRadius="md"
            boxShadow="sm"
            cursor="pointer"
            onClick={() => handleClick('staffManagement')}
            _hover={{ backgroundColor: hoverBg, transition: 'background-color 0.3s ease' }}
            backgroundColor={selectedItem === 'staffManagement' ? selectedBg : 'transparent'}
            transition="background-color 0.3s ease"
          >
            <Text fontSize="xl" fontWeight="bold">Staff Management</Text>
            <Text fontSize="2xl">Detailed overview</Text>
          </GridItem>
          <GridItem
            padding="4"
            borderRadius="md"
            boxShadow="sm"
            cursor="pointer"
            onClick={() => handleClick('dailyOperations')}
            _hover={{ backgroundColor: hoverBg, transition: 'background-color 0.3s ease' }}
            backgroundColor={selectedItem === 'dailyOperations' ? selectedBg : 'transparent'}
            transition="background-color 0.3s ease"
          >
            <Text fontSize="xl" fontWeight="bold">Daily Operations</Text>
            <Text fontSize="2xl">Detailed overview</Text>
          </GridItem>
          <GridItem
            padding="4"
            borderRadius="md"
            boxShadow="sm"
            cursor="pointer"
            onClick={() => handleClick('customerFeedback')}
            _hover={{ backgroundColor: hoverBg, transition: 'background-color 0.3s ease' }}
            backgroundColor={selectedItem === 'customerFeedback' ? selectedBg : 'transparent'}
            transition="background-color 0.3s ease"
          >
            <Text fontSize="xl" fontWeight="bold">Customer Feedback</Text>
            <Text fontSize="2xl">Detailed overview</Text>
          </GridItem>
        </Grid>

        {renderStaffManagement()}
        {renderDailyOperations()}
        {renderCustomerFeedback()}
      </Box>
    </Flex>
  );
}

export default ManagerReports;
