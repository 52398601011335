// chartConfig.js
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    BarElement, // Import BarElement
    ArcElement,
    Tooltip,
    Legend,
  } from 'chart.js';
  
  // Register the necessary components
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement, 
    Title,
    ArcElement,
    Tooltip,
    Legend
  );
  