import React, {createContext, useContext, useEffect, useReducer} from "react";
import API from "./API";

const ApiContext = createContext({
  state: {
    items: [],
    customizations: [],
    orders: [],
    categories: [],
    menus: [],
  },
  refreshItems: () => Promise.resolve(),
  editItem: () => {},
  editCategory: () => {},
});

const apiReducer = (state, action) => {
  switch (action.type) {
    case "SET_ITEMS":
      return { ...state, items: action.payload };
    case "EDIT_ITEM":
      const updatedItems = state.items.map((item) =>
        item.id === action.payload.id
          ? { ...item, ...action.payload.updates }
          : item
      );
      return { ...state, items: updatedItems };
    case "SET_CUSTOMIZATIONS":
      return { ...state, customizations: action.payload };
    case "SET_MENUS":
      return { ...state, menus: action.payload };
    case "SET_ORDERS":
      return { ...state, orders: action.payload };
    case "SET_CATEGORIES":
      return { ...state, categories: action.payload };
    case "EDIT_CATEGORY":
      const updatedCategories = state.categories.map((category) =>
        category.id === action.payload.id
          ? { ...category, ...action.payload.updates }
          : category
      );
      return { ...state, categories: updatedCategories };
    default:
      return state;
  }
};

export const ApiProvider = ({ children }) => {
  const initialState = {
    items: [],
    customizations: [],
    orders: [],
    categories: [],
    menus: [],
  };

  const [state, dispatch] = useReducer(apiReducer, initialState);

  useEffect(() => {
    // handleFetchItems();
    // handleFetchCustomizations();
    // handleFetchOrders();
    // handleFetchCategories();
    // handleFetchMenus();
  }, []);

  const handleFetchItems = async () => {
    try {
      const res = await API.get("/items/");
      dispatch({ type: "SET_ITEMS", payload: res.data });
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const handleFetchCustomizations = async () => {
    try {
      const res = await API.get("/customizations/");
      dispatch({ type: "SET_CUSTOMIZATIONS", payload: res.data });
    } catch (error) {
      console.error("Error fetching customizations:", error);
    }
  };

  const handleFetchCategories = async () => {
    try {
      const res = await API.get("/categories/");
      dispatch({ type: "SET_CATEGORIES", payload: res.data });
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleFetchMenus = async () => {
    try {
      const res = await API.get("/menus/");
      dispatch({ type: "SET_MENUS", payload: res.data });
    } catch (error) {
      console.error("Error fetching menu:", error);
    }
  };

  const handleFetchOrders = async () => {
    try {
      const res = await API.get("/orders/");
      dispatch({ type: "SET_ORDERS", payload: res.data });
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const editItem = (id, updates) => {
    console.log(updates);
    dispatch({ type: "EDIT_ITEM", payload: { id, updates } });
  };

  const editCustomization = (id, updates) => {
    dispatch({ type: "EDIT_CUSTOMIZATION", payload: { id, updates } });
  };

  const editCategory = (id, updates) => {
    console.log(updates);
    dispatch({ type: "EDIT_CATEGORY", payload: { id, updates } });
  };

  return (
    <ApiContext.Provider
      value={{
        state,
        editItem,
        editCustomization,
        editCategory,
        handleFetchCategories,
        handleFetchItems,
        handleFetchCustomizations,
        handleFetchMenus,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useApiContext = () => useContext(ApiContext);
