import React, { useEffect, useState, useCallback, memo } from "react";
import {
  Box,
  Heading,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Tooltip,
  Select,
  Flex,
} from "@chakra-ui/react";
import API from "../../context/API";
import DateRangePicker from "../Analytics/DateRangePicker";
import moment from "moment";
import Pagination from "../Common/Pagination/Pagination";

function ActionLogs() {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const [devices, setDevices] = useState([]);

  const [merchants, setMerchants] = useState([]);

  const { colorMode } = useColorMode();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: moment().subtract(30, "days"),
    endDate: moment(),
  });

  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [selectedMerchantId, setSelectedMerchantId] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedLog, setSelectedLog] = useState(null);

  const user = localStorage.getItem("user");

  let parsedUser;
  if (user) {
    try {
      parsedUser = JSON.parse(user);
    } catch (e) {
      console.error("Failed to parse user from localStorage", e);
      parsedUser = null;
    }
  } else {
    parsedUser = null;
  }

  useEffect(() => {
    fetchLogs();
  }, [selectedDateRange, currentPage, limit, selectedDeviceId, selectedMerchantId]);

  const fetchLogs = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("access_token");

      const params = {
        page: currentPage,
        limit: limit,
      };

      // Include merchantId if a merchant is selected (Admin Only)
      if (parsedUser?.role === "admin" && selectedMerchantId) {
        params.merchantId = selectedMerchantId;
      }

      // Include deviceId if a device is selected
      if (selectedDeviceId) {
        params.deviceId = selectedDeviceId;
      }

      // Include date range if set
      if (selectedDateRange.startDate && selectedDateRange.endDate) {
        params.startDate = selectedDateRange.startDate.format("YYYY-MM-DD");
        params.endDate = selectedDateRange.endDate.format("YYYY-MM-DD");
      }

      const response = await API.get(`/merchant/logs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
      });

      if (response.data.success) {
        const fetchedLogs = response.data.data.data || [];
        const meta = response.data.data.meta || {};

        setLogs(fetchedLogs);
        setTotalPages(meta.totalPages || 1);
      } else {
        setLogs([]);
        setTotalPages(1);
      }
    } catch (error) {
      console.error("Error fetching logs:", error);
      setLogs([]);
      setTotalPages(1);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMerchantDevices();
  }, [selectedMerchantId]);

  const fetchMerchantDevices = async () => {
    try {
      const token = localStorage.getItem("access_token");

      const params = {};
      // If a merchant is selected, fetch devices for that merchant
      if (parsedUser?.role === "admin" && selectedMerchantId) {
        params.merchantId = selectedMerchantId;
      }

      const response = await API.get(`/merchant/devices`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
      });

      if (response.data.success) {
        const fetchedDevices = response.data.data || [];
        console.log("devices ", fetchedDevices);
        setDevices(fetchedDevices);
      } else {
        setDevices([]);
      }
    } catch (error) {
      console.error("Error fetching devices:", error);
      setDevices([]); 
    }
  };

  // Fetch merchants if user is admin
  useEffect(() => {
    if (parsedUser?.role === "admin") {
      fetchMerchants();
    }
  }, []);

  // Fetch merchants from API (Admin Only)
  const fetchMerchants = async () => {
    try {
      const token = localStorage.getItem("access_token");

      const response = await API.get(`/merchant`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        const fetchedMerchants = response.data.data || [];
        console.log("merchants ", fetchedMerchants);
        setMerchants(fetchedMerchants);
      } else {
        setMerchants([]);
      }
    } catch (error) {
      console.error("Error fetching merchants:", error);
      setMerchants([]);
    }
  };

  const handleLogClick = useCallback(
    (log) => {
      setSelectedLog(log);
      onOpen();
    },
    [onOpen]
  );

  const handleDeviceChange = (e) => {
    setSelectedDeviceId(e.target.value);
    setCurrentPage(1); 
  };

  // Handler for merchant selection (Admin Only)
  const handleMerchantChange = (e) => {
    setSelectedMerchantId(e.target.value);
    setSelectedDeviceId(""); 
    setCurrentPage(1);
  };

  // Handler for date range change
  const handleDateRangeChange = (date) => {
    setSelectedDateRange(date);
    setCurrentPage(1);
  };

  return (
    <Box padding="4" width="100%">
      <Heading size="xl" letterSpacing="-2" marginBottom="4">
        Logs
      </Heading>

      <Flex
        direction={{ base: "column", md: "row" }}
        mb="4"
        align={{ base: "stretch", md: "center" }}
        gap="4"
      >
        <DateRangePicker
          onDateRangeChange={handleDateRangeChange}
          initialRange={selectedDateRange}
        />

        {parsedUser?.role === "admin" && (
          <Box>
            {merchants.length > 0 ? (
              <Select
                placeholder="All Merchants"
                value={selectedMerchantId}
                onChange={handleMerchantChange}
                maxWidth="300px"
                color="gray.800"
                fontWeight="medium"
                backgroundColor={"white"}
              >
                {merchants.map((merchant) => (
                  <option key={merchant.id} value={merchant.id}>
                    {merchant.name}
                  </option>
                ))}
              </Select>
            ) : (
              <Text color="gray.500">No merchants available.</Text>
            )}
          </Box>
        )}

        <Box>
          {devices.length > 0 ? (
            <Select
              placeholder="All Devices"
              value={selectedDeviceId}
              onChange={handleDeviceChange}
              maxWidth="300px"
              color="gray.800"
              fontWeight="medium"
              backgroundColor={"white"}
            >
              {devices.map((device) => (
                <option key={device.id} value={device.id}>
                  {device.name}
                </option>
              ))}
            </Select>
          ) : (
            <Text color="gray.500">No devices available.</Text>
          )}
        </Box>
      </Flex>

      {loading ? (
        <Box textAlign="center" mt="4">
          <Spinner size="xl" />
        </Box>
      ) : logs.length === 0 ? (
        <Text mt="4">No logs found.</Text>
      ) : (
        <>
          <LogsTable
            logs={logs}
            colorMode={colorMode}
            onLogClick={handleLogClick}
            isAdmin={parsedUser?.role === "admin"}
          />

          <Pagination
            currentPage={currentPage}
            itemsPerPage={limit}
            totalPages={totalPages}
            onItemsPerPageChange={(lim) => {
              setLimit(lim);
              setCurrentPage(1);
            }}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </>
      )}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        isCentered
        motionPreset="none"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Log Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedLog && (
              <Box>
                <Text fontWeight="bold">Log ID:</Text>
                <Text mb="2">{selectedLog.id}</Text>

                <Text fontWeight="bold">Device Name:</Text>
                <Text mb="2">{selectedLog.logDeviceName}</Text>

                {parsedUser?.role === "admin" && (
                  <>
                    <Text fontWeight="bold">Merchant:</Text>
                    <Text mb="2">{selectedLog.merchant || "N/A"}</Text>
                  </>
                )}

                <Text fontWeight="bold">Log:</Text>
                <Text mb="2" whiteSpace="pre-wrap">
                  {selectedLog.log}
                </Text>

                <Text fontWeight="bold">Time:</Text>
                <Text>
                  {moment(selectedLog.timestamp).format("YYYY-MM-DD HH:mm:ss")}
                </Text>
              </Box>
            )}
          </ModalBody>

          <Box textAlign="right" p="4">
            <Button onClick={onClose} colorScheme="blue">
              Close
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
}

const LogsTable = memo(
  ({ logs, showStatus = false, colorMode, onLogClick, isAdmin }) => {
    return (
      <Box overflowX="auto" borderRadius="md" mt="4" width="100%">
        <Table variant="simple" width="100%">
          <Thead>
            <Tr>
              <Th>Log ID</Th>
              <Th>Device Name</Th>
              {isAdmin && <Th>Merchant</Th>}
              <Th>Log</Th>
              <Th>Time</Th>
            </Tr>
          </Thead>
          <Tbody>
            {logs.map((log) => (
              <Tr key={log.id}>
                <Td>{log.id}</Td>
                <Td>{log.logDeviceName}</Td>
                {isAdmin && (
                  <Td>{log.merchant || "N/A"}</Td>
                )}
                <Td maxWidth="300px">
                  <Tooltip label={log.log} aria-label="Full log details">
                    <Text
                      isTruncated
                      cursor="pointer"
                      onClick={() => onLogClick(log)}
                      color="blue.500"
                      _hover={{ textDecoration: "underline" }}
                    >
                      {log.log}
                    </Text>
                  </Tooltip>
                </Td>
                <Td>
                  {moment(log.timestamp).format("YYYY-MM-DD HH:mm:ss")}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    );
  }
);

export default ActionLogs;
