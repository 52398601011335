import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Grid,
  GridItem,
  useColorModeValue,
  Collapse,
} from "@chakra-ui/react";

function ManagerAnalytics() {
  const [showDetails, setShowDetails] = useState({
    staffManagement: false,
    dailyOperations: false,
    customerFeedback: false,
  });

  const hoverBg = useColorModeValue('gray.200', 'gray.700');
  const selectedBg = useColorModeValue('gray.200', 'gray.600');

  const toggleDetails = (section) => {
    setShowDetails((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <Flex direction="column">
      <Box as="main" flex="1" padding="4">
        <Heading size="lg" marginBottom="4">Analytics</Heading>

        <Grid templateColumns="repeat(2, 1fr)" gap="4" marginBottom="4">
          <GridItem
            padding="4"
            borderRadius="md"
            boxShadow="md"
            cursor="pointer"
            _hover={{
              backgroundColor: hoverBg,
              transition: "background-color 0.3s ease",
            }}
            backgroundColor={showDetails.staffManagement ? selectedBg : "transparent"}
            transition="background-color 0.3s ease"
            onClick={() => toggleDetails('staffManagement')}
          >
            <Text fontSize="xl" fontWeight="bold">Staff Management</Text>
            <Text fontSize="2xl">Detailed overview</Text>
          </GridItem>
          <GridItem
            padding="4"
            borderRadius="md"
            boxShadow="md"
            cursor="pointer"
            _hover={{
              backgroundColor: hoverBg,
              transition: "background-color 0.3s ease",
            }}
            backgroundColor={showDetails.dailyOperations ? selectedBg : "transparent"}
            transition="background-color 0.3s ease"
            onClick={() => toggleDetails('dailyOperations')}
          >
            <Text fontSize="xl" fontWeight="bold">Daily Operations</Text>
            <Text fontSize="2xl">Detailed overview</Text>
          </GridItem>
          <GridItem
            padding="4"
            borderRadius="md"
            boxShadow="md"
            cursor="pointer"
            _hover={{
              backgroundColor: hoverBg,
              transition: "background-color 0.3s ease",
            }}
            backgroundColor={showDetails.customerFeedback ? selectedBg : "transparent"}
            transition="background-color 0.3s ease"
            onClick={() => toggleDetails('customerFeedback')}
          >
            <Text fontSize="xl" fontWeight="bold">Customer Feedback</Text>
            <Text fontSize="2xl">Detailed overview</Text>
          </GridItem>
        </Grid>

        <Collapse in={showDetails.staffManagement} animateOpacity>
          <Box mt="4" p="4" borderWidth="1px" borderRadius="md" boxShadow="sm">
            <Heading size="md">Staff Management Details</Heading>
            <Text marginBottom="4">Detailed report on staff management...</Text>
          </Box>
        </Collapse>
        <Collapse in={showDetails.dailyOperations} animateOpacity>
          <Box mt="4" p="4" borderWidth="1px" borderRadius="md" boxShadow="sm">
            <Heading size="md">Daily Operations Details</Heading>
            <Text marginBottom="4">Detailed report on daily operations...</Text>
          </Box>
        </Collapse>
        <Collapse in={showDetails.customerFeedback} animateOpacity>
          <Box mt="4" p="4" borderWidth="1px" borderRadius="md" boxShadow="sm">
            <Heading size="md">Customer Feedback Details</Heading>
            <Text marginBottom="4">Detailed report on customer feedback...</Text>
          </Box>
        </Collapse>
      </Box>
    </Flex>
  );
}

export default ManagerAnalytics;
