// ItemSales.js
import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Text,
  Icon,
  Flex,
  Spinner,
  VStack,
  HStack,
  useColorModeValue,
  Heading,
  IconButton,
  Image,
  useDisclosure,
  Collapse,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import {
  FiArrowLeft,
  FiShoppingBag,
  FiDollarSign,
  FiTrendingUp,
} from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import BarLineChart from "./BarLineChart";
import ChartSelection from "./ChartSelection";
import DateSelector from "./DateSelector";
import DateRangePicker from "./DateRangePicker";
import moment from "moment";
import API, { getMediaURL } from "../../context/API";
import KeyMetricsSelector from "./KeyMetricSelector";

// Reusable InfoTile Component for Item Sales
const InfoTile = ({ title, subTitle, value, icon }) => {
  const { isOpen, onToggle } = useDisclosure();

  // Define color constants
  const tileBg = useColorModeValue("gray.100", "gray.700");
  const tileHoverBg = useColorModeValue("gray.200", "gray.600");
  const textColor = useColorModeValue("gray.800", "white");
  const subTextColor = useColorModeValue("gray.600", "gray.500");

  return (
    <Box
      bg={tileBg}
      borderRadius="md"
      p={6}
      cursor="pointer"
      _hover={{ bg: tileHoverBg }}
      transition="background 0.3s"
      onClick={onToggle}
      h="100%" // Make tile occupy full height
      w="100%"
    >
      <Flex align="center" justifyContent="space-between">
        <HStack spacing={3}>
          <Icon as={icon} w={8} h={8} color={textColor} />
          <VStack align="start" spacing={0}>
            <Text fontSize="xl" fontWeight="bold" color={textColor}>
              {title}
            </Text>
            <Text fontSize="sm" color={subTextColor}>
              {subTitle}
            </Text>
          </VStack>
        </HStack>
        <Text fontSize="3xl" fontWeight="bold" color={textColor}>
          {value}
        </Text>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Box
          mt={4}
          p={4}
          bg={useColorModeValue("gray.50", "gray.600")}
          borderRadius="md"
        >
          <Text fontSize="sm" color={subTextColor}>
            Detailed information about {title.toLowerCase()} goes here.
          </Text>
        </Box>
      </Collapse>
    </Box>
  );
};

// ItemsRankingGrid Component (using Grid)
const ItemsRankingGrid = ({ data }) => {
  // Define color constants
  const textColor = useColorModeValue("gray.800", "white");
  const gridHeaderBg = useColorModeValue("gray.100", "gray.700");

  return (
    <Box w="100%" h="100%">
      <Grid
        templateColumns="repeat(3, 1fr)"
        gap={4}
        alignItems="center"
        bg={gridHeaderBg}
        p={2}
        borderRadius="md"
      >
        <GridItem colSpan={1}>
          <Text fontWeight="bold" color={textColor}>
            Item
          </Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold" color={textColor} textAlign="right">
            Units Sold
          </Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold" color={textColor} textAlign="right">
            Revenue ($)
          </Text>
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(3, 1fr)"
        gap={4}
        mt={2}
        h="calc(100% - 40px)"
      >
        {data.slice(0, 5).map((item) => (
          <React.Fragment key={item.id}>
            {/* Item Name with Circular Image */}
            <GridItem>
              <HStack spacing={3}>
                <Box
                  position="relative"
                  borderRadius="full"
                  overflow="hidden"
                  width="40px"
                  height="40px"
                  minWidth={"40px"}
                >
                  <Image
                    src={getMediaURL(item.imageUrl)}
                    alt={item.name}
                    boxSize="40px"
                    objectFit="cover"
                    fallbackSrc="https://via.placeholder.com/40"
                  />
                  {/* Gradient Overlay */}
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    bgGradient="linear(to-t, rgba(0,0,0,0.2), transparent)"
                  />
                </Box>
                <Text color={textColor}>{item.item}</Text>
              </HStack>
            </GridItem>
            {/* Units Sold */}
            <GridItem>
              <Text color={textColor} textAlign="right">
                {item.timesSold}
              </Text>
            </GridItem>
            {/* Revenue */}
            <GridItem>
              <Text color={textColor} textAlign="right">
                ${parseFloat(item.revenue).toFixed(2)}
              </Text>
            </GridItem>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

// ItemSales Component
const ItemSales = () => {
  const [itemData, setItemData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isFilter, setIsFilter] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: moment().subtract(1, "month").startOf("month"),
    endDate: moment().subtract(1, "month").endOf("month"),
  });
  const [chartType, setChartType] = useState("line");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [itemsData, setItemsData] = useState(null);
  const [metricOption, setMetricOption] = useState("revenue");
  const [keyMetricsFilter, setKeyMetricsFilter] = useState({});
  const [isAscending, setIsAscending] = useState(true);
  const handleSort = () => {
    setIsAscending(!isAscending);
  };

  // Sort itemData based on item name
  const sortedData = [...itemData].sort((a, b) => {
    if (isAscending) {
      return a.item.localeCompare(b.item);
    } else {
      return b.item.localeCompare(a.item);
    }
  });

  const navigate = useNavigate();

  const location = useLocation();
  const { data } = location.state || {};

  // Define color constants
  const textColor = useColorModeValue("gray.800", "white");
  const tableHeaderBg = useColorModeValue("gray.100", "gray.700");
  const iconColor = useColorModeValue("gray.800", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const subTextColor = useColorModeValue("gray.600", "gray.400");
  const chartbg = useColorModeValue("gray.100", "gray.700");

  const fetchAnalyticsData = async () => {
    try {
      setIsLoading(true)
      const params1 = {
        filter: selectedFilter,
      };
      const params2 = {
        startDate: selectedDateRange.startDate?.format("YYYY-MM-DD"),
        endDate: selectedDateRange.endDate?.format("YYYY-MM-DD"),
      };
      const token = localStorage.getItem("access_token");
      const res = await API.get("/analytics/item-sales", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: isFilter ? params1 : params2,
      });
      const itemsRes = res.data.data.items;
      const totalRevenue =itemsRes.length > 0 ? itemsRes.reduce(
        (sum, category) => sum + parseFloat(category.revenue),
        0
      ):0;
      const averageRevenue = totalRevenue / itemsRes.length || 0;

      // Find best category by revenue
      const bestCategoryByRevenue =itemsRes.length > 0 ? itemsRes.reduce((prev, current) =>
        parseFloat(current.revenue) > parseFloat(prev.revenue) ? current : prev
      ):0;

      // Find best category by units sold
      const bestCategoryByUnitsSold =itemsRes.length > 0 ? itemsRes.reduce((prev, current) =>
        current.itemsSold > prev.timesSold ? current : prev
      ):0;
      setKeyMetricsFilter({
        totalRevenue: totalRevenue.toFixed(2),
        averageRevenue: averageRevenue.toFixed(2),
        bestCategoryByRevenue: parseFloat(
          bestCategoryByRevenue.revenue
        ).toFixed(2),
        bestCategoryByUnitsSold: bestCategoryByUnitsSold.timesSold,
      });
      setItemsData(res.data?.data?.items);
      setIsLoading(false)
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    } finally {
      setIsLoading(false)
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("itemSalesData", data);
    fetchAnalyticsData();
    if (data && data.items) {
      // Sort items based on timesSold
      const sortedItems = [...data.items].sort(
        (a, b) => b.timesSold - a.timesSold
      );

      // Set itemData to sortedItems
      setItemData(sortedItems);

      setLoading(false);
    } else {
      setItemData([]);
      setLoading(false);
    }
  }, [data, selectedFilter, selectedDateRange]);

  // Calculate total revenue
  const totalRevenue = useMemo(() => {
    return itemData.reduce((sum, item) => sum + parseFloat(item.revenue), 0);
  }, [itemData]);

  const handleSelection = (selection) => {
    setSelectedFilter(selection);
    setIsFilter(true);
  };

  return (
    <Box p={5} color={textColor} minH="100vh">
      {loading ? (
        <Flex justify="center" align="center" minH="80vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <VStack spacing={6} align="stretch">
          {/* Header with Back Button */}
          <Flex align="center">
            <IconButton
              icon={<FiArrowLeft />}
              variant="ghost"
              borderRadius={"full"}
              color={iconColor}
              onClick={() => navigate(-1)}
              aria-label="Go Back"
              mr={4}
              size="lg"
            />

            <VStack align="start" spacing={1}>
              <Heading size="xl" fontWeight="bold">
                Item Sales
              </Heading>
              <Text fontSize="md" color={subTextColor}>
                Analyze sales performance by items
              </Text>
            </VStack>
          </Flex>

          {/* Item Sales Tile and Items Ranking Grid */}
          <Flex direction={{ base: "column", md: "row" }} gap={6}>
            {/* Item Sales Tile */}
            <Box flex="1" minH="200px">
              <InfoTile
                title="Total Revenue"
                subTitle="Overall revenue from all items"
                value={`$${totalRevenue.toFixed(2)}`}
                icon={FiDollarSign}
              />
            </Box>

            {/* Items Ranking Grid */}
            <Box flex="1" minH="200px">
              <ItemsRankingGrid data={itemData} />
            </Box>
          </Flex>

          {/* Line Chart */}
          <Box borderRadius="md" width={"full"} bg={chartbg} boxShadow="md">
            <Heading size="md" mx={5} mt={4}>
              Revenue Over Time
            </Heading>
            <Box height="450px" width={"full"}>
              <VStack>
                <HStack
                  padding={4}
                  width={"100%"}
                  justifyContent={"flex-start"}
                  wrap={"wrap"}
                >
                  <DateRangePicker
                    onDateRangeChange={(range) => {
                      setIsFilter(false);
                      setSelectedDateRange(range);
                    }}
                  />
                  <DateSelector onDateSelect={handleSelection} />
                  <KeyMetricsSelector
                    options={[
                      { label: "Revenue", value: "revenue" },
                      { label: "Times Item Sold", value: "timesSold" },
                    ]}
                    onSelectionChange={(option) => setMetricOption(option)}
                  />
                  <ChartSelection onChange={(type) => setChartType(type)} />
                </HStack>
                <HStack
                  paddingX={4}
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={10}
                  wrap={"wrap"}
                >
                  <Text display={"flex"} alignItems={"center"}>
                    <Icon
                      as={FiTrendingUp}
                      w={5}
                      h={5}
                      color={"green.500"}
                      marginRight={"4px"}
                    />
                    Total Sales :$
                    <Text as={"span"}>{keyMetricsFilter?.totalRevenue}</Text>
                  </Text>
                  <Text display={"flex"} alignItems={"center"}>
                    <Icon
                      as={FiTrendingUp}
                      w={5}
                      h={5}
                      color={"blue.500"}
                      marginRight={"4px"}
                    />
                    Best Item by Revenue :$
                    <Text as={"span"}>
                      {keyMetricsFilter?.bestCategoryByRevenue}
                    </Text>
                  </Text>
                  <Text display={"flex"} alignItems={"center"}>
                    <Icon
                      as={FiTrendingUp}
                      w={5}
                      h={5}
                      color={"orange.500"}
                      marginRight={"4px"}
                    />
                    Best Item by Units Sold :
                    <Text as={"span"}>
                      {keyMetricsFilter?.bestCategoryByUnitsSold}
                    </Text>
                  </Text>
                  <Text display={"flex"} alignItems={"center"}>
                    <Icon
                      as={FiTrendingUp}
                      w={5}
                      h={5}
                      color={"purple.500"}
                      marginRight={"4px"}
                    />
                    Average Revenue per Item :$
                    <Text as={"span"}>{keyMetricsFilter?.averageRevenue}</Text>
                  </Text>
                </HStack>
                <Box width={"100%"} height={"380px"}>
                  <BarLineChart
                    data={itemsData}
                    chartType={chartType}
                    xKey={"item"}
                    yKey={metricOption}
                    valueKey={metricOption}
                    isLoading={isLoading}
                  />
                </Box>
              </VStack>
            </Box>
          </Box>

          {/* Items Sold and Revenue Table */}
          <Box
            borderWidth="1px"
            borderColor={borderColor}
            borderRadius="md"
            p={4}
          >
            <Heading size="md" mb={4}>
              Items Sold and Revenue
            </Heading>
            <Box overflowX="auto">
              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                {/* Table Headers */}
                <Box
                  bg={tableHeaderBg}
                  p={2}
                  borderRadius="md"
                  onClick={handleSort}
                  cursor="pointer"
                >
                  <Text fontWeight="bold" color={textColor}>
                    Item {isAscending ? "↑" : "↓"}
                  </Text>
                </Box>
                <Box bg={tableHeaderBg} p={2} borderRadius="md" onClick={handleSort}>
                  <Text fontWeight="bold" color={textColor} textAlign="right">
                    Units Sold {isAscending ? "↑" : "↓"}
                  </Text>
                </Box>
                <Box bg={tableHeaderBg} p={2} borderRadius="md" onClick={handleSort}>
                  <Text fontWeight="bold" color={textColor} textAlign="right">
                    Revenue ($) {isAscending ? "↑" : "↓"}
                  </Text>
                </Box>

                {/* Table Rows */}
                {sortedData.map((item) => (
                  <>
                    <Box p={2} borderBottom="1px" borderColor={borderColor}>
                      <HStack spacing={3}>
                        <Box
                          position="relative"
                          borderRadius="full"
                          overflow="hidden"
                          width="40px"
                          height="40px"
                        >
                          <Image
                            src={getMediaURL(item.imageUrl)}
                            alt={item.name}
                            boxSize="40px"
                            objectFit="cover"
                            fallbackSrc="https://via.placeholder.com/40"
                          />
                          <Box
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                            bgGradient="linear(to-t, rgba(0,0,0,0.2), transparent)"
                          />
                        </Box>
                        <Text color={textColor}>{item.item}</Text>
                      </HStack>
                    </Box>
                    <Box p={2} borderBottom="1px" borderColor={borderColor}>
                      <Text color={textColor} textAlign="right">
                        {item.timesSold}
                      </Text>
                    </Box>
                    <Box p={2} borderBottom="1px" borderColor={borderColor}>
                      <Text color={textColor} textAlign="right">
                        ${parseFloat(item.revenue).toFixed(2)}
                      </Text>
                    </Box>
                  </>
                ))}
              </Grid>
            </Box>
          </Box>
        </VStack>
      )}
    </Box>
  );
};

export default ItemSales;
