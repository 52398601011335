import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Collapse,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

const StatButton = ({ label, value, isSelected, onClick }) => {
  const hoverBgColor = useColorModeValue("gray.200", "gray.700");
  const selectedBgColor = useColorModeValue("gray.200", "gray.600");
  const bgColor = useColorModeValue("gray.100", "gray.800");

  return (
    <Box
      as="button"
      onClick={onClick}
      flex="1"
      padding="4"
      margin="2"
      borderRadius="md"
      boxShadow={isSelected ? "md" : "sm"}
      bg={isSelected ? selectedBgColor : bgColor}
      _hover={{
        backgroundColor: hoverBgColor,
        transition: "background-color 0.3s ease",
      }}
    >
      <Text fontSize="xl" fontWeight="bold">
        {label}
      </Text>
      <Text fontSize="lg">{value}</Text>
    </Box>
  );
};

const StaffAnalytics = () => {
  const [selectedTile, setSelectedTile] = useState("attendance");

  const toggleTile = (tile) => {
    setSelectedTile(selectedTile === tile ? null : tile);
  };

  return (
    <Flex direction="column" padding="4">
      <Heading size="lg" marginBottom="4">
        Staff Analytics
      </Heading>

      {/* Tiles Section */}
      <Flex wrap="wrap">
        <StatButton
          label="Attendance"
          value=" - "
          isSelected={selectedTile === "attendance"}
          onClick={() => toggleTile("attendance")}
        />
        <StatButton
          label="Performance"
          value=" - "
          isSelected={selectedTile === "performance"}
          onClick={() => toggleTile("performance")}
        />
        <StatButton
          label="Tasks Completed"
          value=" - "
          isSelected={selectedTile === "tasks"}
          onClick={() => toggleTile("tasks")}
        />
        <StatButton
          label="Training Progress"
          value=" - "
          isSelected={selectedTile === "training"}
          onClick={() => toggleTile("training")}
        />
      </Flex>

      {/* Details Section */}
      <Box mt={4}>
        <Collapse in={selectedTile === "attendance"} animateOpacity>
          <Box p="4" boxShadow="md" borderRadius="md" mb={4}>
            <Flex justify={"space-between"} alignItems={"center"}>
              <Heading size="md">Attendance details</Heading>
              <IconButton
                aria-label="Close"
                icon={<CloseIcon />}
                size="sm"
                onClick={() => setSelectedTile(null)}
                alignSelf={"flex-end"}
              />
            </Flex>
            <Text>No data found</Text>
          </Box>
        </Collapse>

        <Collapse in={selectedTile === "performance"} animateOpacity>
          <Box p="4" boxShadow="md" borderRadius="md" mb={4}>
            <Flex justify={"space-between"} alignItems={"center"}>
              <Heading size="md">Performance Overview</Heading>
              <IconButton
                aria-label="Close"
                icon={<CloseIcon />}
                size="sm"
                onClick={() => setSelectedTile(null)}
                alignSelf={"flex-end"}
              />
            </Flex>
            <Text>No data found</Text>
          </Box>
        </Collapse>

        <Collapse in={selectedTile === "tasks"} animateOpacity>
          <Box p="4" boxShadow="md" borderRadius="md" mb={4}>
            <Flex justify={"space-between"} alignItems={"center"}>
              <Heading size="md">Tasks Completed</Heading>
              <IconButton
                aria-label="Close"
                icon={<CloseIcon />}
                size="sm"
                onClick={() => setSelectedTile(null)}
                alignSelf={"flex-end"}
              />
            </Flex>
            <Text>No data found</Text>
          </Box>
        </Collapse>

        <Collapse in={selectedTile === "training"} animateOpacity>
          <Box p="4" boxShadow="md" borderRadius="md" mb={4}>
            <Flex justify={"space-between"} alignItems={"center"}>
              <Heading size="md">Training Progress</Heading>
              <IconButton
                aria-label="Close"
                icon={<CloseIcon />}
                size="sm"
                onClick={() => setSelectedTile(null)}
                alignSelf={"flex-end"}
              />
            </Flex>
            <Text>No data found</Text>
          </Box>
        </Collapse>
      </Box>
    </Flex>
  );
};

export default StaffAnalytics;
