import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {IoIosRefresh} from "react-icons/io";
import API, {getMediaURL} from "../../context/API";
import SearchBar from "../../theme/Layout/Searchbar";

function SoldOut({tabIndex, role }) {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false); // Separate state for refresh loading
  const [error, setError] = useState(null);
  const [itemToToggle, setItemToToggle] = useState(null); // For toggling availability
  const [lastUpdated, setLastUpdated] = useState(null); // To store last updated time

  const bgColor = useColorModeValue("white", "gray.800");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const fetchSoldOutItems = async () => {
    try {
      setRefreshing(true);
      const response = await API.get(
        "/items?include=customizations,category_names"
      );
      const itemsData = response.data.data
        .filter((item) => item.sold_out === true) // Filter items that are sold out
        .map((item) => ({
          ...item,
          picture: getMediaURL(item.picture),
        }));
      if (response.data.success) {
        setItems(itemsData);
        setFilteredItems(itemsData);
        setLastUpdated(new Date().toLocaleString()); // Update last updated time
      } else {
        setError("Failed to fetch sold-out items.");
      }
    } catch (error) {
      setError("An error occurred while fetching sold-out items.");
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  useEffect(() => {
    if(tabIndex === 4)
    {
       fetchSoldOutItems();
    }
  }, [tabIndex]);

  const handleToggleSoldOut = async () => {
    try {
      const updatedItem = { ...itemToToggle, sold_out: false };

       // Clean the picture path to remove any duplicated base URLs
    const cleanPicturePath = (path) => {
      const baseURL = "https://kiwikiosk.nyc3.digitaloceanspaces.com/media/";
      if (!path || path.trim() === "") {
        return "default.jpg";
      }
      const parts = path.split(baseURL);
      let picturePath = parts[parts.length - 1];
      if (!picturePath || picturePath.trim() === "") {
        return "default.jpg";
      }
      return picturePath;
    };

    updatedItem.picture = cleanPicturePath(updatedItem.picture);

      // Process category_names to ensure it's an array of IDs as strings
      if (Array.isArray(updatedItem.category_names)) {
        updatedItem.category_names = updatedItem.category_names.map(
          (category) => {
            if (
              typeof category === "object" &&
              category !== null &&
              "id" in category
            ) {
              return category.id.toString();
            }
            return category.toString();
          }
        );
      }

      // Process customizations to ensure it's an array of IDs as strings
      if (Array.isArray(updatedItem.customizations)) {
        updatedItem.customizations = updatedItem.customizations.map(
          (customization) => {
            if (
              typeof customization === "object" &&
              customization !== null &&
              "id" in customization
            ) {
              return customization.id.toString();
            }
            return customization.toString();
          }
        );
      }

      console.log("Updated Item:", updatedItem);

      await API.put(`/items/${itemToToggle.id}`, updatedItem);

      toast({
        title: "Item status updated.",
        description: "The item is now available.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Refresh the list after toggling
      await fetchSoldOutItems();
      onClose();
    } catch (error) {
      console.error("Error updating item:", error);
      toast({
        title: "An error occurred.",
        description: "Unable to update the item.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const confirmToggleSoldOut = (item) => {
    setItemToToggle(item);
    onOpen();
  };

  const handleSearch = (query) => {
    const results = items.filter((item) =>
      item.item.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredItems(results);
  };

  const RefreshButton = () => (
    <IconButton
      icon={<IoIosRefresh />}
      size={"sm"}
      onClick={fetchSoldOutItems}
      isLoading={refreshing}
      loadingText="Refreshing..."
      mx={4}
      px={2}
    />
  );

  if (loading && !refreshing) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Box my={"4"}>
      <Flex
        justify={{ base: "flex-start", md: "space-between" }}
        //align="center"
        marginBottom="4"
        direction={{ base: "column", md: "row" }}
      >
        <Box>
          <Heading size={"xl"} letterSpacing={-2}>
            Sold out items
          </Heading>
          <Text fontSize={"sm"}>
            List of all sold out items, you can mark new ones as sold out, they
            will appear here.
          </Text>
        </Box>
        <Flex align="center">
          <Text fontSize="sm" color="gray.500">
            Last updated: {lastUpdated || "Never"}
          </Text>
          <RefreshButton />
        </Flex>
      </Flex>
      <SearchBar
        items={items.map((item) => item.item)}
        onSearch={handleSearch}
        placeholder="Search items..."
        variant="filled"
        borderRadius="full"
        width="100%"
      />
      <Box p={4} bg={bgColor} borderRadius="md" overflowX="auto">
        <Table variant="simple" borderRadius="md" mt="4" minWidth="600px">
          <Thead>
            <Tr>
              <Th>Item</Th>
              <Th>Description</Th>
              <Th>Price</Th>
              <Th>Image</Th>
              <Th textAlign={"center"}>Sold out</Th>
            </Tr>
          </Thead>
          <Tbody>
            {refreshing ? (
              <Tr>
                <Td colSpan="5">
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    height="150px"
                  >
                    <Spinner size="lg" />
                  </Flex>
                </Td>
              </Tr>
            ) : (
              filteredItems.map((item) => (
                <Tr key={item.id}>
                  <Td>{item.item}</Td>
                  <Td>{item.description || "No description available"}</Td>
                  <Td>
                    $
                    {item.price !== null && item.price !== undefined
                      ? item.price.toFixed(2)
                      : "Free"}
                  </Td>
                  <Td>
                    {item.picture && (
                      <Image
                        src={item.picture}
                        alt={item.item}
                        boxSize="50px"
                        objectFit="cover"
                        borderRadius="md"
                        fallbackSrc="https://via.placeholder.com/50"
                      />
                    )}
                  </Td>
                  <Td>
                    <Flex justifyContent={"center"}>
                      <Switch
                        isChecked={item.sold_out}
                        onChange={() => confirmToggleSoldOut(item)}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </Box>
      {/* Toggle Sold-Out Status Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Status Change</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to mark this item as available again?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={handleToggleSoldOut}>
              Yes, Make Available
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default SoldOut;
