import React from 'react';
import DashboardMerchant from './dashboardMerchant';
import DashboardAdmin from './dashboardAdmin';
import DashboardManager from './dashboardManager';
import DashboardStaff from './staffDashboard';

const Dashboard = ({ role }) => {
    return (
        <div>
            {role === 'admin' && <DashboardAdmin />}
            {role === 'merchant' && <DashboardMerchant />}
            {role === 'staff' && <DashboardStaff />}
            {role === 'manager' && <DashboardManager />}
        </div>
    );
};

export default Dashboard;
