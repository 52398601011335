import {
  FaChevronDown,
  FaChevronUp,
  FaCog,
  FaHome,
  FaMobile,
  FaSellsy,
  FaShoppingCart,
  FaSignOutAlt,
  FaStore,
  FaTasks,
  FaUsers,
  FaHistory,
} from "react-icons/fa";
import {
  MdFeedback,
  MdGroups,
  MdHolidayVillage,
  MdMenuBook,
  MdOutlinePayments,
  MdAssignmentAdd,
} from "react-icons/md";
import { BiFoodMenu, BiCategoryAlt } from "react-icons/bi";
import { GrDocumentPerformance } from "react-icons/gr";
import { PiShootingStarThin, PiTipi } from "react-icons/pi";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { HiDocumentReport } from "react-icons/hi";
import {
  RiBillFill,
  RiSlideshow4Fill,
  RiUserSettingsFill,
} from "react-icons/ri";
import { IoMdDocument, IoMdPeople } from "react-icons/io";
import { CiBank } from "react-icons/ci";
import { GrAnalytics, GrSchedules } from "react-icons/gr";
import { MdPayment } from "react-icons/md";
import { TfiStatsUp } from "react-icons/tfi";
import { GrPerformance } from "react-icons/gr";
import { CgColorPicker } from "react-icons/cg";
import { LuMenuSquare } from "react-icons/lu";
import { AiOutlineMobile } from "react-icons/ai";
import { FiActivity } from 'react-icons/fi';

// Mapping object
const IconMapping = {
  FaChevronDown: FaChevronDown,
  FaChevronUp: FaChevronUp,
  FaCog: FaCog,
  FaHome: FaHome,
  FaMobile: FaMobile,
  FaSellsy: FaSellsy,
  FaShoppingCart: FaShoppingCart,
  FaSignOutAlt: FaSignOutAlt,
  FaStore: FaStore,
  FaTasks: FaTasks,
  FaUsers: FaUsers,
  MdFeedback: MdFeedback,
  MdGroups: MdGroups,
  MdHolidayVillage: MdHolidayVillage,
  MdMenuBook: MdMenuBook,
  BiCategoryAlt: BiCategoryAlt,
  MdOutlinePayments: MdOutlinePayments,
  BiFoodMenu: BiFoodMenu,
  GrDocumentPerformance: GrDocumentPerformance,
  GrSchedules: GrSchedules,
  PiShootingStarThin: PiShootingStarThin,
  PiTipi: PiTipi,
  LiaFileInvoiceDollarSolid: LiaFileInvoiceDollarSolid,
  HiDocumentReport: HiDocumentReport,
  RiBillFill: RiBillFill,
  RiSlideshow4Fill: RiSlideshow4Fill,
  IoMdDocument: IoMdDocument,
  IoMdPeople: IoMdPeople,
  CiBank: CiBank,
  GrAnalytics: GrAnalytics,
  MdPayment: MdPayment,
  TfiStatsUp: TfiStatsUp,
  GrPerformance: GrPerformance,
  RiUserSettingsFill: RiUserSettingsFill,
  CgColorPicker: CgColorPicker,
  FaHistory: FaHistory,
  MdAssignmentAdd: MdAssignmentAdd,
  LuMenuSquare: LuMenuSquare,
  AiOutlineMobile:AiOutlineMobile,
  FiActivity:FiActivity
};

export default IconMapping;
