import React from "react";
import "../../App.css"; 
import { Box, useColorMode } from "@chakra-ui/react";

const Card = ({ children, className, ...props }) => {
  const { colorMode } = useColorMode();

  const glassEffect = {
    bg: colorMode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'white', 
    backdropFilter: colorMode === 'dark' ? 'blur(10px)' : 'none', 
    boxShadow: colorMode === 'dark' ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'sm', 
  };
  return (
    <Box
      borderRadius="10px"
      p={4} 
      w="auto"
      h="auto"
      overflow="hidden"
      {...glassEffect}
      {...props} 
    >
      {children}
    </Box>
  );
};

export default Card;
