import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  VStack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "daterangepicker/daterangepicker.css";
import "daterangepicker";

const KeyMetricsSelector = ({ options,onSelectionChange }) => {
  const [selectedOption, setSelectedOption] = useState(options?.[0]?.label);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    // Add event listener to detect clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option.label);
    setIsDropdownOpen(false);

      // Define date ranges based on selection
      const selectedRange = getPredefinedDateRange(option.value);
      // Pass selected range back to parent
      onSelectionChange(selectedRange);

  };

  const getPredefinedDateRange = (value) => {
    return value
  };

  // Initialize the custom date picker
  useEffect(() => {
    
  }, [ onSelectionChange]);
  
  const listbg = useColorModeValue("blue.50", "gray.600");
  const containbg = useColorModeValue("white", "gray.700");

  return (
    <Box position="relative" ref={dropdownRef}>
      <Button
        onClick={() => setIsDropdownOpen((prev) => !prev)}
        rightIcon={isDropdownOpen ? <FaChevronUp color="#718096"/> : <FaChevronDown color="#718096"/>}
        variant="outline"
        width="full"
        color="gray.800"
        fontWeight="medium"
        px={4}
        cursor="pointer"
        borderRadius="md"
        boxShadow="sm"
      >
        <Text color={"gray.500"}>{selectedOption}</Text>
      </Button>
      {isDropdownOpen && (
        <VStack
          position="absolute"
          top="100%"
          left={0}
          width="145px"
          bg={containbg}
          boxShadow="md"
          borderRadius="md"
          mt={2}
          spacing={0}
          zIndex={10}
        >
          {options.map((option) => (
            <Box
              key={option.value}
              width="full"
              py={2}
              px={4}
              textAlign="left"
              cursor="pointer"
              bg={selectedOption === option.label ? listbg : "transparent"}
              color={selectedOption === option.label ? "blue.500" : "inherit"}
              fontWeight={selectedOption === option.label ? "bold" : "normal"}
              _hover={{ bg: listbg }}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </Box>
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default KeyMetricsSelector;
