import React from 'react';
import { Box, Button, Flex, HStack, Select, Text } from '@chakra-ui/react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const Pagination = ({ 
  currentPage, 
  totalPages, 
  itemsPerPage, 
  onPageChange, 
  onItemsPerPageChange 
}) => {
  
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleSelectChange = (event) => {
    onItemsPerPageChange(parseInt(event.target.value, 10));
  };

  return (
    <Flex align="center" display={"flex"} justify="space-between" padding="4">
      <HStack spacing={4}>
        <Button 
          onClick={handlePreviousPage} 
          isDisabled={currentPage === 1}
          leftIcon={<FiChevronLeft />}
        />
        
        <Text>
          Page {currentPage} of {totalPages}
        </Text>

        <Button 
          onClick={handleNextPage} 
          isDisabled={currentPage === totalPages}
          rightIcon={<FiChevronRight />}
        />
        <Select 
        value={itemsPerPage} 
        onChange={handleSelectChange} 
        width="100px"
      >
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={30}>30</option>
        <option value={50}>50</option>
      </Select>
      </HStack>
    </Flex>
  );
};

export default Pagination;
