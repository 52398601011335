import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  HStack,
  Input,
  PinInput,
  PinInputField,
  VStack,
  Text,
  useToast,
} from "@chakra-ui/react";
import API from "../context/API";

const OtpPhoneInput = ({ setIsLoggedIn, otp: otp1, phone, role }) => {
  const [otp, setOtp] = useState("");
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [resendTimer, setResendTimer] = useState(30);
  const toast = useToast();

  // Mask phone number, showing only the last 4 digits
  const maskedPhoneNumber = phone
    ? `${"*".repeat(phone.length - 4)}${phone.slice(-4)}`
    : "";

  useEffect(() => {
    let timer;
    if (isResendDisabled && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      setIsResendDisabled(false);
      setResendTimer(30);
    }

    return () => clearInterval(timer);
  }, [isResendDisabled, resendTimer]);

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  const handleVerify = async () => {
    try {
      if (otp.length < 6) {
        toast({
          title: "Invalid OTP",
          description: "Please enter a 6-digit OTP.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      const res = await API.post(`/merchant/verify-otp`, {
        phone: phone,
        otp: otp,
      });

      if (res.data.status === 200) {
        toast({
          title: "Verification Successful",
          description: "Your phone number and OTP have been verified.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          setIsLoggedIn(true);
          localStorage.setItem("access_token", res.data.data.jwt);
          localStorage.setItem("user", JSON.stringify(res.data.data[role]));
          window.location.href = "/";
        }, 1500);
      }
    } catch (error) {
      console.log("error verifying OTP", error);
    }
  };

  const handleResendOtp = async () => {
    try {
        const res = await API.post(`/merchant/resend-otp`, {
            phone: phone
          });

      setOtp(String(res.data.data.otp))
      setIsResendDisabled(true);
      toast({
        title: "OTP Resent",
        description: "A new OTP has been sent to your phone number.",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {}
  };

  if (!otp1 || !phone) return null;

  return (
    <Box
      p={6}
      maxWidth="100%"
      mx="auto"
      boxShadow="lg"
      borderRadius="md"
      bg="white"
      display="flex"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        p={6}
        maxWidth="400px"
        mx="auto"
        mt={8}
        boxShadow="lg"
        borderRadius="md"
        bg="white"
        maxH="400px"
        height="400px"
        textAlign="center"
      >
        <VStack spacing={6} align="stretch">
          <Text fontSize="2xl" fontWeight="bold" color="blue.600">
            Verify Your Phone Number
          </Text>

          {/* Read-only Phone Number Input with Masked Display */}
          <Input
            value={maskedPhoneNumber}
            readOnly
            focusBorderColor="blue.500"
            variant="outline"
            size="lg"
            borderRadius="md"
            textAlign="center"
          />

          {/* OTP Input */}
          <HStack justifyContent="center">
            <PinInput
              onChange={handleOtpChange}
              value={otp || ""}
              focusBorderColor="blue.500"
              size="lg"
            >
              {Array.from({ length: 6 }).map((_, index) => (
                <PinInputField key={index} />
              ))}
            </PinInput>
          </HStack>

          {/* Resend OTP Button */}
          <Button
            colorScheme="blue"
            variant="link"
            onClick={handleResendOtp}
            isDisabled={isResendDisabled}
          >
            Resend OTP {isResendDisabled ? `in ${resendTimer}s` : ""}
          </Button>

          {/* Verify Button */}
          <Button
            colorScheme="blue"
            onClick={handleVerify}
            isFullWidth
            size="lg"
          >
            Verify
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default OtpPhoneInput;
