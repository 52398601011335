import React, {useEffect, useRef, useState} from "react";
import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  useColorMode
} from "@chakra-ui/react";
import {CloseIcon, SearchIcon} from "@chakra-ui/icons";

const SearchBar = ({ items, onSearch, onSuggestionClick, placeholder = "Search...", ...props }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const { colorMode } = useColorMode();
  const searchBarRef = useRef(null); // Reference for the search bar

  // Fetch or generate suggestions based on the input query
  const fetchSuggestions = (query) => {
    return items.filter(item =>
      item.toLowerCase().includes(query.toLowerCase())
    );
  };

  useEffect(() => {
    if (query) {
      setSuggestions(fetchSuggestions(query));
    } else {
      setSuggestions([]);
    }
  }, [query, items]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearch(query);
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setSuggestions([]);
    onSearch(suggestion);
  };

  const clearSearch = () => {
    setQuery('');
    setSuggestions([]);
    onSearch(''); // Optionally reset the search
  };

  const handleClickOutside = (event) => {
    if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
      setSuggestions([]); // Close the suggestions when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const suggestionBg = colorMode === "light" ? "whiteAlpha.900" : "gray.700";
  const suggestionHoverBg = colorMode === "light" ? "gray.100" : "gray.600";
  const suggestionTextColor = colorMode === "light" ? "gray.800" : "gray.200";

  return (
    <Box position="relative" ref={searchBarRef} {...props}>
      <InputGroup mb="4">
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.500" />
        </InputLeftElement>
        <Input
          type="text"
          placeholder={placeholder}
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          variant="filled"
          borderRadius="full"
          {...props}
        />
        {query && (
          <InputRightElement>
            <IconButton
              icon={<CloseIcon />}
              size="sm"
              onClick={clearSearch}
              variant="ghost"
              aria-label="Clear search"
            />
          </InputRightElement>
        )}
      </InputGroup>
      {suggestions.length > 0 && (
        <Box
          position="absolute"
          top="100%"
          left="0"
          right="0"
          zIndex="10"
          bg={suggestionBg}
          borderRadius="lg"
          boxShadow="lg"
          maxHeight="200px"
          overflowY="auto"
          backdropFilter="blur(10px)" // Adds a blur effect to the background
        >
          <List spacing={1}>
            {suggestions.map((suggestion, index) => (
              <ListItem
                key={index}
                p={3}
                cursor="pointer"
                borderRadius="md"
                _hover={{ bg: suggestionHoverBg }}
                color={suggestionTextColor}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default SearchBar;
