import React, {useEffect, useState} from 'react';
import {
  Avatar,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {EditIcon} from '@chakra-ui/icons';
import {useNavigate} from 'react-router-dom';

const Profile = ({ role }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();

  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const headingColor = useColorModeValue("gray.900", "white");
  const labelColor = useColorModeValue("gray.500", "gray.200");

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    //console.log(storedUser);
    //console.log(storedUser[role]);

    if (storedUser) {
      setUser(storedUser);
      setLoading(false);
    } else {
      toast({
        title: 'Error fetching user details.',
        description: 'User data not found in local storage.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box

        justifyContent="center"
      alignItems="center"
      p={{ base: "4", md: "2" }}
      pt={{ base: "4", md: "6" }}
    >
      <Heading size={"lg"} letterSpacing={"-2"} mb={"4"}>
        User profile
      </Heading>
      <Box
        bg={bgColor}
        p={{ base: "6", md: "8" }}
        borderRadius="lg"
        //boxShadow="lg"
        w="full"
        textAlign="left"
      >
        <Flex justify="space-between" alignItems="center" mb={6}>
          <Avatar
            size="xl"
            name={user?.name || user?.username}
            src={`https://ui-avatars.com/api/?name=${user?.name || user?.username}`}
          />
          {role !== "staff" && role !== "manager" && (
            <IconButton
              icon={<EditIcon />}
              onClick={() => navigate("/settings")}
              variant="ghost"
              color={headingColor}
              aria-label="Edit Profile"
            />
          )}
        </Flex>
        <VStack align="start" spacing={2} mb={6}>
          <Heading size="lg" color={headingColor}>
            {user?.name || user?.username }
          </Heading>
          <Text fontSize="md" color="blue.500">
            {role.charAt(0).toUpperCase() + role.slice(1)}
          </Text>
          <Text fontSize="sm" color={labelColor}>
            {user?.address || "Location not provided"}
          </Text>
        </VStack>
        <VStack align="start" spacing={4} w="full">
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 2fr" }}
            gap={4}
            w="full"
          >
            <GridItem>
              <FormControl>
                <FormLabel fontSize="sm" color={labelColor}>
                  Phone Number
                </FormLabel>
                <Input
                  type="text"
                  value={user?.phone}
                  isDisabled
                  variant="filled"
                  borderRadius="full"
                  color={textColor}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel fontSize="sm" color={labelColor}>
                  E-mail
                </FormLabel>
                <Input
                  type="email"
                  value={user?.email}
                  isDisabled
                  variant="filled"
                  borderRadius="full"
                  color={textColor}
                />
              </FormControl>
            </GridItem>
          </Grid>
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 2fr" }}
            gap={4}
            w="full"
          >
            <GridItem>
              <FormControl>
                <FormLabel fontSize="sm" color={labelColor}>
                  Username
                </FormLabel>
                <Input
                  type="text"
                  value={`@${user?.username}`}
                  isDisabled
                  variant="filled"
                  borderRadius="full"
                  color={textColor}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel fontSize="sm" color={labelColor}>
                  Role
                </FormLabel>
                <Input
                  type="text"
                  value={role}
                  isDisabled
                  variant="filled"
                  borderRadius="full"
                  color={textColor}
                />
              </FormControl>
            </GridItem>
          </Grid>
        </VStack>
      </Box>
    </Box>
  );
};

export default Profile;
