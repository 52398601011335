import React from "react";
import {
  Box,
  useBreakpointValue,
  useColorModeValue,
  Text,
  Center,
  Spinner,
} from "@chakra-ui/react";
import {
  ResponsiveContainer,
  BarChart,
  LineChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

const BarLineChart = ({
  data,
  chartType = "bar",
  xKey,
  yKey,
  valueKey,
  isLoading,
}) => {
  const chartBg = useColorModeValue("white", "gray.800");

  // Responsive dimensions
  const chartWidth = useBreakpointValue({ base: "100%", md: "100%" });
  const chartHeight = useBreakpointValue({ base: 300, md: 300 });

  // Y-axis label format with dollar or number formatting
  const yAxisTickFormatter = (value) =>
    yKey === "itemsSold" || yKey === "timesSold" || yKey === "numberOfCustomers"
      ? `${value.toLocaleString()}`
      : `$${value.toLocaleString()}`;

  // Tooltip label mapping
  const labelMapping = {
    itemsSold: "Items Sold",
    timesSold: "Times Sold",
    numberOfCustomers: "Number of Customers",
    revenue: "Revenue",
  };

  // Tooltip formatter to use mapped label names
  const tooltipFormatter = (value, name) => {
    const formattedName = labelMapping[name] || name; // Map to readable label or fallback to name
    return [`${value.toLocaleString()}`, formattedName];
  };

  // Dynamic chart selection
  const renderChart = () => {
    if (isLoading) {
      return (
        <Center height="100%">
          <Spinner size="xl" color="blue.500" />
        </Center>
      );
    }

    if (!data || data.length === 0) {
      return (
        <Center height="100%">
          <Text fontSize="lg" fontWeight="bold" color="gray.500">
            No data for current selection
          </Text>
        </Center>
      );
    }

    if (chartType === "line") {
      return (
        <LineChart data={data} width={chartWidth} height={chartHeight}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xKey} stroke="gray" fontSize={12} />
          <YAxis
            stroke="gray"
            fontSize={12}
            dataKey={yKey}
            tickFormatter={yAxisTickFormatter}
          />
          <Tooltip formatter={tooltipFormatter} />
          <Line type="monotone" dataKey={valueKey} stroke="#3182CE" />
        </LineChart>
      );
    } else {
      return (
        <BarChart data={data} width={chartWidth} height={chartHeight}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xKey} stroke="gray" fontSize={12} />
          <YAxis
            stroke="gray"
            fontSize={12}
            dataKey={yKey}
            tickFormatter={yAxisTickFormatter}
          />
          <Tooltip formatter={tooltipFormatter} />
          <Bar dataKey={valueKey} fill="#3182CE" width={"40px"} />
        </BarChart>
      );
    }
  };

  return (
    <Box
      bg={chartBg}
      p={4}
      borderRadius="md"
      boxShadow="sm"
      width="full"
      overflow="hidden"
    >
      <ResponsiveContainer width="100%" height={chartHeight}>
        {renderChart()}
      </ResponsiveContainer>
    </Box>
  );
};

export default BarLineChart;
