import React, { useState } from "react";
import {
  Box,
  Button,
  Icon,
  VStack,
  Text,
  HStack,
  Radio,
  RadioGroup,
  useColorModeValue,
  useOutsideClick,
  useDisclosure,
} from "@chakra-ui/react";
import { FaChartLine, FaChartBar, FaChevronDown, FaChevronUp } from "react-icons/fa";

const ChartSelection = ({ onChange }) => {
  const [selectedType, setSelectedType] = useState("line");
  const { isOpen, onToggle, onClose } = useDisclosure();
  const dropdownRef = React.useRef(null);

  // Close dropdown when clicking outside
  useOutsideClick({
    ref: dropdownRef,
    handler: onClose,
  });

  // Handle selection change and notify parent component
  const handleSelection = (value) => {
    setSelectedType(value);
    onChange(value); // Call parent function with the new selection
    onClose(); // Close dropdown after selection
  };

  const dropdownBg = useColorModeValue("white", "gray.700");
  const selectedColor = useColorModeValue("blue.500", "blue.300");

  return (
    <Box position="relative" ref={dropdownRef}>
      <Button onClick={onToggle} rightIcon={isOpen ? <FaChevronUp color="#718096"/> : <FaChevronDown color="#718096" />}
      variant="outline"
      width="full"
      color="gray.800"
      fontWeight="medium"
      px={4}
      cursor="pointer"
      borderRadius="md"
      boxShadow="sm"
      >
        <Text color={"gray.500"}>View</Text> <Text as="span" fontWeight="bold" color={"gray.500"} ml={1}>{selectedType === "line" ? "Line" : "Bar"}</Text>
      </Button>

      {isOpen && (
        <Box
          position="absolute"
          top="100%"
          left={0}
          mt={2}
          p={4}
          width="200px"
          bg={dropdownBg}
          borderRadius="md"
          boxShadow="md"
          zIndex={10}
        >
          <Text fontSize="md" fontWeight="bold" mb={2}>
            Graph type
          </Text>

          <RadioGroup value={selectedType} onChange={handleSelection}>
            <VStack align="stretch" spacing={3}>
              {/* Line Option */}
              <HStack spacing={3}>
                <Icon as={FaChartLine} boxSize={5} />
                <Text flex="1">Line</Text>
                <Radio
                  value="line"
                  colorScheme="blue"
                  isChecked={selectedType === "line"}
                  _checked={{
                    bg: selectedColor,
                  }}
                />
              </HStack>

              <Box borderBottomWidth="1px" borderColor="gray.300" />

              {/* Bar Option */}
              <HStack spacing={3}>
                <Icon as={FaChartBar} boxSize={5} />
                <Text flex="1">Bar</Text>
                <Radio
                  value="bar"
                  colorScheme="blue"
                  isChecked={selectedType === "bar"}
                  _checked={{
                    bg: selectedColor,
                  }}
                />
              </HStack>
            </VStack>
          </RadioGroup>
        </Box>
      )}
    </Box>
  );
};

export default ChartSelection;
