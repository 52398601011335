import React, {useEffect, useState} from "react";
import {
  Box,
  Collapse,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import API from "../../context/API";
import {CloseIcon} from "@chakra-ui/icons";

function AdminAnalytics() {
  const [activeSessions, setActiveSessions] = useState(0);
  const [merchantsWithSessions, setMerchantsWithSessions] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState("N/A");
  const [totalTransactions, setTotalTransactions] = useState("N/A");
  const [transactionSuccessRate, setTransactionSuccessRate] = useState("N/A");
  const [averageTransactionValue, setAverageTransactionValue] = useState("N/A");
  const [topMerchants, setTopMerchants] = useState([]);
  const [deviceErrors, setDeviceErrors] = useState("N/A");
  const [customerRetentionRate, setCustomerRetentionRate] = useState("N/A");
  const [customerFeedback, setCustomerFeedback] = useState("N/A");
  const [avgResolutionTime, setAvgResolutionTime] = useState("N/A");
  const [commonIssues, setCommonIssues] = useState([]);

  const [showSessionsDetails, setShowSessionsDetails] = useState(true);
  const [isSelected, setIsSelected] = useState(true);

  const hoverBg = useColorModeValue('gray.200', 'gray.700');
  const selectedBg = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    // Fetch data from the API for merchants and sessions
    const fetchData = async () => {
      try {
        const response = await API.get("merchant");
        const merchants = response.data.data;

        console.log("Merchants data:", merchants);
        // Ensure merchants is an array
        if (!Array.isArray(merchants)) {
          throw new Error("Expected an array of merchants");
        }

        // Calculate active devices and prepare merchant sessions data
        let activeDeviceCount = 0;
        let merchantsSessions = [];

        merchants.forEach(merchant => {
          let merchantSessions = [];
          merchant.currentSessions.forEach(session => {
            if (session.isActive) {
              activeDeviceCount++;
            }
            merchantSessions.push(session);
          });
          if (merchantSessions.length > 0) {
            merchantsSessions.push({ merchantName: merchant.name, sessions: merchantSessions });
          }
        });

        setActiveSessions(activeDeviceCount);
        setMerchantsWithSessions(merchantsSessions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleClick = () => {
    setShowSessionsDetails(!showSessionsDetails);
    setIsSelected(!isSelected);
  };

  const handleCloseClick = () => {
    setShowSessionsDetails(false);
    setIsSelected(false);
  }
  const renderOverviewSection = () => (
    <>
      <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap="4" marginBottom="4">
        <GridItem padding="4" borderRadius="md" boxShadow="md">
          <Text fontSize="xl" fontWeight="bold">
            Total Revenue
          </Text>
          <Text fontSize="2xl">{totalRevenue}</Text>
        </GridItem>
        <GridItem padding="4" borderRadius="md" boxShadow="md">
          <Text fontSize="xl" fontWeight="bold">
            Total Transactions
          </Text>
          <Text fontSize="2xl">{totalTransactions}</Text>
        </GridItem>
        <GridItem
          padding="4"
          borderRadius="md"
          boxShadow="md"
          cursor="pointer"
          _hover={{
            backgroundColor: hoverBg,
            transition: "background-color 0.3s ease",
          }}
          backgroundColor={isSelected ? selectedBg : "transparent"}
          transition="background-color 0.3s ease"
          onClick={handleClick}
        >
          <Text fontSize="xl" fontWeight="bold">
            Active Sessions
          </Text>
          <Text fontSize="2xl">{activeSessions}</Text>
        </GridItem>
      </Grid>
      <Collapse in={showSessionsDetails} animateOpacity>
        <Box mt="4" p="4" borderWidth="1px" borderRadius="md" boxShadow="sm">
          <Flex
            justify={"space-between"}
            alignItems={"center"}
            marginBottom="4"
          >
            <Heading size="md">Current Sessions Details</Heading>
            <IconButton
              icon={<CloseIcon />}
              size="sm"
              onClick={handleCloseClick}
              aria-label="Close"
            />
          </Flex>
          {merchantsWithSessions.length > 0 ? (
            merchantsWithSessions.map((merchant, index) => (
              <Box key={index} marginBottom="4">
                <Text fontSize="lg" fontWeight="bold" marginBottom="2">
                  Merchant: {merchant.merchantName}
                </Text>
                <Box overflowX="auto">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Device Name</Th>
                      <Th>Device Brand</Th>
                      <Th>Device Type</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {merchant.sessions.map((session, sessionIndex) => (
                      <Tr key={sessionIndex}>
                        <Td>{session.deviceName}</Td>
                        <Td>{session.deviceBrand}</Td>
                        <Td>{session.deviceType}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                </Box>
              </Box>
            ))
          ) : (
            <Text>No active sessions available.</Text>
          )}
        </Box>
      </Collapse>
    </>
  );

  const renderRevenueAnalysis = () => (
    <Box padding="4" borderRadius="md" boxShadow="sm" marginBottom="4">
      <Heading size="md" marginBottom="4">Revenue Analysis</Heading>
      {/* Future API call can be added here */}
      <Text>Total Revenue: {totalRevenue}</Text>
    </Box>
  );

  const renderTransactionAnalysis = () => (
    <Box padding="4" borderRadius="md" boxShadow="sm" marginBottom="4">
      <Heading size="md" marginBottom="4">Transaction Analysis</Heading>
      {/* Future API call can be added here */}
      <Text>Transaction Success Rate: {transactionSuccessRate}</Text>
      <Text>Average Transaction Value: {averageTransactionValue}</Text>
    </Box>
  );

  const renderMerchantAnalysis = () => (
    <Box padding="4" borderRadius="md" boxShadow="sm" marginBottom="4">
      <Heading size="md" marginBottom="4">Merchant Analysis</Heading>
      {/* Future API call can be added here */}
      <Text>Top Merchants: {topMerchants.length > 0 ? topMerchants.join(", ") : "N/A"}</Text>
    </Box>
  );

  const renderDeviceAnalysis = () => (
    <Box padding="4" borderRadius="md" boxShadow="sm" marginBottom="4">
      <Heading size="md" marginBottom="4">Device Analysis</Heading>
      {/* Future API call can be added here */}
      <Text>Active Devices: {activeSessions}</Text>
      <Text>Device Errors: {deviceErrors}</Text>
    </Box>
  );

  const renderCustomerInsights = () => (
    <Box padding="4" borderRadius="md" boxShadow="sm" marginBottom="4">
      <Heading size="md" marginBottom="4">Customer Insights</Heading>
      {/* Future API call can be added here */}
      <Text>Customer Retention Rate: {customerRetentionRate}</Text>
      <Text>Customer Feedback: {customerFeedback}</Text>
    </Box>
  );

  const renderSupportTickets = () => (
    <Box padding="4" borderRadius="md" boxShadow="sm" marginBottom="4">
      <Heading size="md" marginBottom="4">Support and Tickets</Heading>
      {/* Future API call can be added here */}
      <Text>Average Resolution Time: {avgResolutionTime}</Text>
      <Text>Common Issues: {commonIssues.length > 0 ? commonIssues.join(", ") : "N/A"}</Text>
    </Box>
  );

  const renderCustomReports = () => (
    <Box padding="4" borderRadius="md" boxShadow="sm">
      <Heading size="md" marginBottom="4">Custom Reports</Heading>
      {/* Future API call can be added here */}
      <Text>Generate custom reports based on selected filters and criteria.</Text>
    </Box>
  );

  return (
    <Flex direction="column" height="100vh">
      <Box as="main" flex="1" padding="4">
        <Heading size="lg" marginBottom="4">Analytics</Heading>

        {renderOverviewSection()}
        {renderRevenueAnalysis()}
        {renderTransactionAnalysis()}
        {renderMerchantAnalysis()}
        {renderDeviceAnalysis()}
        {renderCustomerInsights()}
        {renderSupportTickets()}

      </Box>
    </Flex>
  );
}

export default AdminAnalytics;
