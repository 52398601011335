import React, {useEffect, useState} from "react";
import {Box, Flex, Heading, IconButton, Text, Tooltip, useColorMode,} from "@chakra-ui/react";
import {InfoOutlineIcon} from "@chakra-ui/icons";

const StatButton = ({ label, value, change, info, isSelected, onSelect }) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      p="4"
      m="2"
      flexGrow={1}
      border="2px"
      borderColor={
        isSelected
          ? colorMode === "light"
            ? "black"
            : "white"
          : colorMode === "light"
          ? "gray.200"
          : "gray.600"
      }
      borderRadius="md"
      cursor="pointer"
      onClick={onSelect}
      _hover={{ borderColor: colorMode === "light" ? "black" : "white" }}
      transition={"border-color 0.2s ease-in-out"}
      bg={colorMode === "light" ? "white" : "gray.800"}
    >
      <Text fontSize="lg" fontWeight="bold">
        {label}
      </Text>
      <Flex align="center">
        <Text fontSize="xl" fontWeight="bold" mr="2">
          {value}
        </Text>
        <Text
          fontSize="md"
          color={change.startsWith("+") ? "green.500" : "red.500"}
        >
          {change}
        </Text>
      </Flex>
      {info && (
        <Tooltip label={info} hasArrow>
          <IconButton
            aria-label={`Info about ${label}`}
            icon={<InfoOutlineIcon />}
            size="xs"
            variant="ghost"
          />
        </Tooltip>
      )}
    </Flex>
  );
};

export const Sales = () => {
  const [selectedStat, setSelectedStat] = useState("sales");
  const [salesData, setSalesData] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [ticketSizeData, setTicketSizeData] = useState([]);
  const [salesRankingData, setSalesRankingData] = useState([]);

  useEffect(() => {
    // Fetch data from APIs or display "No data found"
    const fetchData = async () => {
      try {
        // Placeholder for fetching sales data
        // Replace this with actual API call when available
        setSalesData([]); // API.get('/sales').then(res => setSalesData(res.data));

        // Placeholder for fetching orders data
        setOrdersData([]); // API.get('/orders').then(res => setOrdersData(res.data));

        // Placeholder for fetching ticket size data
        setTicketSizeData([]); // API.get('/ticket-size').then(res => setTicketSizeData(res.data));

        // Placeholder for fetching sales ranking data
        setSalesRankingData([]); // API.get('/sales-ranking').then(res => setSalesRankingData(res.data));
      } catch (error) {
        console.error("Error fetching data:", error);
        setSalesData(null);
        setOrdersData(null);
        setTicketSizeData(null);
        setSalesRankingData(null);
      }
    };

    fetchData();
  }, []);

  const handleSelectStat = (stat) => {
    setSelectedStat(stat);
  };

  const getChartData = () => {
    if (selectedStat === "sales") return salesData;
    if (selectedStat === "orders") return ordersData;
    if (selectedStat === "ticketSize") return ticketSizeData;
    return [];
  };

  return (
    <>
      <Box>
        <Flex
          justify={"space-between"}
          alignItems={"center"}
          width={"100%"}
          flexWrap={"wrap"}
          my={"4"}
        >
          <Box>
            <Heading size={"xl"} letterSpacing={-2}>
              Sales
            </Heading>
            <Text fontSize={"md"}>
              See how your shop is performing on the platform.
            </Text>
          </Box>
        </Flex>

        <Box my={"4"}>
          <Heading size={{ base: "md", md: "lg" }}>
            Overview
          </Heading>
          <Text>A summary of your revenue and sales by channel.</Text>
          <Flex
            direction={["column", "row"]}
            wrap="wrap"
            justify="center"
            align="center"
          >
            <StatButton
              label="Sales"
              value={salesData.length > 0 ? "US$207" : "No data found"}
              change="↑↓ 0%"
              info="Total sales volume"
              isSelected={selectedStat === "sales"}
              onSelect={() => handleSelectStat("sales")}
            />
            <StatButton
              label="Orders"
              value={ordersData.length > 0 ? "7" : "No data found"}
              change="↑↓ 0%"
              info="Total number of orders"
              isSelected={selectedStat === "orders"}
              onSelect={() => handleSelectStat("orders")}
            />
            <StatButton
              label="Ticket size"
              value={ticketSizeData.length > 0 ? "US$29.58" : "No data found"}
              change="↑↓ 0%"
              info="Average order value"
              isSelected={selectedStat === "ticketSize"}
              onSelect={() => handleSelectStat("ticketSize")}
            />
          </Flex>

        </Box>
      </Box>
    </>
  );
};
