import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Spinner,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { AddIcon, ChevronRightIcon } from "@chakra-ui/icons";
import API, { getMediaURL } from "../../../context/API";
import SearchBar from "../../../theme/Layout/Searchbar";
import CustomizationSideBox from "./CustomizationSideBox";
import { FiRefreshCw } from "react-icons/fi";

function Customizations({ role, selectedCustomizationId ,tabIndex}) {
  const [customizations, setCustomizations] = useState([]);
  const [filteredCustomizations, setFilteredCustomizations] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customizationData, setCustomizationData] = useState(null);
  const [refreshing, setRefreshing] = useState(false); 
  const [lastUpdated, setLastUpdated] = useState(null);
  const [previouslySelectedCustId, setPreviouslySelectedCustId] =
    useState(null);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const hoverBg = colorMode === "light" ? "gray.200" : "gray.900";
  const borderColor = useColorModeValue("gray.300", "gray.700");

  useEffect(() => {
    if(tabIndex === 5){
      fetchCustomizations();
    }
  }, [tabIndex]);

  useEffect(() => {
    if (selectedCustomizationId && customizations.length > 0) {
      const customization = customizations.find(
        (c) => c.id.toString() === selectedCustomizationId.toString()
      );
      if (customization) {
        setSelectedItem(customization.id);
        setCustomizationData({
          customization: customization.customization,
          options: customization.option,
          note: customization.note,
          max_quantity: customization.max_quantity,
          min_quantity: customization.min_quantity,
          multiple_selections: customization.multiple_selections,
          multiple_selections_quantity:
            customization.multiple_selections_quantity,
            related_items: customization.related_items || [],
          });
        onOpen(); // Open the side box
      }
    }
  }, [selectedCustomizationId, customizations]);

  const fetchCustomizations = async () => {
    try {
      setLoading(true);
      const response = await API.get("/customizations");
      const customizationsData = response.data.data.map((customization) => ({
        ...customization,
        options: customization.option.map((option) => ({
          ...option,
          item: {
            ...option.item,
          },
        })),
        related_items: customization.related_items.map((item) => ({
          ...item,
          picture: getMediaURL(item.picture), // Handle related item image URL
        })),
      }));

      customizationsData.sort((a, b) => a.id - b.id);
      if (response.data.success) {
        setCustomizations(customizationsData);
        setFilteredCustomizations(customizationsData);
        setLastUpdated(new Date().toLocaleString()); 
        return customizationsData; 
      } else {
        setError("Failed to fetch customizations.");
        return [];
      }
    } catch (error) {
      console.error("error:", error);
      setError("An error occurred while fetching customizations.");
      return [];
    } finally {
      setLoading(false);
      setRefreshing(false); 
    }
  };

  const handleRefresh = async () => {
    setRefreshing(true); 
    const currentSelectedCustId = selectedItem; 
    setPreviouslySelectedCustId(currentSelectedCustId); 

    try {
      const fetchedCustomizations = await fetchCustomizations(); 
      if (currentSelectedCustId) {
        const customization = fetchedCustomizations.find(
          (c) => c.id === currentSelectedCustId
        );
        if (customization) {
          handleItemClick(customization); 
        } else {
          setSelectedItem(null);
          setCustomizationData(null);
          onClose();
          toast({
            title: "Customization Not Found",
            description:
              "The previously selected customization was not found after refresh.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.error("Error during refresh:", error);
      toast({
        title: "Refresh Failed",
        description: "Unable to refresh customizations. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setRefreshing(false); 
    }
  };

  const handleItemClick = (customization) => {
    setSelectedItem(customization.id);
    setCustomizationData({
      customization: customization.customization,
      options: customization.option,
      note: customization.note,
      max_quantity: customization.max_quantity,
      min_quantity: customization.min_quantity,
      multiple_selections: customization.multiple_selections,
      multiple_selections_quantity: customization.multiple_selections_quantity,
      related_items: customization.related_items || [],
    });
    onOpen();
  };

  const handleInputChange = (field, value) => {
    setCustomizationData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleEdit = (name) => {
    setCustomizationData({
      ...customizationData,
      customization: name,
    });
  };

  const handleCreate = () => {
    setSelectedItem(null);
    setCustomizationData({
      customization: "",
      options: [],
      note: "",
      max_quantity: 1,
      min_quantity: 1,
      multiple_selections: false,
      multiple_selections_quantity: 1,
      related_items: [],
    });
    onOpen();
  };

  const handleDelete = async () => {
    try {
      await API.delete(`/customizations/${selectedItem}`);
      setCustomizations(
        customizations.filter((item) => item.id !== selectedItem)
      );
      setFilteredCustomizations(
        filteredCustomizations.filter((item) => item.id !== selectedItem)
      );
      toast({
        title: "Customization deleted.",
        description: "The customization has been deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.error("Error deleting customization:", error);
    }
  };

  const handleDuplicate = async() => {
    try{
        const postResponse = await API.get(
          `/customizations/clone/${selectedItem}`
        );
        toast({
          title: "Customization created.",
          description: "The customization has been created successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        const newCustomization = postResponse.data.data; 
        const updatedCustomizations = [...customizations, newCustomization];
        setCustomizations(updatedCustomizations);
        setFilteredCustomizations(updatedCustomizations);
        handleItemClick(newCustomization);
      }catch(error){
        console.log("error",error)
      }
  };

  const handleSeeChanges = () => {
    console.log("See changes for customization");
  };

  const handleSubmit = async (submissionType,selectedOptions) => {
    const formattedCustomizationData = {
      customization: customizationData.customization,
      options: customizationData.options.map((option) => {
        if (typeof option === "object" && option !== null && "id" in option) {
          return option.id.toString();
        }
        return option.toString();
      }),

      related_items: (customizationData.related_items || []).map(
        (relatedItem, index) => ({
          id: relatedItem.item && relatedItem.item.id
            ? relatedItem.item.id.toString()
            : "",
          sequence_number: relatedItem.sequence_number || index.toString(),
        })
      ).filter(item => item.item !== ""),
      max_quantity: customizationData.max_quantity,
      min_quantity: customizationData.min_quantity,
      multiple_selections: customizationData.multiple_selections,

      multiple_selections_quantity: Number(
        customizationData.multiple_selections
          ? customizationData.multiple_selections_quantity || 1
          : 1
      ),
    };


    try {
      if (selectedItem) {
        let putResponse
        if(submissionType === "name" ) {
          putResponse = await API.put(
              `/customizations/${selectedItem}/name`,
              {
                customization: customizationData.customization
              }
          );
        }
        if(submissionType === "options" ) {
          putResponse = await API.put(
              `/customizations/${selectedItem}/options`,
              {
                options: selectedOptions.map((option) => {
                  if (typeof option === "object" && option !== null && "id" in option) {
                    return {
                      id:option.id.toString(),
                      sequence_number:option.sequence_number
                    };
                  }
                  return option.toString();
                })
              }
          );
        }
        if(submissionType === "addtoitems" ) {
          putResponse = await API.put(
              `/customizations/${selectedItem}/items`,
              {
                related_items: (customizationData.related_items || []).map(
                    (relatedItem, index) => ({
                      id: relatedItem.item && relatedItem.item.id
                          ? relatedItem.item.id.toString()
                          : "",
                      sequence_number: relatedItem.sequence_number || index.toString(),
                    })
                ).filter(item => item.item !== "")
              }
          );
        }
        if(submissionType === "customizationrules" ) {
          putResponse = await API.put(
              `/customizations/${selectedItem}/rules`,
              {
                max_quantity: customizationData.max_quantity,
                min_quantity: customizationData.min_quantity,
                multiple_selections: customizationData.multiple_selections,

                multiple_selections_quantity: Number(
                    customizationData.multiple_selections
                        ? customizationData.multiple_selections_quantity || 1
                        : 1
                ),
              }
          );
        }
        const updatedCustomization = {
          ...putResponse.data.data,
          options: customizationData.options,
          related_items: customizationData.related_items,
        };

        const updatedCustomizations = customizations.map((c) =>
          c.id === selectedItem ? updatedCustomization : c
        );
        setCustomizations(updatedCustomizations);
        setFilteredCustomizations(updatedCustomizations);

        toast({
          title: "Customization updated.",
          description: "The customization has been updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        formattedCustomizationData.related_items = [{}];
        const postResponse = await API.post(
          "/customizations",
          formattedCustomizationData
        );
        const newCustomization = postResponse.data.data; 

        toast({
          title: "Customization created.",
          description: "The customization has been created successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        const updatedCustomizations = [...customizations, newCustomization];
        setCustomizations(updatedCustomizations);
        setFilteredCustomizations(updatedCustomizations);

        handleItemClick(newCustomization);
      }
    } catch (error) {
      console.error("Error saving customization:", error);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Flex my={"4"}>
      <VStack
        w={"full"}
        maxW={{ base: "100%", md: "70%" }}
        minW={{ base: "100%", md: "70%" }}
        mr={4}
        spacing={2}
      >
        <Flex
          justify={{ base: "center", md: "space-between" }}
          align="center"
          marginBottom="4"
          w={"full"}
          flexWrap={{ md: "wrap", lg: "nowrap" }}
        >
          <Box>
            <Heading size={"xl"} letterSpacing={-2}>
              Customizations
            </Heading>
          </Box>
          <Flex align="center" w={"full"} justify={"flex-end"}>
            <Flex textAlign="right" mr={4} align="center" justify={"center"}>
              <Text fontSize="sm" color="gray.500" mr={"2"}>
                Last updated: {lastUpdated || "Never"}
              </Text>
              <IconButton
                icon={<FiRefreshCw />}
                aria-label="Refresh"
                onClick={handleRefresh}
                isLoading={refreshing}
                size={{ base: "xs", md: "sm" }}
              />
            </Flex>

            {role !== "staff" && (
              <>
                <Button
                  display={{ base: "none", md: "flex" }} 
                  leftIcon={<AddIcon />}
                  bg="black"
                  color={"white"}
                  _hover={{
                    color: "black",
                    bg: "white",
                    borderWidth: "1px",
                    borderColor: "gray.300",
                  }}
                  variant="outline"
                  onClick={handleCreate}
                  ml={4}
                  size={"sm"}
                >
                  Create Customization
                </Button>
                <IconButton
                  display={{ base: "flex", md: "none" }} 
                  icon={<AddIcon />}
                  aria-label="Create Customization"
                  bg="black"
                  color={"white"}
                  _hover={{
                    color: "black",
                    bg: "white",
                    borderWidth: "1px",
                    borderColor: "gray.300",
                  }}
                  onClick={handleCreate}
                  ml={2}
                />
              </>
            )}
          </Flex>
        </Flex>
        <SearchBar
          items={customizations.map(
            (customization) => customization.customization
          )}
          onSearch={(query) =>
            setFilteredCustomizations(
              customizations.filter((customization) =>
                customization.customization
                  .toLowerCase()
                  .includes(query.toLowerCase())
              )
            )
          }
          placeholder="Search customizations..."
          variant="filled"
          borderRadius="full"
          width="100%"
        />
        <React.Fragment>
          {filteredCustomizations.map((customization, index) => (
            <Box
              as="button"
              key={index}
              p={2}
              align="center"
              justify="space-between"
              w="full"
              bg={bgColor}
              borderRadius="md"
              _hover={{ bg: hoverBg }}
              transition="background-color 0.3s ease-in-out"
              onClick={() => handleItemClick(customization)} // Pass customization object
            >
              <Flex
                justify={"space-between"}
                align={"center"}
                width={"full"}
                px={4}
              >
                <VStack align="flex-start" justify={"flex-start"}>
                  <Heading size="md">{customization.customization}</Heading>
                  <Text fontSize="sm">
                    {customization.option.length} options
                  </Text>
                </VStack>
                <Icon as={ChevronRightIcon} />
              </Flex>
            </Box>
          ))}
        </React.Fragment>
      </VStack>
      {isOpen && customizationData ? (
        <CustomizationSideBox
          customizationData={customizationData}
          handleInputChange={handleInputChange}
          handleDelete={handleDelete}
          handleDuplicate={handleDuplicate}
          handleSeeChanges={handleSeeChanges}
          onClose={onClose}
          isOpen={isOpen}
          setCustomizationData={setCustomizationData}
          handleEdit={handleEdit}
          handleSubmit={handleSubmit}
          isNewCustomization={selectedItem === null} 
          selectedItem={selectedItem}
          handleRefresh={handleRefresh}
        />
      ) : (
        <Box
          width={"25%"}
          position={"fixed"}
          right={4}
          h={`calc(100vh - 120px)`}
          maxWidth={"inherit"}
          overflowY={"auto"}
          borderLeft="1px solid"
          borderColor={borderColor}
        >
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Text>Select an item to edit details</Text>
          </Flex>
        </Box>
      )}
    </Flex>
  );
}

export default Customizations;
