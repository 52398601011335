"use client";

import axios from "axios";
import imageCompression from "browser-image-compression";

const baseURL = process.env.REACT_APP_ADMIN_URL;
export const mediaUploadURL = "https://kiwikiosk.nyc3.digitaloceanspaces.com/media/";

export const getCompressedFile = async (file) => {
    const compressionOptions = {
        maxWidthOrHeight: file.width,
        useWebWorker: true
    };

    try {
        const compressedFile = await imageCompression(file, compressionOptions);

        // Get the original file name without the extension
        const originalFileName = file.name.substring(0, file.name.lastIndexOf('.')) || file.name;

        // Generate a unique name by appending a timestamp
        const uniqueFileName = `${originalFileName}-${Date.now()}.jpg`; // Change extension if necessary

        // Create a new File object with the unique name
        return new File(
            [compressedFile],
            uniqueFileName,
            {type: compressedFile.type}
        );

    } catch (error) {
        console.error("Compression error:", error);
        throw error;
    }
};

const convertToWebP = async (file) => {
    const originalFileName = file.name.substring(0, file.name.lastIndexOf('.')) || file.name;

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                canvas.toBlob(
                    (blob) => {
                        if (blob) {
                            resolve(new File([blob], `${originalFileName}.webp`, { type: 'image/webp' }));
                        } else {
                            reject(new Error("Failed to create WebP blob."));
                        }
                    },
                    'image/webp',
                    0.95 // Use a higher quality setting
                );
            };

            img.onerror = (err) => reject(err);
        };

        reader.onerror = (err) => reject(err);
    });
};

const API = axios.create({
    baseURL: baseURL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
    },
});

// Helper function to get the full media URL
export const getMediaURL = (path) => {
    //return path ? `${mediaUploadURL}${path}` : null;
    return "https://nyc3.digitaloceanspaces.com/kiwikiosk/media/" + path;
};

export default API;
