import React, { useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useBreakpointValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import API from "../../context/API";

export const MerchantBox = ({
  initialMerchant,
  onSave,
  onCancel,
  fetchMerchants,
  isManagerMode = false, // Add a prop to determine the mode
  isStaffMode = false, // Add a prop to determine the mode
}) => {
  const [merchant, setMerchant] = useState({
    name: "",
    username: "",
    email: "",
    address: "",
    phone: "",
    password: "",
    ...initialMerchant, // Spread the initialMerchant to override default values if editing
  });

  const [errors, setErrors] = useState({});
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const toast = useToast();

  const isEditMode = !!initialMerchant; // Check if we are in edit mode based on the presence of initialMerchant

  const validate = () => {
    const newErrors = {};
    if (!merchant.name) newErrors.name = "Name is required";
    if (!merchant.username) newErrors.username = "Username is required";
    if (!merchant.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(merchant.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!merchant.phone) newErrors.phone = "Phone number is required";
    if (!merchant.address) newErrors.address = "Address is required";
    if (!isEditMode && !merchant.password)
      newErrors.password = "Password is required"; // Password required only for new merchants
    return newErrors;
  };

  const handleSaveClick = async () => {
    const newErrors = validate();
    if (Object.keys(newErrors).length === 0) {
      try {
        const url = isManagerMode
          ? "manager"
          : isStaffMode
          ? "staff"
          : "merchant";

        if (isEditMode) {
          const updatedMerchantResponse = await API.put(
            `${url}/${merchant.id}`,
            merchant,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: false,
            }
          );
          if (updatedMerchantResponse.data.status === 200) {
            toast({
              title: "Success.",
              description: `${
                isManagerMode
                  ? "Merchant Manager"
                  : isStaffMode
                  ? "Staff"
                  : "Merchant"
              } updated successfully.`,
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Error.",
              description: updatedMerchantResponse.data.message,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          }
        } else {
          const newMerchantResponse = await API.post(url, merchant, {
            headers: { "Content-Type": "application/json" },
            withCredentials: false,
          });
          if (newMerchantResponse.data.status === 200) {
            toast({
              title: "Success.",
              description: `${
                isManagerMode
                  ? "Merchant Manager"
                  : isStaffMode
                  ? "Staff"
                  : "Merchant"
              } updated successfully.`,
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Error.",
              description: newMerchantResponse.data.message,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          }
        }
        fetchMerchants();
        onSave();
      } catch (error) {
        toast({
          title: "Error.",
          description: error.response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } else {
      setErrors(newErrors);
    }
  };

  const handleCancelClick = () => {
    if (isUnsavedChanges) {
      onOpen();
    } else {
      onCancel();
    }
  };

  const confirmCancel = () => {
    setIsUnsavedChanges(false);
    onCancel();
    onClose();
  };

  const handleChange = (field, value) => {
    setMerchant((prevMerchant) => ({ ...prevMerchant, [field]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
    setIsUnsavedChanges(true);
  };

  const addonSize = useBreakpointValue({ base: "sm", md: "md" });

  return (
    <>
      <VStack spacing={4} align="stretch" mt={4}>
        <Flex justify="space-between">
          <Button
            leftIcon={<ArrowBackIcon />}
            variant="outline"
            onClick={handleCancelClick}
          >
            Back
          </Button>
          <Flex>
            <Button
              colorScheme="red"
              variant="outline"
              mr={3}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              bg="black"
              color={"white"}
              _hover={{
                color: "black",
                bg: "white",
                borderWidth: "1px",
                borderColor: "gray.300",
              }}
              variant="outline"
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </Flex>
        </Flex>
        <FormControl id="merchant-name" isInvalid={errors.name}>
          <FormLabel>Name</FormLabel>
          <Input
            value={merchant.name}
            variant={"filled"}
            onChange={(e) => handleChange("name", e.target.value)}
          />
          <FormErrorMessage>{errors.name}</FormErrorMessage>
        </FormControl>
        <FormControl id="merchant-username" isInvalid={errors.username}>
          <FormLabel>Username</FormLabel>
          <Input
            value={merchant.username}
            variant={"filled"}
            onChange={(e) => handleChange("username", e.target.value)}
          />
          <FormErrorMessage>{errors.username}</FormErrorMessage>
        </FormControl>
        <FormControl id="merchant-email" isInvalid={errors.email}>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            value={merchant.email}
            variant={"filled"}
            onChange={(e) => handleChange("email", e.target.value)}
          />
          <FormErrorMessage>{errors.email}</FormErrorMessage>
        </FormControl>
        <FormControl id="merchant-phone" isInvalid={errors.phone}>
          <FormLabel>Phone</FormLabel>
          <Input
            value={merchant.phone}
            variant={"filled"}
            onChange={(e) => handleChange("phone", e.target.value)}
          />
          <FormErrorMessage>{errors.phone}</FormErrorMessage>
        </FormControl>
        <FormControl id="merchant-address" isInvalid={errors.address}>
          <FormLabel>Address</FormLabel>
          <Input
            value={merchant.address}
            variant={"filled"}
            onChange={(e) => handleChange("address", e.target.value)}
          />
          <FormErrorMessage>{errors.address}</FormErrorMessage>
        </FormControl>
        {!isEditMode && (
          <FormControl id="merchant-password" isInvalid={errors.password}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={merchant.password}
              variant={"filled"}
              onChange={(e) => handleChange("password", e.target.value)}
            />
            <FormErrorMessage>{errors.password}</FormErrorMessage>
          </FormControl>
        )}
      </VStack>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Unsaved Changes
            </AlertDialogHeader>

            <AlertDialogBody>
              You have unsaved changes. Are you sure you want to cancel?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                No
              </Button>
              <Button colorScheme="red" onClick={confirmCancel} ml={3}>
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
