import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Flex,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    Link,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorMode,
    useToast,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
//import { MerchantBox } from "./MerchantBox";
import API from "../../context/API";
import { useLoading } from "../../context/LoadingContext";
import { LoadingAnimation } from "../LoadingAnimation";
import { MerchantBox } from "../Merchants/MerchantBox";

const Staff = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [currentMerchantStaff, setCurrentMerchantStaff] = useState(null);
    const [merchantStaff, setMerchantStaffs] = useState([]);
    const [filteredMerchantStaffs, setFilteredMerchantStaffs] = useState([]);
    const toast = useToast();
    const { isLoading, setLoading } = useLoading();
    const { colorMode } = useColorMode();

    async function fetchMerchantStaffs() {
        try {
            const merchantStaffResponse = await API.get("staff/all", {
                headers: { "Content-Type": "application/json" },
                withCredentials: false,
            });
            const merchantStaffData = merchantStaffResponse.data.data;
            setMerchantStaffs(merchantStaffData);
            setFilteredMerchantStaffs(merchantStaffData);
            setLoading(false);
        } catch (error) {
            toast({
                title: "Error fetching merchant staff.",
                description: error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    }

    useEffect(() => {
        setLoading(true);
        fetchMerchantStaffs();
        return () => {
            setMerchantStaffs([]);
        };
    }, []);

    const handleAddClick = () => {
        setCurrentMerchantStaff(null);
        setIsEditing(true);
    };

    const handleItemClick = (itemId) => {
        const merchantManagerToEdit = merchantStaff.find((item) => item.id === itemId);
        setCurrentMerchantStaff(merchantManagerToEdit);
        setIsEditing(true);
    };

    const handleDeleteClick = async (itemId) => {
        try {
            const deleteMerchantStaffResponse = await API.delete(`staff/${itemId}`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: false,
            });
            if (deleteMerchantStaffResponse.data.status === 200) {
                toast({
                    title: "Success.",
                    description: "Merchant staff deleted successfully.",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
                const updatedMerchantStaffs = merchantStaff.filter((item) => item.id !== itemId);
                setMerchantStaffs(updatedMerchantStaffs);
                setFilteredMerchantStaffs(updatedMerchantStaffs);
            } else {
                toast({
                    title: "Error.",
                    description: deleteMerchantStaffResponse.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Error.",
                description: error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleSearch = (event) => {
        const filter = event.target.value.toLowerCase();
        if (!filter) {
            setFilteredMerchantStaffs(merchantStaff);
            return;
        }
        const filtered = merchantStaff.filter(
            (item) =>
                item.name.toLowerCase().includes(filter) ||
                item.username.toLowerCase().includes(filter) ||
                item.email.toLowerCase().includes(filter)
        );
        setFilteredMerchantStaffs(filtered);
    };

    if (isLoading) {
        return (
            <>
                <LoadingAnimation size="contentArea" />
            </>
        );
    }

    return (
        <>
            {!isEditing ? (
                <>
                    <Box>
                        <Flex
                            justify={"space-between"}
                            alignItems={"center"}
                            width={"100%"}
                            flexWrap={"wrap"}
                            mt={4}
                        >
                            <Box>
                                <Heading size={"xl"} letterSpacing={-2}>
                                    All staff
                                </Heading>
                            </Box>
                            <Box>
                                <Button
                                    leftIcon={<IoMdAdd />}
                                    bg="black"
                                    color={"white"}
                                    _hover={{
                                        color: "black",
                                        bg: "white",
                                        borderWidth: "1px",
                                        borderColor: "gray.300",
                                    }}
                                    variant="outline"
                                    onClick={handleAddClick}
                                >
                                    Add Merchant staff
                                </Button>
                            </Box>
                        </Flex>
                        <Box m={4}>
                            <InputGroup size={"md"}>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<SearchIcon color="gray.300" />}
                                />
                                <Input
                                    type="search"
                                    placeholder="Search..."
                                    _placeholder={{ color: "gray.500" }}
                                    onKeyUp={handleSearch}
                                />
                            </InputGroup>
                        </Box>
                    </Box>
                    <TableContainer>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Username</Th>
                                    <Th>Email</Th>
                                    <Th>Phone</Th>
                                    <Th>Address</Th>
                                    <Th>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {filteredMerchantStaffs.map((item) => (
                                    <Tr key={item.id}>
                                        <Td>
                                            <Link
                                                color={colorMode === 'light' ? "green" : "lightgreen"}
                                                onClick={() => handleItemClick(item.id)}
                                            >
                                                {item.name}
                                            </Link>
                                        </Td>
                                        <Td>{item.username}</Td>
                                        <Td>{item.email}</Td>
                                        <Td>{item.phone}</Td>
                                        <Td>{item.address}</Td>
                                        <Td>
                                            <Button
                                                variant="ghost"
                                                colorScheme="red"
                                                onClick={() => handleDeleteClick(item.id)}
                                            >
                                                <MdDelete />
                                            </Button>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <MerchantBox
                    initialMerchant={currentMerchantStaff}
                    onSave={() => {
                        setIsEditing(false);
                        fetchMerchantStaffs();
                    }}
                    onCancel={() => setIsEditing(false)}
                    fetchMerchants={fetchMerchantStaffs}
                    isStaffMode={true} // Pass the prop to indicate manager mode
                />
            )}
        </>
    );
};

export default Staff;
