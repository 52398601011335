import {
    Icon,
    IconButton,
    Popover,
    PopoverArrow,
    PopoverContent,
    PopoverTrigger,
    Text,
    useColorMode,
    useDisclosure,
} from "@chakra-ui/react";
import {MdDarkMode, MdLightMode} from "react-icons/md";

export const ToggleColorModeButton = ({iconButtonProps, width, height}) => {
    const {colorMode, toggleColorMode} = useColorMode();
    const {isOpen, onOpen, onClose} = useDisclosure();

    const label =
        colorMode === "light" ? "Switch to dark mode" : "Switch to light mode";
    const IconComponent = colorMode === "light" ? MdDarkMode : MdLightMode;

    return (
        <Popover isOpen={isOpen} onClose={onClose} placement="auto">
            <PopoverTrigger>
                <IconButton
                    isRound
                    aria-label={label}
                    icon={<Icon as={IconComponent} w={width} h={height}/>}
                    onClick={toggleColorMode}
                    onMouseEnter={onOpen}
                    onMouseLeave={onClose}
                    {...iconButtonProps} 
                />
            </PopoverTrigger>
            <PopoverContent
                bg={colorMode === "light" ? "white" : "navy.900"} 
                borderRadius="md" 
                boxShadow="xl" 
                p="12px" 
                animation="ease-in-out" 
                w={"auto"}
                minWidth="max-content"
            >
                <PopoverArrow/>
                <Text>{label}</Text> {/* Display the label text inside the Popover */}
            </PopoverContent>
        </Popover>
    );
};
