// SalesDetailedView.js
import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Text,
  Icon,
  Flex,
  Spinner,
  VStack,
  HStack,
  SimpleGrid,
  useColorModeValue,
  Button,
  Heading,
  Collapse,
  useDisclosure,
  SlideFade,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  TableContainer,
  useBreakpointValue,
  IconButton,
} from "@chakra-ui/react";
import {
  FiTrendingUp,
  FiTrendingDown,
  FiAlertCircle,
  FiArrowLeft,
  FiUsers,
  FiShoppingCart,
  FiArrowUp,
  FiArrowDown,
  FiCheckSquare,
  FiGift,
  FiPercent 
} from "react-icons/fi";
import "../../theme/Layout/ChartConfig.js"; // Ensure correct path and extension
import { Bar } from "react-chartjs-2";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../../context/API.js";
import DateRangePicker from "./DateRangePicker.js";
import DateSelector from "./DateSelector.js";
import ChartSelection from "./ChartSelection.js";
import BarLineChart from "./BarLineChart.js";
import moment from "moment";

// Reusable InfoTile Component
const InfoTile = ({ title, subTitle, value, icon }) => {
  const { isOpen, onToggle } = useDisclosure();

  // Chakra UI color modes
  const tileBg = useColorModeValue("gray.100", "gray.700");
  const tileHoverBg = useColorModeValue("gray.200", "gray.600");
  const textColor = useColorModeValue("gray.800", "white");
  const subTextColor = useColorModeValue("gray.600", "gray.500");
  const metricBorderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <SlideFade in={true} offsetY="20px" transition={{ duration: 0.5 }}>
      <Box
        bg={tileBg}
        borderRadius="md"
        p={6}
        cursor="pointer"
        _hover={{ bg: tileHoverBg }}
        transition="background 0.3s"
        onClick={onToggle}
      >
        <Flex align="center" justifyContent="space-between">
          <HStack spacing={3}>
            <Icon as={icon} w={8} h={8} color={textColor} />
            <VStack align="start" spacing={0}>
              <Text fontSize="xl" fontWeight="bold" color={textColor}>
                {title}
              </Text>
              <Text fontSize="sm" color={subTextColor}>
                {subTitle}
              </Text>
            </VStack>
          </HStack>
          <Text fontSize="3xl" fontWeight="bold" color={textColor}>
            {value}
          </Text>
        </Flex>
        <Collapse in={isOpen} animateOpacity>
          <Box
            mt={4}
            p={4}
            bg={useColorModeValue("gray.50", "gray.600")}
            borderRadius="md"
          >
            <Text fontSize="sm" color={subTextColor}>
              {/* Replace this with dynamic content as needed */}
              Detailed information about {title.toLowerCase()} goes here.
            </Text>
          </Box>
        </Collapse>
      </Box>
    </SlideFade>
  );
};

// Reusable SalesSummary Component
const SalesSummary = ({ totalSales, monthlyChange }) => {
  // Determine the trend direction and color
  const isIncrease = monthlyChange >= 0;
  const trendIcon = isIncrease ? FiArrowUp : FiArrowDown;
  const trendColor = isIncrease ? "green.400" : "red.400";

  // Chakra UI color modes
  const textColor = useColorModeValue("gray.800", "white");
  const subTextColor = useColorModeValue("gray.600", "gray.500");

  return (
    <Box
      p={6}
      bg={useColorModeValue("gray.100", "gray.700")}
      borderRadius="md"
      boxShadow="md"
      mb={6}
    >
      <VStack align="start" spacing={0}>
        <Text fontSize="xl" color={textColor}>
          Total Sales
        </Text>
        <Text fontSize="sm" color={subTextColor} mb={"4"}>
          Detailed insights into your sales performance.
        </Text>
        <HStack>
          <Text fontSize="4xl" fontWeight="bold" color={textColor}>
            $
            {totalSales.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
          <Flex align="center">
            <Icon as={trendIcon} color={trendColor} w={5} h={5} />
            <Text ml={1} fontSize="md" color={trendColor} fontWeight="semibold">
              {Math.abs(monthlyChange)}% {isIncrease ? "↑" : "↓"} this month
            </Text>
          </Flex>
        </HStack>
      </VStack>
    </Box>
  );
};

// Reusable SalesRankingTable Component
const SalesRankingTable = ({ data }) => {
  // Chakra UI color modes
  const bgColor = useColorModeValue("white", "gray.800");
  const tableBg = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("gray.800", "white");
  const subTextColor = useColorModeValue("gray.600", "gray.500");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Box
      p={4}
      //bg={bgColor}
      borderRadius="md"
      //boxShadow="md"
      mt={6}
      pl={"0"}
      w={"full"}
    >
      <VStack align="start" spacing={4}>
        <Box>
          <Text fontSize="2xl" fontWeight="bold">
            Sales Ranking
          </Text>
          <Text fontSize="sm" color={subTextColor}>
            Items ranked by the number of units sold.
          </Text>
        </Box>
        <TableContainer w={"full"}>
          <Table variant="simple" w={"full"}>
            <Thead>
              <Tr>
                <Th color={textColor}>Rank</Th>
                <Th color={textColor}>Item</Th>
                <Th color={textColor} isNumeric>
                  Units Sold
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item, index) => (
                <Tr key={item.id} bg={tableBg} borderBottomColor={borderColor}>
                  <Td
                    color={textColor}
                    fontWeight={index === 0 ? "bold" : "normal"}
                  >
                    {index + 1}
                  </Td>
                  <Td
                    color={textColor}
                    fontWeight={index === 0 ? "bold" : "normal"}
                  >
                    {item.name}
                  </Td>
                  <Td
                    color={textColor}
                    isNumeric
                    fontWeight={index === 0 ? "bold" : "normal"}
                  >
                    {item.unitsSold}
                  </Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th color={textColor}>Rank</Th>
                <Th color={textColor}>Item</Th>
                <Th color={textColor} isNumeric>
                  Units Sold
                </Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </VStack>
    </Box>
  );
};

// Sales Detailed View Component
const SalesDetailedView = () => {
  const [salesData, setSalesData] = useState(null);
  const [loadingSales, setLoadingSales] = useState(true);
  const [totalSales, setTotalSales] = useState(0);
  const [monthlyChange, setMonthlyChange] = useState(0);
  const [keyMetrics, setKeyMetrics] = useState([]);
  const [salesRankingData, setSalesRankingData] = useState([]);

  const [salesAllData, setSalesAllData] = useState(null);
  const [isFilter, setIsFilter] = useState(false);

  const navigate = useNavigate(); // Initialize useNavigate
  const location = useLocation();
  const { data, itemRankingData } = location.state || {};

  // Chakra UI color modes
  const textColor = useColorModeValue("gray.800", "white");
  const subTextColor = useColorModeValue("gray.600", "gray.500");
  const metricBorderColor = useColorModeValue("gray.300", "gray.600");
  const chartbg = useColorModeValue("gray.100", "gray.700");
  const [chartType, setChartType] = useState("line");
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: moment().subtract(1, "month").startOf("month"),
    endDate: moment().subtract(1, "month").endOf("month") 
  });
  const [selectedFilter, setSelectedFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const barColor = useColorModeValue("black", "lightgrey");

  const fetchData = async () => {
    const token = localStorage.getItem("access_token");
    const params1 = {
      filter: selectedFilter,
    };
    const params2 = {
      startDate: selectedDateRange.startDate?.format("YYYY-MM-DD"),
      endDate: selectedDateRange.endDate?.format("YYYY-MM-DD"),
    };
    try {
      setIsLoading(true)
      const res = await API.get("/analytics/sales", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: isFilter ? params1 : params2,
      });
      console.log("response data sales", res);
      const transformedMonthlyData = Object.keys(res.data.data.salesData).map(
        (date) => ({
          date, // This becomes the X-axis label
          sales: parseFloat(res.data.data.salesData[date]).toFixed(2), // This is the Y-axis value
          yLabels:res.data.data.salesData[date]
        })
      );
      setSalesAllData(res.data.data);
      setSalesData(transformedMonthlyData);
      setLoadingSales(false)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log("sales data error", e);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedFilter, selectedDateRange]);

  // Fetch sales data for the past 5 months
  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const salesDataByMonth = [];
        const promises = [];

        const today = new Date();
        const monthsToFetch = 5;

        for (let i = monthsToFetch - 1; i >= 0; i--) {
          const monthDate = new Date(
            today.getFullYear(),
            today.getMonth() - i,
            1
          );
          const startDate = new Date(
            monthDate.getFullYear(),
            monthDate.getMonth(),
            1
          );
          const endDate = new Date(
            monthDate.getFullYear(),
            monthDate.getMonth() + 1,
            0
          );

          // Format dates as YYYY-MM-DD
          const startDateStr = startDate.toISOString().split("T")[0];
          const endDateStr = endDate.toISOString().split("T")[0];

          // Prepare API call
          const promise = API.get("/analytics/sales", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              startDate: startDateStr,
              endDate: endDateStr,
            },
          });

          promises.push(promise);
        }

        // Execute all API calls in parallel
        const responses = await Promise.all(promises);

        responses.forEach((response) => {
          const netSales = parseFloat(response.data.data.netSales);
          salesDataByMonth.push(netSales);
        });
        // Calculate total sales
        const total = salesDataByMonth.reduce((acc, curr) => acc + curr, 0);
        setTotalSales(total);

        // Calculate monthly change
        if (salesDataByMonth.length >= 2) {
          const lastMonthSales = salesDataByMonth[salesDataByMonth.length - 1];
          const prevMonthSales = salesDataByMonth[salesDataByMonth.length - 2];
          const change =
            prevMonthSales !== 0
              ? (
                  ((lastMonthSales - prevMonthSales) / prevMonthSales) *
                  100
                ).toFixed(2)
              : 0;
          setMonthlyChange(change);
        } else {
          setMonthlyChange(0);
        }
      } catch (error) {
        console.error("Error fetching sales data:", error);
      } finally {
        setLoadingSales(false);
      }
    };

    fetchSalesData();
  }, [barColor]);
const user = JSON.parse(localStorage.getItem("user"))
  // Set key metrics from the data passed via location.state
  useEffect(() => {
    
    if (data) {
      setKeyMetrics([
        {
          id: "netSales",
          title: "Net Sales",
          subTitle: "Total net revenue",
          value: `$${parseFloat(data.netSales).toFixed(2)}`,
          icon: FiTrendingUp,
          color: "green.500",
        },
        {
          id: "grossSales",
          title: "Gross Sales",
          subTitle: "Total gross revenue",
          value: `$${parseFloat(data.grossSales).toFixed(2)}`,
          icon: FiTrendingDown,
          color: "red.500",
        },
        {
          id: "refunds",
          title: "Refunds",
          subTitle: "Total refunds issued",
          value: `$${parseFloat(data.refunds).toFixed(2)}`,
          icon: FiAlertCircle,
          color: "purple.500",
        },
        {
          id: "tips",
          title: "Tips",
          subTitle: "Total Tips",
          value: `$${parseFloat(data.tips).toFixed(2)}`,
          icon: FiGift,
          color: "purple.500",
        },
        {
          id: "tax",
          title: "Tax",
          subTitle: "Total Tax",
          value: `$${parseFloat(data.netSales * user?.tax).toFixed(2)}`,
          icon: FiPercent,
          color: "blue.500",
        },
      ]);

      // Set total sales from data
      setTotalSales(parseFloat(data.netSales));
    }
  }, [data]);

  // Set sales ranking data from itemRankingData
  useEffect(() => {
    if (itemRankingData && itemRankingData.items) {
      // Sort items by timesSold in descending order
      const sortedItems = [...itemRankingData.items].sort(
        (a, b) => b.timesSold - a.timesSold
      );
      // Map to expected format
      const rankingData = sortedItems.map((item, index) => ({
        id: index + 1,
        name: item.item,
        unitsSold: item.timesSold,
      }));
      setSalesRankingData(rankingData);
    }
  }, [itemRankingData]);
  const handleSelection = (selection) => {
    setSelectedFilter(selection);
    setIsFilter(true);
  };
  return (
    <Box p={5} color={textColor}>
      {loadingSales ? (
        <Flex justify="center" align="center" minH="100vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <VStack spacing={6} align="stretch">
          {/* Heading with Back Button */}
          <Flex justify="flex-start" align="center">
            <IconButton
              icon={<FiArrowLeft />}
              variant="outline"
              color={textColor}
              borderRadius={"full"}
              onClick={() => navigate(-1)} // Navigate back in history
              //borderColor="black"
              _hover={{ bg: "gray.200" }}
              aria-label="Go Back to Analytics Dashboard"
              mr={"4"}
              size="lg"
            ></IconButton>
            <VStack align="start" spacing={1}>
              <Heading size="xl" fontWeight="bold">
                Sales
              </Heading>
              <Text fontSize="md" color={subTextColor}>
                A summary of your revenue and sales by channel.
              </Text>
            </VStack>
            {/* Placeholder to balance the flex layout */}
            <Box width="75px" />
          </Flex>

          {/* Sales Summary Header */}
          <SalesSummary totalSales={totalSales} monthlyChange={monthlyChange} />

          {/* Sales Chart */}
          <Box
            // p={4}
            borderRadius="md"
            height="450px"
            width={"full"}
            bg={chartbg}
            boxShadow="md"
          >
            <VStack>
              <HStack padding={4} width={"100%"} justifyContent={"flex-start"} wrap={"wrap"}>
                <DateRangePicker
                  onDateRangeChange={(range) => {
                    setIsFilter(false);
                    setSelectedDateRange(range);
                  }}
                />
                <DateSelector onDateSelect={handleSelection} />
                <ChartSelection onChange={(type) => setChartType(type)} />
              </HStack>
              <HStack
                paddingX={4}
                width={"100%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={10}
                wrap={"wrap"}
              >
                <Text display={"flex"} alignItems={"center"}>
                  <Icon
                    as={FiTrendingUp}
                    w={5}
                    h={5}
                    color={"green.500"}
                    marginRight={"4px"}
                  />
                  Net Sales :<Text as={"span"}>{salesAllData?.netSales}</Text>
                </Text>
                <Text display={"flex"} alignItems={"center"}>
                  <Icon
                    as={FiTrendingDown}
                    w={5}
                    h={5}
                    color={"red.500"}
                    marginRight={"4px"}
                  />
                  Gross Sales :
                  <Text as={"span"}>{salesAllData?.grossSales}</Text>
                </Text>
                <Text display={"flex"} alignItems={"center"}>
                  <Icon
                    as={FiAlertCircle}
                    w={5}
                    h={5}
                    color={"purple.500"}
                    marginRight={"4px"}
                  />
                  Refunds :<Text as={"span"}>{salesAllData?.refunds}</Text>
                </Text>
                <Text display={"flex"} alignItems={"center"}>
                  <Icon
                    as={FiCheckSquare}
                    w={5}
                    h={5}
                    color={"green.500"}
                    marginRight={"4px"}
                  />
                  Order Count :
                  <Text as={"span"}>{salesAllData?.orderCount}</Text>
                </Text>
                <Text display={"flex"} alignItems={"center"}>
                  <Icon
                    as={FiGift}
                    w={5}
                    h={5}
                    color={"purple.500"}
                    marginRight={"4px"}
                  />
                  Tips :
                  <Text as={"span"}>{salesAllData?.tips}</Text>
                </Text>
                <Text display={"flex"} alignItems={"center"}>
                  <Icon
                    as={FiPercent}
                    w={5}
                    h={5}
                    color={"blue.500"}
                    marginRight={"4px"}
                  />
                  Total Tax :
                  <Text as={"span"}>{parseFloat(salesAllData?.netSales * user?.tax).toFixed(2)}</Text>
                </Text>
              </HStack>
              <Box width={"100%"} height={"380px"}>
                <BarLineChart
                  data={salesData}
                  chartType={chartType}
                  xKey={"date"}
                  yKey={"yLabels"}
                  valueKey={"sales"}
                  isLoading={isLoading}
                />
              </Box>
            </VStack>
          </Box>

          {/* Key Metrics Tiles */}
          <Box>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
              Key Metrics
            </Text>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
              {keyMetrics.map((metric) => (
                <Box
                  key={metric.id}
                  borderWidth="1px"
                  borderColor={metricBorderColor}
                  borderRadius="md"
                  p={4}
                  // Removed boxShadow and bg color
                >
                  <HStack spacing={3}>
                    <Icon as={metric.icon} w={6} h={6} color={metric.color} />
                    <Text fontWeight="bold">{metric.title}</Text>
                  </HStack>
                  <Text fontSize="xl" fontWeight="bold" mt={2}>
                    {metric.value}
                  </Text>
                </Box>
              ))}
            </SimpleGrid>
          </Box>

          {/* Additional Metrics: Total Users and Total Orders */}
          <Box>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
              Additional Metrics
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
              {/* Total Users Tile */}
              <InfoTile
                title="Total Users"
                subTitle="Number of active users"
                value="0"
                icon={FiUsers}
              />

              {/* Total Orders Tile */}
              <InfoTile
                title="Total Orders"
                subTitle="Number of orders placed"
                value="0"
                icon={FiShoppingCart}
              />
            </SimpleGrid>
          </Box>

          {/* Sales Ranking Table */}
          <SalesRankingTable data={salesRankingData} />
        </VStack>
      )}
    </Box>
  );
};

export default SalesDetailedView;
