import React, {useEffect, useRef, useState} from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Select,
    useBreakpointValue,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import {ArrowBackIcon} from "@chakra-ui/icons";
import API from "../../context/API";

export const DeviceBox = ({initialDevice, onSave, onCancel, fetchDevices}) => {
    const [device, setDevice] = useState({
        name: "",
        identifier: "",
        type: "",
        merchant: "",
        ...initialDevice,
    });

    const [deviceTypes, setDeviceTypes] = useState([]);
    const [merchants, setMerchants] = useState([]);
    const [errors, setErrors] = useState({});
    const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const cancelRef = useRef();
    const toast = useToast();

    const isEditMode = !!initialDevice;

    useEffect(() => {
        async function fetchDeviceTypesAndMerchants() {
            try {
                const [deviceTypesResponse, merchantsResponse] = await Promise.all([
                    API.get("device-types", {
                        headers: {"Content-Type": "application/json"},
                        withCredentials: false,
                    }),
                    API.get("merchant", {
                        headers: {"Content-Type": "application/json"},
                        withCredentials: false,
                    }),
                ]);
                setDeviceTypes(deviceTypesResponse.data.data);
                setMerchants(merchantsResponse.data.data);

                if (isEditMode) {
                    setDevice((prevDevice) => ({
                        ...prevDevice,
                        type: initialDevice.type.id,
                        merchant: initialDevice.merchant ? initialDevice.merchant.id : "",
                    }));
                }
            } catch (error) {
                toast({
                    title: "Error fetching data.",
                    description: error.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        }

        fetchDeviceTypesAndMerchants();
    }, [toast, isEditMode, initialDevice]);

    const validate = () => {
        const newErrors = {};
        if (!device.name) newErrors.name = "Name is required";
        if (!device.identifier) newErrors.identifier = "Identifier is required";
        if (!device.type) newErrors.type = "Device type is required";
        return newErrors;
    };

    const handleSaveClick = async () => {
        const newErrors = validate();
        if (Object.keys(newErrors).length === 0) {
            try {
                if (isEditMode) {
                    const updatedDeviceResponse = await API.put(`devices/${device.id}`, device, {
                        headers: {"Content-Type": "application/json"},
                        withCredentials: false,
                    });
                    if (updatedDeviceResponse.data.status === 200) {
                        toast({
                            title: "Success.",
                            description: "Device updated successfully.",
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                        });
                    } else {
                        toast({
                            title: "Error.",
                            description: updatedDeviceResponse.data.message,
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                        });
                    }
                } else {
                    const newDeviceResponse = await API.post("devices", device, {
                        headers: {"Content-Type": "application/json"},
                        withCredentials: false,
                    });
                    if (newDeviceResponse.data.status === 200) {
                        toast({
                            title: "Success.",
                            description: "Device added successfully.",
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                        });
                    } else {
                        toast({
                            title: "Error.",
                            description: newDeviceResponse.data.message,
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                        });
                    }
                }
                fetchDevices();
                onSave();
            } catch (error) {
                toast({
                    title: "Error.",
                    description: error.response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } else {
            setErrors(newErrors);
        }
    };

    const handleCancelClick = () => {
        if (isUnsavedChanges) {
            onOpen();
        } else {
            onCancel();
        }
    };

    const confirmCancel = () => {
        setIsUnsavedChanges(false);
        onCancel();
        onClose();
    };

    const handleChange = (field, value) => {
        setDevice((prevDevice) => ({...prevDevice, [field]: value}));
        setErrors((prevErrors) => ({...prevErrors, [field]: null}));
        setIsUnsavedChanges(true);
    };

    const addonSize = useBreakpointValue({base: "sm", md: "md"});

    return (
        <>
            <VStack spacing={4} align="stretch">
                <Flex justify="space-between">
                    <Button leftIcon={<ArrowBackIcon/>} variant="outline" onClick={handleCancelClick}>
                        Back
                    </Button>
                    <Flex>
                        <Button colorScheme="red" variant="outline" mr={3} onClick={handleCancelClick}>
                            Cancel
                        </Button>
                        <Button
                            bg="black"
                            color={"white"}
                            _hover={{
                                color: "black",
                                bg: "white",
                                borderWidth: "1px",
                                borderColor: "gray.300",
                            }}
                            variant="outline"
                            onClick={handleSaveClick}
                        >
                            Save
                        </Button>
                    </Flex>
                </Flex>
                <FormControl id="device-name" isInvalid={errors.name}>
                    <FormLabel>Name</FormLabel>
                    <Input value={device.name} variant={"filled"}
                           onChange={(e) => handleChange("name", e.target.value)}/>
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
                <FormControl id="device-identifier" isInvalid={errors.identifier}>
                    <FormLabel>Identifier</FormLabel>
                    <Input
                        value={device.identifier}
                        variant={"filled"}
                        onChange={(e) => handleChange("identifier", e.target.value)}
                    />
                    <FormErrorMessage>{errors.identifier}</FormErrorMessage>
                </FormControl>
                <FormControl id="device-type" isInvalid={errors.type}>
                    <FormLabel>Device Type</FormLabel>
                    <Select
                        placeholder="Select device type"
                        value={device.type}
                        variant={"filled"}
                        onChange={(e) => handleChange("type", e.target.value)}
                    >
                        {deviceTypes.map((type) => (
                            <option key={type.id} value={type.id}>
                                {type.name}
                            </option>
                        ))}
                    </Select>
                    <FormErrorMessage>{errors.type}</FormErrorMessage>
                </FormControl>
                <FormControl id="device-merchant" isInvalid={errors.merchant}>
                    <FormLabel>Merchant</FormLabel>
                    <Select
                        placeholder="Select merchant"
                        value={device.merchant}
                        variant={"filled"}
                        onChange={(e) => handleChange("merchant", e.target.value)}
                    >
                        {merchants.map((merchant) => (
                            <option key={merchant.id} value={merchant.id}>
                                {merchant.name}
                            </option>
                        ))}
                    </Select>
                    <FormErrorMessage>{errors.merchant}</FormErrorMessage>
                </FormControl>
            </VStack>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Unsaved Changes
                        </AlertDialogHeader>
                        <AlertDialogBody>You have unsaved changes. Are you sure you want to cancel?</AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                No
                            </Button>
                            <Button colorScheme="red" onClick={confirmCancel} ml={3}>
                                Yes
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};
