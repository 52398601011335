import {
  Box,
  Flex,
  Heading,
  Text,
  Grid,
  GridItem,
  Collapse,
  Button,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "@chakra-ui/icons";
import API from "../../context/API";

function DashboardManager() {
  const [totalStaff, setTotalStaff] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalCustomizations, setTotalCustomizations] = useState(0);
  const [activeCollapse, setActiveCollapse] = useState("totalStaff");

  const navigate = useNavigate();

  // Background and Hover colors based on color mode
  const hoverBgColor = useColorModeValue("gray.200", "gray.700");
  const selectedBgColor = useColorModeValue("gray.200", "gray.600");
  const bgColor = useColorModeValue("gray.100", "gray.800");

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        // Fetch staff, items, and customizations data
        const staffResponse = await API.get("/staff/all");
        const itemsResponse = await API.get("/items");
        const customizationsResponse = await API.get("/customizations");

        // Set counts to state
        setTotalStaff(staffResponse.data.data.length);
        setTotalItems(itemsResponse.data.data.length);
        setTotalCustomizations(customizationsResponse.data.data.length);
      } catch (error) {
        console.error("Error fetching metrics:", error);
      }
    };

    fetchMetrics();
  }, []);

  const handleTileClick = (tileName) => {
    setActiveCollapse(tileName === activeCollapse ? null : tileName);
  };

  return (
    <Flex direction="column">
      <Flex direction="row" flex="1">
        {/* Main Content Area */}
        <Box as="main" flex="1" padding="4">
          <Heading size="lg" marginBottom="4">
            Dashboard
          </Heading>

          {/* Key Metrics */}
          <Grid templateColumns="repeat(3, 1fr)" gap="4" marginBottom="4">
            {/* Total Staff Tile */}
            <GridItem
              padding="4"
              borderRadius="md"
              boxShadow="sm"
              bg={activeCollapse === "totalStaff" ? selectedBgColor : bgColor}
              _hover={{
                bg: hoverBgColor,
                transition: "background-color 0.3s ease",
              }}
              onClick={() => handleTileClick("totalStaff")}
              cursor="pointer"
            >
              <Text fontSize="xl" fontWeight="bold">
                Total Staff
              </Text>
              <Text fontSize="2xl">{totalStaff}</Text>
            </GridItem>

            {/* Items Tile */}
            <GridItem
              padding="4"
              borderRadius="md"
              boxShadow="sm"
              bg={activeCollapse === "items" ? selectedBgColor : bgColor}
              _hover={{
                bg: hoverBgColor,
                transition: "background-color 0.3s ease",
              }}
              onClick={() => handleTileClick("items")}
              cursor="pointer"
            >
              <Text fontSize="xl" fontWeight="bold">
                Items
              </Text>
              <Text fontSize="2xl">{totalItems}</Text>
            </GridItem>

            {/* Customizations Tile */}
            <GridItem
              padding="4"
              borderRadius="md"
              boxShadow="sm"
              bg={
                activeCollapse === "customizations" ? selectedBgColor : bgColor
              }
              _hover={{
                bg: hoverBgColor,
                transition: "background-color 0.3s ease",
              }}
              onClick={() => handleTileClick("customizations")}
              cursor="pointer"
            >
              <Text fontSize="xl" fontWeight="bold">
                Customizations
              </Text>
              <Text fontSize="2xl">{totalCustomizations}</Text>
            </GridItem>
          </Grid>

          {/* Collapses */}
          <Box marginTop="4">
            {/* Total Staff Collapse (Always Open) */}
            <Box padding="4" borderRadius="md" boxShadow="sm" bg={bgColor}>
              <Heading size="md" marginBottom="4">
                Total Staff
              </Heading>
              <Button onClick={() => navigate("/staff")}>View All Staff</Button>
            </Box>

            {/* Items Collapse */}
            <Collapse in={activeCollapse === "items"} animateOpacity>
              <Box
                padding="4"
                borderRadius="md"
                boxShadow="sm"
                bg={bgColor}
                mt="4"
              >
                <Flex justify="space-between" align="center">
                  <Heading size="md" marginBottom="4">
                    Items
                  </Heading>
                  <IconButton
                    icon={<CloseIcon />}
                    onClick={() => handleTileClick(null)}
                    size="sm"
                    aria-label="Close"
                  />
                </Flex>
                <Button onClick={() => navigate("/items")}>
                  View All Items
                </Button>
              </Box>
            </Collapse>

            {/* Customizations Collapse */}
            <Collapse in={activeCollapse === "customizations"} animateOpacity>
              <Box
                padding="4"
                borderRadius="md"
                boxShadow="sm"
                bg={bgColor}
                mt="4"
              >
                <Flex justify="space-between" align="center">
                  <Heading size="md" marginBottom="4">
                    Customizations
                  </Heading>
                  <IconButton
                    icon={<CloseIcon />}
                    onClick={() => handleTileClick(null)}
                    size="sm"
                    aria-label="Close"
                  />
                </Flex>
                <Button onClick={() => navigate("/customizations")}>
                  View All Customizations
                </Button>
              </Box>
            </Collapse>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default DashboardManager;
