import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Grid,
  GridItem,
  useColorModeValue,
  Collapse,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import API from "../../context/API";

function AdminReports() {
  const [totalMerchants, setTotalMerchants] = useState(0);
  const [activeDevices, setActiveDevices] = useState(0);
  const [merchants, setMerchants] = useState([]);
  const [devices, setDevices] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const merchantsResponse = await API.get("merchant");
        const merchantsData = merchantsResponse.data.data;

        const devicesResponse = await API.get("devices");
        const devicesData = devicesResponse.data.data;

        setTotalMerchants(merchantsData.length);
        setActiveDevices(devicesData.length);
        setMerchants(merchantsData);
        setDevices(devicesData);
      } catch (error) {
        console.error("Error fetching metrics:", error);
      }
    };

    fetchMetrics();
  }, []);

  const hoverBg = useColorModeValue("gray.100", "gray.700");
  const selectedBg = useColorModeValue("gray.200", "gray.600");

  const handleClick = (item) => {
    setSelectedItem(item === selectedItem ? null : item);
  };

  const renderMerchantsDetails = () => (
    <Collapse in={selectedItem === "totalMerchants"} animateOpacity>
      <Box mt="4" p="4" borderWidth="1px" borderRadius="md" boxShadow="sm">
        <Heading size="md">Merchants Details</Heading>
        <Text marginBottom="4">For more details, head to Merchants page</Text>
        <Box overflowX={"auto"}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Merchant Name</Th>
                <Th>Address</Th>
                <Th>Email</Th>
                <Th>Phone</Th>
              </Tr>
            </Thead>
            <Tbody>
              {merchants.map((merchant, index) => (
                <Tr key={index}>
                  <Td>{merchant.name}</Td>
                  <Td>{merchant.address}</Td>
                  <Td>{merchant.email}</Td>
                  <Td>{merchant.phone}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </Collapse>
  );

  const renderDevicesDetails = () => (
    <Collapse in={selectedItem === "activeDevices"} animateOpacity>
      <Box mt="4" p="4" borderWidth="1px" borderRadius="md" boxShadow="sm">
        <Heading size="md">Active Devices Details</Heading>
        <Text marginBottom="4">For more details, head to Devices page</Text>
        {merchants.map((merchant, index) => {
          const merchantDevices = devices.filter(
            (device) => device.merchant.id === merchant.id
          );
          if (merchantDevices.length === 0) return null;

          return (
            <Box key={index} marginBottom="4">
              <Text fontSize="lg" fontWeight="bold" marginBottom="2">
                Merchant: {merchant.name}
              </Text>
              <Box overflowX={"auto"}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Device Name</Th>
                      <Th>Device Identifier</Th>
                      <Th>Device Type</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {merchantDevices.map((device, deviceIndex) => (
                      <Tr key={deviceIndex}>
                        <Td>{device.name}</Td>
                        <Td>{device.identifier}</Td>
                        <Td>{device.type.name}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Collapse>
  );

  return (
    <Flex direction="column">
      <Box as="main" flex="1" padding="4">
        <Heading size="lg" marginBottom="4">
          Reports
        </Heading>

        <Grid
          templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
          gap="4"
          marginBottom="4"
        >
          <GridItem
            padding="4"
            borderRadius="md"
            boxShadow="sm"
            cursor="pointer"
            onClick={() => handleClick("totalMerchants")}
            _hover={{
              backgroundColor: hoverBg,
              transition: "background-color 0.3s ease",
            }}
            backgroundColor={
              selectedItem === "totalMerchants" ? selectedBg : "transparent"
            }
            transition="background-color 0.3s ease"
          >
            <Text fontSize="xl" fontWeight="bold">
              Total Merchants
            </Text>
            <Text fontSize="2xl">{totalMerchants}</Text>
          </GridItem>
          <GridItem
            padding="4"
            borderRadius="md"
            boxShadow="sm"
            cursor="pointer"
            onClick={() => handleClick("activeDevices")}
            _hover={{
              backgroundColor: hoverBg,
              transition: "background-color 0.3s ease",
            }}
            backgroundColor={
              selectedItem === "activeDevices" ? selectedBg : "transparent"
            }
            transition="background-color 0.3s ease"
          >
            <Text fontSize="xl" fontWeight="bold">
              Active Devices
            </Text>
            <Text fontSize="2xl">{activeDevices}</Text>
          </GridItem>
          <GridItem
            padding="4"
            borderRadius="md"
            boxShadow="sm"
            cursor="pointer"
            onClick={() => handleClick("supportTickets")}
            _hover={{
              backgroundColor: hoverBg,
              transition: "background-color 0.3s ease",
            }}
            backgroundColor={
              selectedItem === "supportTickets" ? selectedBg : "transparent"
            }
            transition="background-color 0.3s ease"
          >
            <Text fontSize="xl" fontWeight="bold">
              Support Tickets
            </Text>
            <Text fontSize="2xl">null</Text>
          </GridItem>
        </Grid>

        {renderMerchantsDetails()}
        {renderDevicesDetails()}
      </Box>
    </Flex>
  );
}

export default AdminReports;
