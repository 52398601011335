import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Flex,
  VStack,
  SimpleGrid,
  Avatar,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  FiSmartphone,
} from 'react-icons/fi';

import { AiOutlineMobile } from "react-icons/ai";
import API from '../../context/API';

const ActiveSessionsDevices = () => {
    const [merchantData, setMerchantData] = useState(null);
    const [loading, setLoading] = useState(true);
    
    const bgColor = useColorModeValue("white", "gray.800");
    const tileBg = useColorModeValue("gray.100", "gray.700");
    const tileHoverBg = useColorModeValue("gray.200", "gray.600");
    const textColor = useColorModeValue("gray.800", "white");
    const subTextColor = useColorModeValue("gray.600", "gray.500");

    // const { currentSessions, devices } = merchantData;

    useEffect(() => {
        const fetchMerchantData = async () => {
          try {
            const token = localStorage.getItem("access_token");
            const response = await API.get("merchant/profile", {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setMerchantData(response.data.data);
            const now = Date.now();
            localStorage.setItem("lastUpdatedTimestamp", now.toString());
          } catch (error) {
            console.error("Error fetching merchant data:", error);
          } finally {
            setLoading(false);
          }
        };
    
        // Determine user role;
    
        if (true) {
          fetchMerchantData();
        } else {
          setLoading(false);
        }
      }, []);
    //   if (!merchantData) return null;
    return (
      <Box mt={10}>
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          Active Sessions & Devices
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          {/* Current Sessions */}
          <Box bg={tileBg} p={6} borderRadius="md" boxShadow="md">
            <Text fontSize="xl" fontWeight="bold" mb={4}>
              Current Sessions
            </Text>
            <VStack align="stretch" spacing={4}>
              {merchantData?.currentSessions.map((session) => (
                <Flex
                  key={session.id}
                  align="center"
                  p={3}
                  bg={bgColor}
                  borderRadius="full"
                >
                  <Avatar icon={<AiOutlineMobile />} mr={4} />
                  <Box>
                    <Text fontWeight="bold">{session.deviceName}</Text>
                    <Text fontSize="sm" color={subTextColor}>
                      OS: {session.deviceOs} | IP: {session.deviceIp}
                    </Text>
                  </Box>
                </Flex>
              ))}
            </VStack>
          </Box>

          {/* Devices */}
          <Box bg={tileBg} p={6} borderRadius="md" boxShadow="md">
            <Text fontSize="xl" fontWeight="bold" mb={4}>
              Devices
            </Text>
            <VStack align="stretch" spacing={4}>
              {merchantData?.devices.map((device) => (
                <Flex
                  key={device.id}
                  align="center"
                  p={3}
                  bg={bgColor}
                  borderRadius="full"
                >
                  <Avatar icon={<FiSmartphone />} mr={4} />
                  <Box>
                    <Text fontWeight="bold">{device.name}</Text>
                    <Text fontSize="sm" color={subTextColor}>
                      Type: {device.type.name} | ID: {device.identifier}
                    </Text>
                  </Box>
                </Flex>
              ))}
            </VStack>
          </Box>
        </SimpleGrid>
      </Box>
    );
  };

  export default ActiveSessionsDevices;