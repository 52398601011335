import React from "react";
import {
  Box,
  Flex,
  IconButton,
  Spacer,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  BellIcon,
  ExternalLinkIcon,
  QuestionOutlineIcon,
} from "@chakra-ui/icons";
import { ToggleColorModeButton } from "../../theme/ToggleColorModeButton";
import { FaUser } from "react-icons/fa";
import { Link as RouterLink, useLocation } from "react-router-dom";

const Header = () => {
  const sidebarWidth = useBreakpointValue({ base: "0", md: "250px" });
  const { colorMode } = useColorMode();
  const location = useLocation();

  const selectedItemBg = useColorModeValue("gray.200", "gray.700");
  const borderBottomHeaderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Flex
      as="header"
      position="fixed"
      right="0"
      top="0"
      left={sidebarWidth}
      zIndex="banner"
      bg={colorMode === "light" ? "white" : "gray.800"}
      backdropFilter="saturate(180%) blur(5px)"
      //borderBottom="1px solid rgba(255, 255, 255, 0.3)"
      px={4}
      py={2}
      align="center"
      boxShadow={"sm"}
      w={`calc(100% - ${sidebarWidth})`}
      height="4rem"
      borderBottomColor={borderBottomHeaderColor}
    >
      {/* <Text fontSize="xl" fontWeight="bold" textAlign="left" ml={1}>Dashboard</Text> */}
      <Spacer />
      {/* Help & Support Link */}
      {/* <IconButton
        isRound
        as={RouterLink}
        to="/help-support"
        icon={<QuestionOutlineIcon />}
        variant="ghost"
        aria-label="Help & Support"
        m={2}
        bg={location.pathname === "/help-support" ? selectedItemBg : "transparent"}
      />
      <IconButton
        isRound
        icon={<BellIcon />}
        variant="ghost"
        aria-label="Notifications"
        m={2}
        bg={location.pathname === "/notifications" ? selectedItemBg : "transparent"}
      />
      <IconButton
        isRound
        icon={<ExternalLinkIcon />}
        variant="ghost"
        aria-label="Log out"
        m={2}
        bg={location.pathname === "/logout" ? selectedItemBg : "transparent"}
      /> */}
      {/* Profile Link */}
      <IconButton
        isRound
        as={RouterLink}
        to="/profile"
        icon={<FaUser />}
        variant="ghost"
        aria-label="Profile"
        m={2}
        bg={location.pathname === "/profile" ? selectedItemBg : "transparent"}
      />
      <Box>
        <ToggleColorModeButton position="relative" top="5" right="5" />
      </Box>
    </Flex>
  );
};

export default Header;
