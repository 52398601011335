import React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
//import { DateRangePicker } from "../../theme/Layout/DatePicker";
import {FaQuestionCircle} from "react-icons/fa";
import {IoCloudDownloadOutline} from "react-icons/io5";
import {InfoOutlineIcon} from "@chakra-ui/icons";
//import { Line } from "react-chartjs-2";
//import "chartjs-adapter-date-fns";
//import "../../theme/Layout/ChartConfig"; // Ensure the path is correct
import {BsBagXFill} from "react-icons/bs";

const Operations = () => {
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const colorBasedOnTheme = useColorModeValue("black", "white");

  const handleOpenGlossaryModal = () => {
    onOpen();
  };

  return (
    <>
      <Box p={4}>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Heading size={"xl"} letterSpacing={-2}>
            Operations
          </Heading>
          <Box>
            <Button
              display={{ base: "none", md: "flex" }} // Hide on mobile
              rightIcon={<FaQuestionCircle />}
              onClick={handleOpenGlossaryModal}
            >
              Operations glossary
            </Button>
            <IconButton
              display={{ base: "flex", md: "none" }} // Hide on mobile
              icon={<FaQuestionCircle />}
              onClick={handleOpenGlossaryModal}
            />
          </Box>
        </Flex>
        <Flex justify={"space-between"} mb={4}></Flex>

        <Flex my={4} justifyContent="space-between" alignItems="center">
          <Heading size={"md"} letterSpacing={-1}>
            Orders with issues
          </Heading>
          <Button rightIcon={<IoCloudDownloadOutline />}>Download</Button>
        </Flex>
        <Box
          p={4}
          borderWidth={"1px"}
          //borderColor={"gray.200"}
          borderRadius={"24"}
        >
          <Tabs
            variant="soft-rounded"
            colorScheme={colorMode === "dark" ? "whiteAlpha" : "blackAlpha"}
          >
            <TabList>
              <Tab color={colorBasedOnTheme}>Inaccurate orders</Tab>
              <Tab color={colorBasedOnTheme}>Unfulfilled orders</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                  <Flex align="flex-start" gap={4}>
                    <StatGroup>
                      <Stat>
                        <StatLabel>
                          Inaccurate order rate
                          <Tooltip
                            label={
                              "Percentage of completed orders that were deemed to be inaccurate after review. Excludes orders with fraudulent claims."
                            }
                          >
                            <IconButton
                              aria-label={`Info about Inaccurate order rate`}
                              icon={<InfoOutlineIcon />}
                              size="xs"
                              variant="ghost"
                            />
                          </Tooltip>
                        </StatLabel>
                        <StatNumber>0%</StatNumber>
                      </Stat>
                    </StatGroup>
                    <StatGroup>
                      <Stat>
                        <StatLabel>Cost of refunds</StatLabel>
                        <StatNumber>(US$0.00)</StatNumber>
                      </Stat>
                    </StatGroup>
                  </Flex>
                </Flex>
                {/* <Box>
                  <Line data={data} options={options} />
                </Box> */}
              </TabPanel>
              <TabPanel>
                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                  <Flex align="flex-start" gap={4}>
                    <StatGroup>
                      <Stat>
                        <StatLabel>
                          Unfulfilled order rate
                          <Tooltip
                            label={
                              "Percentage of all orders cancelled by you, whether you did so deliberately or did not confirm an order on time."
                            }
                          >
                            <IconButton
                              aria-label={`Info about Inaccurate order rate`}
                              icon={<InfoOutlineIcon />}
                              size="xs"
                              variant="ghost"
                            />
                          </Tooltip>
                        </StatLabel>
                        <StatNumber>0%</StatNumber>
                        <StatHelpText>
                          <StatArrow type="increase" />
                          23.36%
                        </StatHelpText>
                      </Stat>
                    </StatGroup>
                    <StatGroup>
                      <Stat>
                        <StatLabel>
                          Sales lost
                          <Tooltip
                            label={
                              "The subtotal amount of all Unfulfilled orders. This amount does not account for potential taxes and fees."
                            }
                          >
                            <IconButton
                              aria-label={`Info about Inaccurate order rate`}
                              icon={<InfoOutlineIcon />}
                              size="xs"
                              variant="ghost"
                            />
                          </Tooltip>
                        </StatLabel>
                        <StatNumber>(US$0.00)</StatNumber>
                        <StatHelpText>
                          <StatArrow type="decrease" />
                          23.36%
                        </StatHelpText>
                      </Stat>
                    </StatGroup>
                  </Flex>
                </Flex>
                {/* <Box>
                  <Line data={data} options={options} />
                </Box> */}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <Box my={"4"}>
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <Heading size={"md"} letterSpacing={-1} ml={4}>
              Top inaccurate orders
            </Heading>
            <Button rightIcon={<IoCloudDownloadOutline />}>Download</Button>
          </Flex>
          <Box
            mt={4}
            height={"400px"}
            borderWidth={"1px"}
            borderRadius={"24"}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <BsBagXFill size={"50px"} color="gray" />
            <Text mt={2} color="gray">
              no orders found
            </Text>
          </Box>
        </Box>
        <Box my={"4"}>
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <Heading size={"md"} letterSpacing={-1} ml={4}>
              Order issues by hour
            </Heading>
            <Button rightIcon={<IoCloudDownloadOutline />}>Download</Button>
          </Flex>
          <Box
            mt={4}
            height={"400px"}
            borderWidth={"1px"}
            borderRadius={"24"}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <BsBagXFill size={"50px"} color="gray" />
            <Text mt={2} color="gray">
              no orders found
            </Text>
          </Box>
        </Box>
        <Box my={"4"}>
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <Heading size={"md"} letterSpacing={-1} ml={4}>
              Online rate
            </Heading>
            <Button rightIcon={<IoCloudDownloadOutline />}>Download</Button>
          </Flex>
          <Box
            mt={4}
            height={"400px"}
            borderWidth={"1px"}
            borderRadius={"24"}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            {/* <BsBagXFill size={"50px"} color="gray" />
            <Text mt={2} color="gray">
              no orders found
            </Text> */}
            {/* <Line data={data1} options={options1} /> */}
          </Box>
        </Box>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size={{base: "sm", md: "lg"}} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Operations glossary</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs variant="unstyled">
              <TabList>
                <Tab
                  _selected={{
                    color: colorBasedOnTheme,
                    borderBottom: `2px solid ${colorBasedOnTheme}`,
                  }}
                  flex="1"
                  fontSize={"sm"}
                >
                  Inaccurate orders
                </Tab>
                <Tab
                  _selected={{
                    color: colorBasedOnTheme,
                    borderBottom: `2px solid ${colorBasedOnTheme}`,
                  }}
                  flex="1"
                  fontSize={"sm"}
                >
                  Cancelled orders
                </Tab>
                <Tab
                  _selected={{
                    color: colorBasedOnTheme,
                    borderBottom: `2px solid ${colorBasedOnTheme}`,
                  }}
                  flex="1"
                  fontSize={"sm"}
                >
                  Online rate
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Text fontSize="md" mb={2}>
                    These are issues that reflect whether you’ve prepared orders
                    according to eater specifications
                  </Text>
                  <Text fontWeight="bold">Inaccurate orders rate</Text>
                  <Text mb={2}>
                    Includes all orders with inaccuracies whether or not you
                    were charged. Excludes orders with fraudulent claims.
                  </Text>
                  <Text fontWeight="bold">Items missing</Text>
                  <Text mb={2}>Items were missing</Text>
                  <Text fontWeight="bold">Missing customisations</Text>
                  <Text mb={2}>Customisations were missing</Text>
                  <Text fontWeight="bold">Wrong order delivered</Text>
                  <Text>
                    The order received was either someone else's or completely
                    wrong
                  </Text>
                </TabPanel>
                <TabPanel>
                  <Text fontSize="md" mb={2}>
                    These are issues where customers did not receive an order
                  </Text>
                  <Text fontWeight="bold">Unfulfilled order rate</Text>
                  <Text mb={2}>
                    Percentage of all orders cancelled by you, whether you did
                    so deliberately or did not confirm an order on time.
                  </Text>
                  <Text fontWeight="bold">Unaccepted (timed out)</Text>
                  <Text mb={2}>
                    Order was not confirmed within the allotted time on the
                    orders app
                  </Text>
                  <Text fontWeight="bold">Cancelled</Text>
                  <Text>You cancelled the incoming order</Text>
                </TabPanel>
                <TabPanel>
                  <Text fontSize="md">
                    The percentage of time your shop(s) were online during menu
                    hours.
                  </Text>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="black"
              color={"white"}
              _hover={{
                color: "black",
                bg: "white",
                borderWidth: "1px",
                borderColor: "gray.300",
              }}
              variant="outline"
              onClick={onClose}
            >
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Operations;
