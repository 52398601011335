import React from "react";
import Lottie from "react-lottie";
import animationData from "../assets/LOGO 3.0.json";
import { useLoading } from "../context/LoadingContext";

export const LoadingAnimation = ({ size = "medium" }) => {
  const { isLoading } = useLoading();
  if (!isLoading) return null;

  const headerHeight = "60px";
  const sidebarWidth = "250px";

  const sizeConfig = {
    small: { width: 100, height: 100 },
    medium: { width: 200, height: 200 },
    large: { width: "100%", height: "100%" },
    contentArea: {
      width: `calc(100vw - ${sidebarWidth})`,
      height: `calc(100vh - ${headerHeight})`,
      top: headerHeight,
      left: sidebarWidth,
    },
  }[size];

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      style={{
        position: "absolute",
        top: sizeConfig.top,
        left: sizeConfig.left,
        width: sizeConfig.width,
        height: sizeConfig.height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: Add background color to dim the content area
      }}
    >
      <Lottie
        options={defaultOptions}
        width={sizeConfig.width}
        height={sizeConfig.height}
      />
    </div>
  );
};
