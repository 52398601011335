import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  HStack,
  IconButton,
  Text,
  VStack,
  Button,
  Flex,
  Image,
  Checkbox,
  useColorMode,
  SlideFade,
  useColorModeValue,
  InputGroup,
  InputLeftElement,
  Input,
  Icon,
} from "@chakra-ui/react";
import {
  ChevronLeftIcon,
  CloseIcon,
  ArrowBackIcon,
  SearchIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import API, { getMediaURL } from "../../../context/API"; // Assuming you have a module to make API calls

const AddToItems = ({
  customizationData,
  handleBackClick,
  handleSubmit,
  handleRelatedItemsChange,
}) => {
  const { colorMode } = useColorMode();
  const focusBorderColor = useColorModeValue("gray.700", "gray.200");
  const borderColor = useColorModeValue("gray.300", "gray.700");

  const [relatedItems, setRelatedItems] = useState(
    (customizationData.related_items || []).map((relatedItem) => ({
      ...relatedItem,
    }))
  );
  
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryItems, setCategoryItems] = useState([]);

  const itemsBorderColor = useColorModeValue("gray.200", "gray.700");

  useEffect(() => {
    // Fetch categories when the component is mounted
    const fetchCategories = async () => {
      try {
        const response = await API.get("/categories");
        if (response.data.success) {
          const categoriesData = response.data.data.map((category) => ({
            ...category,
            picture: getMediaURL(category.picture), // Use the helper function here
          }));
          setCategories(categoriesData);
          setFilteredCategories(categoriesData); // Initialize filtered categories
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter categories based on search query
    const filtered = categories.filter((category) =>
      category.category.toLowerCase().includes(query)
    );
    setFilteredCategories(filtered);

    setIsSearching(!!query);
  };

  const handleSearchFocus = () => {
    setIsSearching(true);
    setSelectedCategory(null); // Ensure we start at categories view when searching
  };

  const handleBack = () => {
    if (selectedCategory) {
      setSelectedCategory(null);
      setCategoryItems([]);
    } else {
      setIsSearching(false);
      setSearchQuery("");
    }
  };

  const handleCategoryClick = async (categoryId) => {
    try {
      const response = await API.get(`/categories/${categoryId}/items`);
      if (response.data.success) {
        const itemsData = response.data.data.map((item) => ({
          ...item,
          picture: getMediaURL(item.picture),
          isChecked: relatedItems.some(
            (relatedItem) => relatedItem.id === item.id
          ), // Check if item is in related_items
        }));
        setCategoryItems(itemsData);
        setSelectedCategory(categoryId);
        console.log("item data:", response.data.data);
      }
    } catch (error) {
      console.error("Error fetching category items:", error);
    }
  };

  const handleRemoveItem = (itemId) => {
    const updatedItems = relatedItems.filter((item) => item.item.id !== itemId);
    setRelatedItems(updatedItems);

    // Call the parent function to update customizationData
    handleRelatedItemsChange(updatedItems);
  };

  const handleCheckboxChange = (item) => {
    let updatedItems;
    if (item.isChecked) {
      // If unchecked, remove the item from relatedItems
      updatedItems = relatedItems.filter(
        (relatedItem) => relatedItem.id !== item.id
      );
    } else {
     // If checked, add the item to relatedItems
    const newRelatedItem = {
      item: item,
    };
    updatedItems = [...relatedItems, newRelatedItem];
    }

    // Update the checkbox state in the categoryItems array
  const updatedCategoryItems = categoryItems.map((categoryItem) =>
    categoryItem.id === item.id
      ? { ...categoryItem, isChecked: !item.isChecked }
      : categoryItem
  );
    setCategoryItems(updatedCategoryItems);

    // Update the relatedItems state
    setRelatedItems(updatedItems);

    // Update the customization data
    handleRelatedItemsChange(updatedItems);
  };

  const saveChanges = () => {
    const updatedCustomizationData = {
      ...customizationData,
      related_items: relatedItems,
    };
    console.log("Onsave btn pressed", relatedItems);
    // Call handleSubmit with updated data
    handleSubmit("addtoitems");
  };
  return (
    <Box
      p={4}
      flexDirection="column"
      width={"25%"}
      position={"fixed"}
      right={4}
      h={`calc(100vh - 120px)`}
      maxWidth={"inherit"}
      borderLeft="1px solid"
      borderColor={borderColor}
      overflowY={"auto"}
    >
      <SlideFade in={true} offsetY="20px">
        {/* Back button and Breadcrumbs */}
        <HStack mb={4}>
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={handleBackClick}
            size="sm"
          />
          <Text
            cursor="pointer"
            onClick={handleBackClick}
            _hover={{ textDecoration: "underline" }}
          >
            Customisations
          </Text>
          <Text> / Add to items</Text>
        </HStack>

        <Heading size="md" mb={2}>
          Add to Items
        </Heading>
        <Text mb={4}>
          Select which items these customisations will be added to.
        </Text>

        {/* Search Bar */}
        <InputGroup mb={4}>
          <InputLeftElement>
            {isSearching || selectedCategory ? (
              <IconButton
                icon={<ArrowBackIcon />}
                size="sm"
                borderRadius={"full"}
                onClick={handleBack}
                aria-label="Back"
              />
            ) : (
              <SearchIcon color="gray.400" />
            )}
          </InputLeftElement>
          <Input
            type="search"
            placeholder="Search all items or categories..."
            variant={"filled"}
            borderRadius={"full"}
            value={searchQuery}
            onChange={handleSearchChange}
            onFocus={handleSearchFocus}
            _focus={{
              borderColor: focusBorderColor, // border color on focus
              //boxShadow: "0 0 0 1px black", // ensure the shadow matches the border color
            }}
          />
        </InputGroup>

        {/* Display Content Based on State */}
        {isSearching || selectedCategory ? (
          selectedCategory ? (
            // Display items of the selected category
            <VStack align="start" spacing={4} flexGrow={1} mb={"4"}>
              {categoryItems.map((item) => (
                <Box
                  key={item.id}
                  p={3}
                  width="full"
                  borderRadius="md"
                  border="1px solid"
                  borderColor={itemsBorderColor}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  bg={colorMode === "light" ? "gray.50" : "gray.800"}
                >
                  <HStack spacing={3}>
                    <Checkbox
                      isChecked={item.isChecked} // Bind checked state to item.isChecked
                      onChange={() => handleCheckboxChange(item)} // Define this function to handle checkbox changes
                    />
                    <Image
                      boxSize="50px"
                      borderRadius="md"
                      src={item.picture}
                      alt={item.item}
                      fallbackSrc="https://via.placeholder.com/50"
                    />
                    <Box>
                      <Text fontWeight="bold">{item.item}</Text>
                      <Text fontSize="sm">
                        ${item.price != null ? item.price.toFixed(2) : ""}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              ))}
            </VStack>
          ) : (
            // Display categories
            <VStack align="start" spacing={2} flexGrow={1} mb={"4"}>
              {filteredCategories.map((category) => (
                <Box
                  key={category.id}
                  p={4}
                  width="full"
                  borderRadius="md"
                  border="1px solid"
                  borderColor={itemsBorderColor}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  bg={colorMode === "light" ? "gray.50" : "gray.800"}
                  cursor="pointer"
                  onClick={() => handleCategoryClick(category.id)}
                >
                  <Heading size={"md"}>{category.category}</Heading>
                  <Icon as={ChevronRightIcon} />
                </Box>
              ))}
            </VStack>
          )
        ) : (
          // Display related items when not searching and no category selected
          <VStack align="start" spacing={4} flexGrow={1} mb="4" width="full">
      {relatedItems.map((relatedItem) => {
        const { item } = relatedItem;

        const hasItem = item && item.id;

        return (
          <Box
            key={relatedItem.id}
            p={3}
            width="full"
            borderRadius="md"
            border="1px solid"
            borderColor={itemsBorderColor}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            bg={colorMode === "light" ? "gray.50" : "gray.800"}
          >
            <HStack spacing={3}>
              {hasItem ? (
                <>
                  <Image
                    boxSize="50px"
                    borderRadius="md"
                    src={item.picture || "https://via.placeholder.com/50"} // Fallback for picture
                    alt={item.item || "Unnamed Item"} 
                    fallbackSrc="https://via.placeholder.com/50"
                  />
                  <Box>
                    <Text fontWeight="bold">{item.item || "Unnamed Item"}</Text>
                    <Text fontSize="sm">
                      {item.price != null
                        ? `$${item.price.toFixed(2)}`
                        : "Price not available"}
                    </Text>
                  </Box>
                </>
              ) : (
                <>
                  <Image
                    boxSize="50px"
                    borderRadius="md"
                    src="https://via.placeholder.com/50"
                    alt="Unnamed Item"
                    fallbackSrc="https://via.placeholder.com/50"
                  />
                  <Box>
                    <Text fontWeight="bold">Unnamed Item</Text>
                    <Text fontSize="sm">Price not available</Text>
                  </Box>
                </>
              )}
            </HStack>
            {hasItem && (
              <IconButton
                icon={<CloseIcon />}
                size="sm"
                onClick={() => handleRemoveItem(item.id)}
                aria-label="Remove Item"
                colorScheme="red"
                variant="ghost"
                _hover={{ bg: "red.100" }}
              />
            )}
          </Box>
        );
      })}
    </VStack>
        )}

        <Button
          position={"sticky"}
          bottom={0}
          bg="black"
          color="white"
          _hover={{
            bg: "white",
            color: "black",
            borderColor: "gray.300",
            borderWidth: "1px",
          }}
          width="full"
          onClick={saveChanges}
        >
          Save
        </Button>
      </SlideFade>
    </Box>
  );
};

export default AddToItems;
