import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Grid,
  GridItem,
  useColorModeValue,
  Collapse,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useBreakpointValue,
} from '@chakra-ui/react';
import API from '../../context/API';

function DashboardAdmin() {
  const [totalMerchants, setTotalMerchants] = useState(0);
  const [activeDevices, setActiveDevices] = useState(0);
  const [merchants, setMerchants] = useState([]);
  const [devices, setDevices] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        // Fetch merchants data
        const merchantsResponse = await API.get('merchant');
        const merchantsData = merchantsResponse.data.data;

        // Fetch devices data
        const devicesResponse = await API.get('devices');
        const devicesData = devicesResponse.data.data;

        // Count merchants and devices
        const totalMerchantsCount = merchantsData.length;
        const activeDevicesCount = devicesData.length;

        // Set data to state
        setTotalMerchants(totalMerchantsCount);
        setActiveDevices(activeDevicesCount);
        setMerchants(merchantsData);
        setDevices(devicesData);
      } catch (error) {
        console.error('Error fetching metrics:', error);
      }
    };

    fetchMetrics();
  }, []);

  const hoverBg = useColorModeValue('gray.100', 'gray.700');
  const selectedBg = useColorModeValue('gray.200', 'gray.600');

  const handleClick = (item) => {
    setSelectedItem(item === selectedItem ? null : item);
  };

  const renderMerchantsDetails = () => (
    <Collapse in={selectedItem === 'totalMerchants'} animateOpacity>
      <Box mt={4} p={4} borderWidth="1px" borderRadius="md" boxShadow="sm">
        <Heading size="md" mb={4}>
          Merchants Details
        </Heading>
        <Text mb={4}>For more details, head to the Merchants page.</Text>
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Merchant Name</Th>
                <Th>Address</Th>
                <Th>Email</Th>
                <Th>Phone</Th>
              </Tr>
            </Thead>
            <Tbody>
              {merchants.map((merchant, index) => (
                <Tr key={index}>
                  <Td>{merchant.name}</Td>
                  <Td>{merchant.address}</Td>
                  <Td>{merchant.email}</Td>
                  <Td>{merchant.phone}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </Collapse>
  );

  const renderDevicesDetails = () => (
    <Collapse in={selectedItem === 'activeDevices'} animateOpacity>
      <Box mt={4} p={4} borderWidth="1px" borderRadius="md" boxShadow="sm">
        <Heading size="md" mb={4}>
          Active Devices Details
        </Heading>
        <Text mb={4}>For more details, head to the Devices page.</Text>
        {merchants.map((merchant, index) => {
          const merchantDevices = devices.filter(
            (device) => device.merchant.id === merchant.id
          );
          if (merchantDevices.length === 0) return null;

          return (
            <Box key={index} mb={6}>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                Merchant: {merchant.name}
              </Text>
              <Box overflowX="auto">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Device Name</Th>
                      <Th>Device Identifier</Th>
                      <Th>Device Type</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {merchantDevices.map((device, deviceIndex) => (
                      <Tr key={deviceIndex}>
                        <Td>{device.name}</Td>
                        <Td>{device.identifier}</Td>
                        <Td>{device.type.name}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Collapse>
  );

  // Responsive heading sizes
  const headingSize = useBreakpointValue({ base: 'md', md: 'lg' });

  return (
    <Flex direction="column" p={4}>
      <Heading size={headingSize} mb={4}>
        Dashboard
      </Heading>

      {/* Key Metrics */}
      <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={4} mb={4}>
        <GridItem
          p={4}
          borderRadius="md"
          boxShadow="sm"
          cursor="pointer"
          onClick={() => handleClick('totalMerchants')}
          _hover={{
            backgroundColor: hoverBg,
            transition: 'background-color 0.3s ease',
          }}
          backgroundColor={selectedItem === 'totalMerchants' ? selectedBg : 'transparent'}
          transition="background-color 0.3s ease"
        >
          <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="bold" mb={2}>
            Total Merchants
          </Text>
          <Text fontSize={{ base: '2xl', md: '3xl' }}>{totalMerchants}</Text>
        </GridItem>
        <GridItem
          p={4}
          borderRadius="md"
          boxShadow="sm"
          cursor="pointer"
          onClick={() => handleClick('activeDevices')}
          _hover={{
            backgroundColor: hoverBg,
            transition: 'background-color 0.3s ease',
          }}
          backgroundColor={selectedItem === 'activeDevices' ? selectedBg : 'transparent'}
          transition="background-color 0.3s ease"
        >
          <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="bold" mb={2}>
            Active Devices
          </Text>
          <Text fontSize={{ base: '2xl', md: '3xl' }}>{activeDevices}</Text>
        </GridItem>
        <GridItem
          p={4}
          borderRadius="md"
          boxShadow="sm"
          cursor="pointer"
          onClick={() => handleClick('supportTickets')}
          _hover={{
            backgroundColor: hoverBg,
            transition: 'background-color 0.3s ease',
          }}
          backgroundColor={selectedItem === 'supportTickets' ? selectedBg : 'transparent'}
          transition="background-color 0.3s ease"
        >
          <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="bold" mb={2}>
            Support Tickets
          </Text>
          <Text fontSize={{ base: '2xl', md: '3xl' }}>N/A</Text>
        </GridItem>
      </Grid>

      {/* Collapse Sections */}
      {renderMerchantsDetails()}
      {renderDevicesDetails()}

      {/* Recent Activities */}
      <Box p={6} borderRadius="md" boxShadow="sm" mt={6}>
        <Heading size="md" mb={4}>
          Recent Activities
        </Heading>
        <Text mb={2}>Merchant A added a new device.</Text>
        <Text mb={2}>Merchant B updated their profile.</Text>
        <Text>Support ticket from Merchant C resolved.</Text>
      </Box>
    </Flex>
  );
}

export default DashboardAdmin;
